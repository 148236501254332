import React, { ChangeEvent } from 'react';

type FileHandler = {
  name: string;
  type: string;
  value?: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  labelText: string;
  placeholder?: string;
  disabled?: boolean;
};

const FileHandler = ({
  name,
  type,
  value,
  handleChange,
  labelText,
  placeholder,

  disabled,
}: FileHandler) => {
  return (
    <div>
      <div className='flex flex-col gap-4'>
        <label htmlFor={name} className='capitalize'>
          {labelText || name}
        </label>

        <input
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
          className={
            disabled
              ? 'outline-none border-[1px] border-slate-400  p-2 mb-8 rounded-lg cursor-not-allowed'
              : 'outline-none border-[1px] border-slate-400  p-2 mb-8 rounded-lg'
          }
        />
      </div>
    </div>
  );
};

export default FileHandler;
