import { UserStoriesItemTypes } from 'pages/projectList/showSingleProject';
import React from 'react';
import { useUpdate, useInvalidate } from '@refinedev/core';
import FormRow from 'HOC/FormRow';
import { GrClose } from 'react-icons/gr';

const EditUserStories = ({
  setOpenUserStoriesEditModal,
  editUserStoriesItem,
}: {
  setOpenUserStoriesEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  editUserStoriesItem: UserStoriesItemTypes | undefined;
}) => {
  const initialValues = {
    id: editUserStoriesItem?._id,
    story: editUserStoriesItem?.story,
    storyDetails: editUserStoriesItem?.storyDetails,
    priority: editUserStoriesItem?.priority,
    intendedAudience: editUserStoriesItem?.intendedAudience,
  };
  const { mutate, isLoading } = useUpdate();
  const invalidate = useInvalidate();
  const [value, setValue] = React.useState(initialValues);

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setValue((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const payload = {
      story: value.story,
      storyDetails: value.storyDetails,
      intendedAudience: value.intendedAudience,
      priority: value.priority,
    };
    const id = value.id as string;

    mutate(
      { resource: 'user-story', values: payload, id: id },
      {
        onSuccess: (data) => {
          if (data.data) {
            invalidate({
              resource: 'project-objective',
              invalidates: ['all'],
            });
            setOpenUserStoriesEditModal(false);
          }
        },
      }
    );
  };

  return (
    <div>
      <div className='flex justify-end mb-8'>
        <button
          className='font-semibold'
          onClick={() => setOpenUserStoriesEditModal(false)}>
          <GrClose className='text-2xl' />
        </button>
      </div>
      <form
        onSubmit={handleSubmit}
        className='w-[75vw] max-w-[100vw] md:w-[40rem] flex flex-col'>
        <FormRow
          labelText='User Story'
          name='story'
          type='text'
          placeholder='E.g. Sales Pipeline'
          value={value.story}
          handleChange={handleChange}
        />
        <FormRow
          labelText='Story Details'
          textArea='textArea'
          name='storyDetails'
          placeholder='E.g. I want to get the details of the sales pipeline'
          value={value.storyDetails}
          handleChange={handleChange}
        />

        <FormRow
          labelText='Intended Audience'
          name='intendedAudience'
          type='text'
          placeholder='E.g. Regional Director'
          value={value.intendedAudience}
          handleChange={handleChange}
        />

        <label htmlFor='userPriority'>Priority</label>
        <select
          onChange={handleChange}
          value={value.priority}
          name='priority'
          id='userPriority'
          className='outline-none border-[1px] border-slate-400  p-2 mb-8 rounded-xl mt-4'>
          <option value='' disabled>
            Select Priority
          </option>
          <option value='High'>High</option>
          <option value='Medium'>Medium</option>
          <option value='Low'>Low</option>
        </select>

        <div className='grid place-items-center'>
          <button
            type='submit'
            className={
              isLoading
                ? 'cursor-not-allowed w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
                : 'w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
            }>
            {isLoading ? 'Updating...' : 'Update'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditUserStories;
