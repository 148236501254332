import React from 'react';
import { GrClose } from 'react-icons/gr';
import { ISecurity } from 'types/securityTypes';

const ViewSecurityDetails = ({
  securityItem,
  setOpenSecurityViewModal,
}: {
  securityItem: ISecurity | undefined;
  setOpenSecurityViewModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className='w-[75vw] max-w-[100vw] md:w-[30vw] '>
      <div className='flex justify-end mb-8'>
        <button
          className='font-semibold'
          onClick={() => setOpenSecurityViewModal(false)}>
          <GrClose className='text-2xl' />
        </button>
      </div>
      <h4 className='font-semibold text-center text-xl mb-4'>
        User Story Details
      </h4>
      <div className='my-6'>
        <span className='font-semibold'>ID:</span>
        <p>{securityItem?._id}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Compliance:</span>
        <p>{securityItem?.compliance}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Data Sensitive Item:</span>
        <p>{securityItem?.dataSensitiveItem}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Details:</span>
        <p>{securityItem?.details}</p>
      </div>
    </div>
  );
};

export default ViewSecurityDetails;
