import { UserStoriesItemTypes } from 'pages/projectList/showSingleProject';
import React from 'react';
import { GrClose } from 'react-icons/gr';

const ViewUserStoriesDetails = ({
  userStoriesItem,
  setOpenUserStoriesViewModal,
}: {
  userStoriesItem: UserStoriesItemTypes | undefined;
  setOpenUserStoriesViewModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className='w-[75vw] max-w-[100vw] md:w-[30vw] '>
      <div className='flex justify-end mb-8'>
        <button
          className='font-semibold'
          onClick={() => setOpenUserStoriesViewModal(false)}>
          <GrClose className='text-2xl' />
        </button>
      </div>
      <h4 className='font-semibold text-center text-xl mb-4'>
        User Story Details
      </h4>
      <div className='my-6'>
        <span className='font-semibold'>ID:</span>
        <p>{userStoriesItem?._id}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Data Source Name:</span>
        <p>{userStoriesItem?.story}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Data Source Type:</span>
        <p>{userStoriesItem?.storyDetails}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Data Source Sub-Type:</span>
        <p>{userStoriesItem?.intendedAudience}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Priority:</span>

        <p
          className={
            userStoriesItem?.priority === 'High'
              ? 'font-bold text-red-300'
              : userStoriesItem?.priority === 'Medium'
              ? 'font-bold text-yellow-300'
              : userStoriesItem?.priority === 'Low'
              ? 'font-bold text-green-300'
              : ''
          }>
          {userStoriesItem?.priority}
        </p>
      </div>
    </div>
  );
};

export default ViewUserStoriesDetails;
