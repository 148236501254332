import { useCustomMutation, useDelete, useList } from '@refinedev/core';
import React from 'react';
import NoData from 'components/errorComponent/noData';
import CreateProjectForms from '../../components/projects/createProjectForms';
import { IProject } from 'types/projectTypes';
import EditProjectForms from 'components/projects/editProjectForms';
import { notify } from 'components/shared/Toastify';
import { useProjectObjectiveStore } from 'store/projectObjectives.store';
import { useStoryStore } from 'store/userStory.store';
import { useDataSourceStore } from 'store/dataSource.store';
import { useKPIStore } from 'store/kpi.store';
import { useSecurityStore } from 'store/securityDetail.store';
import { useSampleReportStore } from 'store/sampleReport.store';
import Modal from 'components/shared/Modal';
import ProjectItems from 'components/projects/ProjectItems';

export interface IInviation {
  email: string;
}

const Projects = () => {
  const clearSampleReport = useSampleReportStore(
    (state) => state.clearSampleReport
  );

  // Project Objectives
  const clearProjectObjectiveItem = useProjectObjectiveStore(
    (state) => state.clearProjectObjectiveItem
  );
  const clearProjectObjectiveID = useProjectObjectiveStore(
    (state) => state.clearProjectObjectiveItemId
  );

  // User Story
  const clearStoryItem = useStoryStore((state) => state.clearStoryItem);
  const clearStoryID = useStoryStore((state) => state.clearStoryItemID);

  // Datasources
  const clearDataSourceItem = useDataSourceStore(
    (state) => state.clearDataSourceItem
  );
  const clearDataSourceID = useDataSourceStore(
    (state) => state.clearDataSourceItemID
  );

  // Key Performance Indicators
  const clearKPIItem = useKPIStore((state) => state.clearKPIItem);
  const clearKPIID = useKPIStore((state) => state.clearKPIItemID);
  const removeProjectName = useKPIStore((state) => state.removeProjectName);

  // Security Details
  const clearSecurityItem = useSecurityStore(
    (state) => state.clearSecurityItem
  );
  const clearSecurityID = useSecurityStore(
    (state) => state.clearSecurityItemID
  );

  const { data: project } = useList<IProject>({
    resource: 'project/get-project',
  });
  const { mutate, isLoading } = useDelete();
  const { mutate: sendInvitation } = useCustomMutation<IInviation>();

  const [isCreateProject, setIsCreateProject] = React.useState<boolean>(false);
  const [isEditProject, setIsEditProject] = React.useState<boolean>(false);
  const [projectId, setProjectId] = React.useState<string>('');
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [deleteItemID, setDeleteItemID] = React.useState<string>('');
  const [editItem, setEditItem] = React.useState<IProject | undefined>();
  const [isSelectedCards, setIsSelectedCards] = React.useState<number | null>(
    null
  );
  const [inviteModal, setInviteModal] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');

  const handleSelectedCards = (index: number) => {
    setIsSelectedCards(index === isSelectedCards ? null : index);
  };
  const handleChange = (e: { target: { name: string; value: string } }) => {
    setEmail(e.target.value);
  };
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    sendInvitation(
      {
        url: `${process.env.REACT_APP_SERVER_API_URL}/project/invite/${projectId}`,
        method: 'post',
        values: { email },
      },
      {
        onSuccess() {
          notify('Invitation sent successfully');
          setInviteModal(false);
          setEmail('');
        },
      }
    );
  };

  return (
    <>
      <Modal
        isOpen={isCreateProject}
        className='fixed w-fit  h-[60vh] overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'
        close={setIsCreateProject}
      >
        <CreateProjectForms setIsCreateProject={setIsCreateProject} />
      </Modal>

      <Modal
        isOpen={inviteModal}
        className='fixed w-fit  h-fit overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'
        close={setInviteModal}
      >
        <form
          onSubmit={handleSubmit}
          className='flex flex-col md:flex-row gap-4 items-center'
        >
          <input
            type='text'
            name='invitations'
            value={email}
            onChange={handleChange}
            placeholder='User email'
            className='outline-none border-[1px] border-slate-400  p-2 rounded-lg md:w-96'
          />
          <input
            type='submit'
            value='Invite'
            className='bg-blue-400 py-2 px-4 text-white rounded-lg cursor-pointer'
          />
        </form>
      </Modal>

      <Modal
        isOpen={isEditProject}
        className='fixed w-fit  h-[60vh] overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'
        close={setIsEditProject}
      >
        <EditProjectForms
          editItem={editItem}
          setIsEditProject={setIsEditProject}
        />
      </Modal>

      <Modal
        isOpen={deleteModal}
        className='fixed w-fit  h-fit overflow-y-auto scrollbar-hide mx-auto top-32 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'
        close={setDeleteModal}
      >
        <div>
          <p className='text-xl text-center font-semibold mb-12'>
            Are you sure you want to
            <br /> delete?
          </p>
          <div className='flex gap-4 justify-center'>
            <button
              onClick={() => setDeleteModal(false)}
              className='w-32 bg-white border border-slate-200 p-3 rounded-xl'
            >
              Cancel
            </button>
            <button
              disabled={isLoading}
              onClick={() => {
                mutate(
                  {
                    resource: 'project/delete-project',
                    id: deleteItemID,
                    invalidates: ['all'],
                  },
                  {
                    onSuccess: (data) => {
                      clearProjectObjectiveItem();
                      clearStoryItem();
                      clearDataSourceItem();
                      clearKPIItem();
                      removeProjectName(data.data._id);
                      clearSecurityItem();
                      clearSampleReport();
                      if (data.data) {
                        setDeleteModal(false);
                      }
                    },
                  }
                );
              }}
              className='w-32 bg-pink-500 text-white p-3 rounded-xl'
            >
              {isLoading ? 'Deleting...' : 'Delete'}
            </button>
          </div>
        </div>
      </Modal>

      <div className='mt-4'>
        <div>
          <h4 className='font-semibold text-lg'>Project Overview</h4>
        </div>
        {project !== undefined && project?.data.length === 0 ? (
          <div>
            <NoData />
            <div className='flex justify-center'>
              <button
                onClick={() => {
                  clearProjectObjectiveItem();
                  clearStoryItem();
                  clearDataSourceItem();
                  clearKPIItem();
                  clearSecurityItem();
                  clearSampleReport();
                  setIsCreateProject(true);
                  clearProjectObjectiveID();
                  clearStoryID();
                  clearDataSourceID();
                  clearKPIID();
                  clearSecurityID();
                }}
                className='p-3 rounded-lg text-white bg-blue-400'
              >
                Start New Project
              </button>
            </div>
          </div>
        ) : (
          <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
            <button
              onClick={() => {
                clearProjectObjectiveItem();
                clearStoryItem();
                clearDataSourceItem();
                clearKPIItem();
                clearSecurityItem();
                clearSampleReport();
                setIsCreateProject(true);
                clearProjectObjectiveID();
                clearStoryID();
                clearDataSourceID();
                clearKPIID();
                clearSecurityID();
              }}
              className=' relative w-full bg-white rounded-lg p-4 mt-4 text-center cursor-pointer'
            >
              <p className='absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 '>
                Create New Project
              </p>
            </button>

            <ProjectItems
              project={project}
              isEditProject={isEditProject}
              setDeleteItemID={setDeleteItemID}
              setDeleteModal={setDeleteModal}
              setEditItem={setEditItem}
              setInviteModal={setInviteModal}
              setIsEditProject={setIsEditProject}
              setProjectId={setProjectId}
              handleSelectedCards={handleSelectedCards}
              isSelectedCards={isSelectedCards}
              setIsSelectedCards={setIsSelectedCards}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Projects;
