import { UseFormReturnType } from '@refinedev/react-hook-form';
import { FormValues } from 'components/accounts/securityLogin';
import { UseFormRegister } from 'react-hook-form';

const FormRow = ({
  name,
  type,
  value,
  handleChange,
  labelText,
  placeholder,
  textArea,
  required,
  register,
  disabled,
}: {
  name: string;
  type?: string;
  value?: string | number;
  handleChange?: (e: { target: { name: string; value: string } }) => void;
  labelText?: string;
  placeholder?: string;
  textArea?: string;
  required?: boolean;
  register?: UseFormRegister<FormValues>;
  disabled?: boolean;
}) => {
  if (textArea) {
    return (
      <div className='flex flex-col gap-4'>
        <div className='flex gap-2'>
          <label
            htmlFor={name}
            className='capitalize mb-1 primary2 font-semibold'>
            {labelText || name}
          </label>
          {/* <p className='primary text-2xl'>*</p> */}
        </div>
        <textarea
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          className='bg-none outline-none h-36 border-[2px] p-2 border-gray-400 border-opacity-20 mb-6 rounded-2xl '
        />
      </div>
    );
  }

  if (type === 'checkbox') {
    return (
      <div className='flex items-center mb-4'>
        <input
          id='link-checkbox'
          type='checkbox'
          required={required}
          value={value}
          className='w-4 h-4 text-blue-300 bg-gray-100 border-gray-300 roundes focus:ring-blue-200'
          onChange={handleChange}
        />
        <label htmlFor={name} className='ml-2 text-sm font-medium  '>
          {labelText || name}
        </label>
      </div>
    );
  }

  return (
    <div>
      <div className='flex flex-col gap-4'>
        <label htmlFor={name} className='capitalize'>
          {labelText || name}
        </label>

        <input
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
          className={
            disabled
              ? 'outline-none border-[1px] border-slate-400  p-2 mb-8 rounded-lg cursor-not-allowed'
              : 'outline-none border-[1px] border-slate-400  p-2 mb-8 rounded-lg'
          }
        />
      </div>
    </div>
  );
};

export default FormRow;
