import React from "react";

const NoDataIcon = () => {
  return (
    <>
      <svg
        width="143"
        height="185"
        viewBox="0 0 143 185"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="71.5" cy="71.5" r="71.5" fill="#E4EAFF" />
        <rect
          x="43.4505"
          y="70.0427"
          width="70"
          height="98"
          rx="7"
          transform="rotate(2.5 43.4505 70.0427)"
          fill="#E4EAFF"
          stroke="#0E2782"
          strokeWidth="2"
        />
        <rect
          x="1.83604"
          y="64.1807"
          width="74"
          height="102"
          rx="9"
          transform="rotate(-7.5 1.83604 64.1807)"
          fill="#738CB1"
          stroke="#0E2782"
          strokeWidth="2"
        />
        <path
          d="M4.00222 72.9732C3.42552 68.5927 6.50909 64.5741 10.8896 63.9974L66.4105 56.6879C70.791 56.1112 74.8095 59.1948 75.3862 63.5753L77.3441 78.4469L5.96011 87.8448L4.00222 72.9732Z"
          fill="#E4EAFF"
        />
        <rect
          x="89.8662"
          y="58.643"
          width="3"
          height="10"
          rx="1.5"
          transform="rotate(82.5 89.8662 58.643)"
          fill="#0E2782"
        />
        <rect
          x="62.4697"
          y="42.0773"
          width="3"
          height="10"
          rx="1.5"
          transform="rotate(-7.5 62.4697 42.0773)"
          fill="#0E2782"
        />
        <rect
          x="80.0469"
          y="44.8063"
          width="3"
          height="10"
          rx="1.5"
          transform="rotate(37.5 80.0469 44.8063)"
          fill="#0E2782"
        />
        <rect
          x="13.4219"
          y="90.8969"
          width="58"
          height="4"
          rx="2"
          transform="rotate(-7.5 13.4219 90.8969)"
          fill="white"
        />
        <rect
          x="16.0332"
          y="110.726"
          width="58"
          height="4"
          rx="2"
          transform="rotate(-7.5 16.0332 110.726)"
          fill="white"
        />
        <rect
          x="18.6436"
          y="130.555"
          width="58"
          height="4"
          rx="2"
          transform="rotate(-7.5 18.6436 130.555)"
          fill="white"
        />
        <rect
          x="14.4668"
          y="98.8285"
          width="21"
          height="4"
          rx="2"
          transform="rotate(-7.5 14.4668 98.8285)"
          fill="#E4EAFF"
        />
        <rect
          x="17.0771"
          y="118.657"
          width="21"
          height="4"
          rx="2"
          transform="rotate(-7.5 17.0771 118.657)"
          fill="#E4EAFF"
        />
        <rect
          x="19.6875"
          y="138.486"
          width="21"
          height="4"
          rx="2"
          transform="rotate(-7.5 19.6875 138.486)"
          fill="#E4EAFF"
        />
      </svg>
    </>
  );
};

export default NoDataIcon;
