import { useMany } from '@refinedev/core';
import { useDataSourceStore } from 'store/dataSource.store';
import { useProjectStore } from 'store/projects.store';
import { filterConfirmationData } from 'utils/filterConfirmationData';

const DataSourceConfirmation = () => {
  const dataSourceItemID = useDataSourceStore(
    (state) => state.dataSourceItemID
  );

  const projectItemId = useProjectStore((state) => state.projectItemID);

  const { data } = useMany({
    resource: 'datasource/get-multiple-data-source',
    ids: dataSourceItemID,
  });

  const projectDataSource = filterConfirmationData(data, projectItemId);

  return (
    <div className='p-4 mb-8'>
      <div className=' uppercase bg-white p-4 rounded-xl font-bold'>
        Data Source
      </div>

      <table className='w-full text-sm text-left text-gray-500 '>
        <thead className='uppercase font-bold bg-none border-b-2 border-white'>
          <tr>
            <th scope='col' className='px-6 py-6'>
              Data Source Name
            </th>
            <th scope='col' className='px-6 py-6'>
              Data Source Type
            </th>
            <th scope='col' className='px-6 py-6'>
              Data Source Sub Type
            </th>

            <th scope='col' className='px-6 py-6'>
              Estimate Volume in GB
            </th>
          </tr>
        </thead>
        <tbody>
          {projectDataSource?.map((item, index: number) => {
            const {
              dataSourceName,
              dataSourceSubType,
              dataSourceType,
              estimateVolumeInGB,
            } = item;
            return (
              <tr
                key={index}
                className='bg-none border-b-2 border-white hover:bg-white '
              >
                <td className='px-6 py-4'>{dataSourceName}</td>
                <td className='px-6 py-4'>{dataSourceType}</td>
                <td className='px-6 py-4'>{dataSourceSubType}</td>
                <td className='px-6 py-4'>{estimateVolumeInGB}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DataSourceConfirmation;
