import { BaseKey, BaseRecord, GetOneResponse } from '@refinedev/core';
import { DatasourceItem } from 'pages/projectList/showSingleProject';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface IDataSouceProps {
  projects: GetOneResponse<BaseRecord> | undefined;
  setDataSourceItem: React.Dispatch<
    React.SetStateAction<DatasourceItem | undefined>
  >;
  setOpenDataSourceModal: React.Dispatch<boolean>;
  setEditDataSourceItem: React.Dispatch<
    React.SetStateAction<DatasourceItem | undefined>
  >;
  setOpenDatasourceEditModal: React.Dispatch<boolean>;
  addProjectItemID: (id: string) => void;
  id: BaseKey | undefined;
  deleteProjectItem: (id: string, resource: string) => void;
  clearProjectStorage: () => void;
  navigateResource: string;
}

const DataSourceSection: FC<IDataSouceProps> = ({
  projects,
  setDataSourceItem,
  setOpenDataSourceModal,
  setEditDataSourceItem,
  setOpenDatasourceEditModal,
  id,
  addProjectItemID,
  deleteProjectItem,
  clearProjectStorage,
  navigateResource,
}) => {
  const navigate = useNavigate();
  return (
    <div className='p-4 bg-white board-bg rounded-lg h-fit w-full'>
      <div className='w-64'>
        <h4 className='text-xl font-bold'>Datasources</h4>
        {projects?.data.dataSources.length > 0 ? (
          projects?.data.dataSources.map(
            (item: DatasourceItem, index: number) => {
              const { dataSourceName, _id: id } = item;
              const resource = 'datasource';

              return (
                <div key={index} className='mt-4 bg-white p-3 rounded-lg'>
                  <div>
                    <div className='mb-10'>
                      <span className='font-semibold'>Data Source Type:</span>
                      <p>{dataSourceName}</p>
                    </div>

                    <div className='flex justify-end gap-3 mt-8'>
                      <button
                        onClick={() => {
                          setOpenDataSourceModal(true);
                          setDataSourceItem(item);
                        }}
                        className='p-2 rounded-lg text-white bg-blue-400 w-fit text-xs'>
                        View Details
                      </button>
                      <button
                        onClick={() => {
                          setOpenDatasourceEditModal(true);
                          setEditDataSourceItem(item);
                        }}
                        className='p-2 rounded-lg text-white bg-blue-400 w-fit'>
                        Edit
                      </button>
                      <button
                        onClick={() => deleteProjectItem(id, resource)}
                        className='p-2 rounded-lg text-white bg-pink-500 w-fit'>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              );
            }
          )
        ) : (
          <div className='mt-4 bg-white p-3 rounded-lg '>
            <div className='w-fit h-fit m-auto'>
              <button
                onClick={() => {
                  addProjectItemID(id as string);
                  clearProjectStorage();
                  navigate(navigateResource);
                }}>
                Create New Data
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DataSourceSection;
