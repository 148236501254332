import {
  LoginFormTypes,
  useActiveAuthProvider,
  useLogin,
  useRegister,
} from '@refinedev/core';
// import { LoginSVG } from 'assets/LoginSVG';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';
import { BsMicrosoft } from 'react-icons/bs';

export interface RegisterFormTypes {
  fullName?: string;
  email?: string;
  password?: string;
  providerName?: string;
}
const initialValue = {
  fullName: '',
  email: '',
  password: '',
};

export const Register = () => {
  const authProvider = useActiveAuthProvider();

  const { mutate: register } = useRegister<RegisterFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });
  const { mutate: login } = useLogin<LoginFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });

  const [values, setValues] = useState(initialValue);

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setValues((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    register({
      email: values.email,
      password: values.password,
      fullName: values.fullName,
    });
    e.preventDefault();
  };
  return (
    <>
      <div className='w-screen h-screen bg-white '>
        <div className='flex flex-col items-center '>
          <form
            className='flex justify-center flex-col gap-2 p-8 bg-white rounded-xl drop-shadow-7xl mb-4  mt-6 md:mt-16   md:w-[25rem] '
            onSubmit={handleSubmit}>
            <h3 className='text-4xl text-primary-green text-center mb-8 font-semibold'>
              Register
            </h3>
            <label htmlFor='fullName'>Full Name</label>
            <input
              className='outline-none h-5 border-b-[2px] border-slate500 border-opacity-20 mb-6 '
              type='text'
              name='fullName'
              id='fullName'
              required
              value={values.fullName}
              onChange={handleChange}
            />
            <label htmlFor='username'>Username</label>
            <input
              className='outline-none h-5 border-b-[2px] border-slate500 border-opacity-20 mb-6 '
              type='email'
              name='email'
              id='username'
              required
              value={values.email}
              onChange={handleChange}
            />
            <label htmlFor='pass'>Password</label>
            <input
              className='outline-none h-5 border-b-[2px] border-slate500 border-opacity-20 mb-6 '
              type='password'
              name='password'
              id='pass'
              required
              value={values.password}
              onChange={handleChange}
            />

            <input
              type='submit'
              value='Register'
              className='bg-slate-500 w-40 rounded-full mx-auto p-4 text-white cursor-pointer font-semibold'
            />

            <p className='font-semibold text-center my-2 text-xl'>or</p>
            <div className='flex flex-col gap-4 justify-center'>
              <button
                onClick={() => login({ providerName: 'google' })}
                type='button'
                className='flex gap-4 items-center cursor-pointer p-4 border border-slate400 rounded-md'>
                <FcGoogle className='text-4xl' />
                <p>Continue with Google</p>
              </button>
              {/* <button
                onClick={() => login({ providerName: 'microsoft' })}
                type='button'
                className='flex gap-4 items-center cursor-pointer p-4 border border-slate400 rounded-md'>
                <BsMicrosoft className='text-3xl' />
                <p>Continue with Microsoft</p>
              </button> */}
            </div>

            <p className='text-center mt-3'>
              Already have an account?{' '}
              <Link to='/login' className='text-blue-500'>
                Login
              </Link>
            </p>
          </form>
        </div>
      </div>
    </>
  );
};
