import React from 'react';
import { GrClose } from 'react-icons/gr';
import { IProjectObjective } from 'types/projectObjective';

const ViewProjectObjectiveDetails = ({
  projectObjectiveItem,
  setOpenProjectObjectiveViewModal,
}: {
  projectObjectiveItem: IProjectObjective | undefined;
  setOpenProjectObjectiveViewModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}) => {
  return (
    <div className='w-[75vw] max-w-[100vw] md:w-[30vw] '>
      <div className='flex justify-end mb-8'>
        <button
          className='font-semibold'
          onClick={() => setOpenProjectObjectiveViewModal(false)}>
          <GrClose className='text-2xl' />
        </button>
      </div>
      <h4 className='font-semibold text-center text-xl mb-4'>
        Project Objective Details
      </h4>
      <div className='my-6'>
        <span className='font-semibold'>ID:</span>
        <p>{projectObjectiveItem?._id}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Project Objective Name:</span>
        <p>{projectObjectiveItem?.projectObjectiveName}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Project Objective Description:</span>
        <p>{projectObjectiveItem?.projectObjectiveDescription}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Project Objective Priority:</span>
        <p>{projectObjectiveItem?.projectObjectivePriority}</p>
      </div>
    </div>
  );
};

export default ViewProjectObjectiveDetails;
