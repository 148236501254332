import { useCreate, useGetIdentity, useList } from '@refinedev/core';
import FormRow from 'HOC/FormRow';
import { GrFormClose } from 'react-icons/gr';
import React from 'react';
import { IIdentity } from 'types/identitytypes';

import { notify, notifyError } from 'components/shared/Toastify';
import { useProjectStore } from 'store/projects.store';
import { useKPIStore } from 'store/kpi.store';
import { IKPI } from 'types/kpi.types';

export const AddKPIForms = ({
  setOpenModal,
}: {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const projectItemId = useProjectStore((state) => state.projectItemID);
  const addKPIItemID = useKPIStore((state) => state.addKPIItemID);
  const addKPIItem = useKPIStore((state) => state.addKPIItem);
  const addKPITotal = useKPIStore((state) => state.addKPITotal);

  const initialValues = {
    metric: '',
    metricDefinition: '',
    metricCalculation: '',
    reportedFrequency: '',
    comparator: '',
    dataSource: '',
    table: '',
    dimensionality: '',
  };

  const [value, setValue] = React.useState(initialValues);
  const [dimensionalityData, setDimensionalityData] = React.useState<string[]>(
    []
  );
  // const [reportedFrequencyData, setReportedFrequencyData] =
  React.useState<string>('');
  const [comparatorData, setComparatorData] = React.useState<string[]>([]);
  const [dataSourceData, setDataSourceData] = React.useState<string[]>([]);
  const [tableData, setTableData] = React.useState<string[]>([]);
  const [dataSourceName, setDataSourceName] = React.useState<string[]>([]);
  const [customComparatorData, setCustomComparatorData] =
    React.useState<string>('');

  const { data: user } = useGetIdentity<IIdentity>();
  const { mutate, isLoading } = useCreate<IKPI>();
  const { data: dataSourceList } = useList({ resource: 'datasource' });
  const REPORTED_FREQUENCY = [
    'Real Time',
    'Hourly',
    'Daily',
    'Weekly',
    'Monthly',
    'Yearly',
  ];

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setValue((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleAddComparator = () => {
    if (customComparatorData !== '') {
      comparatorData.push(customComparatorData);
    }
    if (value.comparator !== 'custom') {
      setComparatorData((prev) => [...prev, value.comparator]);
    }
    setCustomComparatorData('');
    setValue((prevValue) => ({
      ...prevValue,
      comparator: '',
    }));
  };

  const handleAddDataSource = () => {
    setDataSourceData((prev) => [...prev, value.dataSource]);

    dataSourceList?.data?.forEach((data) => {
      if (value.dataSource === data._id) {
        setDataSourceName((prev) => [...prev, data.dataSourceName]);
      }
    });

    setValue((prevValue) => ({
      ...prevValue,
      dataSource: '',
    }));
  };

  const handleAddDimensionality = () => {
    setDimensionalityData((prev) => [...prev, value.dimensionality]);
    setValue((prevValue) => ({
      ...prevValue,
      dimensionality: '',
    }));
  };

  const handleAddTable = () => {
    setTableData((prev) => [...prev, value.table]);
    setValue((prevValue) => ({
      ...prevValue,
      table: '',
    }));
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const payload = {
      uid: user?.id,
      metric: value?.metric,
      metricDefinition: value?.metricDefinition,
      metricCalculation: value?.metricCalculation,
      reportedFrequency: value?.reportedFrequency,
      comparator: comparatorData,
      dataSource: dataSourceData.length === 0 ? ['N/A'] : dataSourceData,
      table: tableData.length === 0 ? ['N/A'] : tableData,
      dimensionality:
        dimensionalityData.length === 0 ? ['N/A'] : dimensionalityData,
      projectId: projectItemId,
    };

    mutate(
      { resource: 'kpi', values: payload, invalidates: ['all'] },
      {
        onSuccess: (data) => {
          if (data.data) {
            addKPIItemID(data.data._id);
            addKPIItem({
              ...data.data,
              dataSource: [{ _id: data.data._id, dataSourceName }],
            });
            addKPITotal({
              ...data.data,
              dataSource: [{ _id: data.data._id, dataSourceName }],
            });
            notify('KPI created successfully');
            setOpenModal(false);
          }
        },
        onError(error, variables, context) {
          notifyError(
            error.response.data.errorMsg
              ? error.response.data.errorMsg
              : 'Something went wrong'
          );
        },
      }
    );
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className='w-[75vw] max-w-[100vw] md:w-[40rem]'
      >
        <FormRow
          name='metric'
          type='text'
          value={value.metric}
          handleChange={handleChange}
        />
        <FormRow
          labelText='Metric Definition'
          name='metricDefinition'
          value={value.metricDefinition}
          handleChange={handleChange}
        />

        <FormRow
          labelText='Metric Calculation'
          name='metricCalculation'
          type='text'
          value={value.metricCalculation}
          handleChange={handleChange}
        />
        <div className='grid grid-cols-1 items-center gap-4'>
          <div className='flex flex-col'>
            <label htmlFor='reportedFrequency' className='capitalize'>
              Reported Frequency
            </label>
            <select
              id='reportedFrequency'
              name='reportedFrequency'
              value={value.reportedFrequency}
              onChange={handleChange}
              className='w-full outline-none border-[1px] border-slate-400 rounded-xl p-2 mb-8 md:rounded-xl mt-4'
            >
              <option value=''>Select Reported Frequency</option>
              {REPORTED_FREQUENCY.map((item) => {
                return (
                  <option value={item} key={item}>
                    {item}
                  </option>
                );
              })}
              <option value='N/A'>N/A</option>
            </select>
          </div>
        </div>

        <div className='grid grid-cols-2  items-center gap-4 md:grid-cols-[1fr_100px]'>
          <div className='flex flex-col'>
            <label htmlFor='comparitor' className='capitalize'>
              Comparator
            </label>
            {value.comparator !== 'custom' ? (
              <select
                id='comparitor'
                name='comparator'
                value={value.comparator}
                onChange={(e) => {
                  setValue((prevValue) => ({
                    ...prevValue,
                    comparator: e.target.value,
                  }));
                }}
                className={
                  comparatorData.length > 0
                    ? 'w-full outline-none border-[1px] border-slate-400 rounded-xl p-2 mb-4 md:rounded-xl mt-4'
                    : 'w-full outline-none border-[1px] border-slate-400 rounded-xl p-2 mb-8 md:rounded-xl mt-4'
                }
              >
                <option value=''>Select Comparator</option>
                <option value='Last-Year'>Last Year</option>
                <option value='Last-Month'>Last Month</option>
                <option value='Last-Week'>Last Week</option>
                <option value='Last-Day'>Last Day</option>
                <option value='custom'>Custom</option>
                <option value='N/A'>N/A</option>
              </select>
            ) : (
              <input
                type='text'
                name='comparator'
                value={customComparatorData}
                onChange={(e) => {
                  setCustomComparatorData(e.target.value);
                }}
                placeholder='Add Custom Comparator'
                className='outline-none border-[1px] border-slate-400  p-2 mb-8 rounded-xl mt-4'
              />
            )}
          </div>
          <div className='flex justify-end md:block'>
            <button
              disabled={
                !value.comparator ||
                (value.comparator === 'custom' && !customComparatorData)
              }
              type='button'
              onClick={handleAddComparator}
              className={
                !value.comparator ||
                (value.comparator === 'custom' && !customComparatorData) ||
                value.comparator === 'N/A'
                  ? ' p-3 text-white rounded-xl bg-blue-300 cursor-not-allowed'
                  : ' p-3 text-white rounded-xl bg-blue-400'
              }
            >
              Add more
            </button>
          </div>
        </div>
        <div className='flex flex-row gap-2 flex-wrap mb-8'>
          {comparatorData.map((item, index) => {
            return (
              <div
                key={index}
                className='flex justify-between items-center p-2 bg-slate-100 rounded-lg mb-2'
              >
                <p>{item}</p>
                <GrFormClose
                  className='cursor-pointer text-2xl'
                  onClick={() => {
                    setComparatorData((prev) =>
                      prev.filter((_, i) => i !== index)
                    );
                  }}
                />
              </div>
            );
          })}
        </div>

        <div className='grid grid-cols-2  items-center gap-4 md:grid-cols-[1fr_100px]'>
          <div className='flex flex-col'>
            <label htmlFor='datasource' className='capitalize'>
              Data Source
            </label>
            <select
              id='datasource'
              name='dataSource'
              value={value.dataSource}
              onChange={(e) => {
                setValue((prevValue) => ({
                  ...prevValue,
                  dataSource: e.target.value,
                }));
              }}
              className='w-full outline-none border-[1px] border-slate-400 rounded-xl p-2 mb-4 md:rounded-xl mt-4'
            >
              <option value=''>Select Data Source</option>
              {dataSourceList?.data?.map((item) => {
                return (
                  <option value={item._id} key={item._id}>
                    {item.dataSourceName}
                  </option>
                );
              })}
              <option value='N/A'>N/A</option>
            </select>
          </div>

          <div className='flex justify-end md:block'>
            <button
              disabled={!value.dataSource}
              type='button'
              onClick={handleAddDataSource}
              className={
                !value.dataSource || value.dataSource === 'N/A'
                  ? ' p-3 text-white rounded-xl bg-blue-300 cursor-not-allowed'
                  : ' p-3 text-white rounded-xl bg-blue-400'
              }
            >
              Add more
            </button>
          </div>

          <div className='flex flex-row gap-2 flex-wrap mb-8'>
            {dataSourceName.map((item, index) => {
              return (
                <div
                  key={index}
                  className='flex justify-between items-center p-2 bg-slate-100 mb-2 rounded-lg'
                >
                  <p>{item}</p>
                  <GrFormClose
                    className='cursor-pointer text-2xl'
                    onClick={() => {
                      setDataSourceName((prev) =>
                        prev.filter((_, i) => i !== index)
                      );

                      setDataSourceData((prev) =>
                        prev.filter((_, i) => i !== index)
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>

        {/* <FormRow
          name='table'
          type='text'
          value={value.table}
          handleChange={handleChange}
        /> */}

        <div className='grid grid-cols-2  items-center gap-4 md:grid-cols-[1fr_100px]'>
          <FormRow
            name='table'
            type='text'
            value={value.table}
            handleChange={handleChange}
          />
          <div className='flex justify-end md:block'>
            <button
              disabled={value.table === ''}
              type='button'
              onClick={handleAddTable}
              className={
                value.table === ''
                  ? ' p-3 text-white rounded-xl bg-blue-300 cursor-not-allowed'
                  : ' p-3 text-white rounded-xl bg-blue-400'
              }
            >
              Add more
            </button>
          </div>

          <div className='flex flex-row gap-2 flex-wrap mb-8'>
            {tableData.map((item, index) => {
              return (
                <div
                  key={index}
                  className='flex justify-between items-center p-2 bg-slate-100 mb-2 rounded-lg'
                >
                  <p>{item}</p>
                  <GrFormClose
                    className='cursor-pointer text-2xl'
                    onClick={() => {
                      setTableData((prev) =>
                        prev.filter((_, i) => i !== index)
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className='grid grid-cols-2  items-center gap-4 md:grid-cols-[1fr_100px]'>
          <FormRow
            name='dimensionality'
            type='text'
            value={value.dimensionality}
            handleChange={handleChange}
          />
          <div className='flex justify-end md:block'>
            <button
              disabled={value.dimensionality === ''}
              type='button'
              onClick={handleAddDimensionality}
              className={
                value.dimensionality === ''
                  ? ' p-3 text-white rounded-xl bg-blue-300 cursor-not-allowed'
                  : ' p-3 text-white rounded-xl bg-blue-400'
              }
            >
              Add more
            </button>
          </div>

          <div className='flex flex-row gap-2 flex-wrap mb-8'>
            {dimensionalityData.map((item, index) => {
              return (
                <div
                  key={index}
                  className='flex justify-between items-center p-2 bg-slate-100 mb-2 rounded-lg'
                >
                  <p>{item}</p>
                  <GrFormClose
                    className='cursor-pointer text-2xl'
                    onClick={() => {
                      setDimensionalityData((prev) =>
                        prev.filter((_, i) => i !== index)
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className='grid place-items-center'>
          <button
            disabled={isLoading}
            type='submit'
            className={
              isLoading
                ? 'cursor-not-allowed w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
                : 'w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
            }
          >
            {isLoading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    </>
  );
};
