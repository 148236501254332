import { useCreate } from '@refinedev/core';
import { notify, notifyError } from '../shared/Toastify';
import FormRow from '../../HOC/FormRow';
import React from 'react';

import {
  IProjectObjectiveItem,
  useProjectObjectiveStore,
} from '../../store/projectObjectives.store';
import { useProjectStore } from '../../store/projects.store';
import SaveButton from '../../components/shared/SaveButton';

const AddProjectObjectiveFrom = ({
  setOpenModal,
}: {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const projectItemId = useProjectStore((state) => state.projectItemID);

  const addProjectObjectiveItem = useProjectObjectiveStore(
    (state) => state.addProjectObjectiveItem
  );
  const addProjectObjectiveId = useProjectObjectiveStore(
    (state) => state.addProjectObjectives
  );
  // console.log(projectObjectivesItem)

  const { mutate, isLoading } = useCreate<IProjectObjectiveItem>();

  const initialValues = {
    projectObjectiveName: '',
    projectObjectiveDescription: '',
    projectObjectivePriority: '',
  };

  const [value, setValue] = React.useState(initialValues);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const payload = {
      projectObjectiveName: value.projectObjectiveName,
      projectObjectiveDescription: value.projectObjectiveDescription,
      projectObjectivePriority: value.projectObjectivePriority,
      project: projectItemId,
    };

    mutate(
      {
        resource: 'project-objective/create-project-objective',
        values: payload,
        invalidates: ['all'],
      },
      {
        onSuccess: (data) => {
          if (data.data) {
            addProjectObjectiveId(data.data._id);
            addProjectObjectiveItem(data.data);
            notify('Project Objective created successfully');
            setOpenModal(false);
          }
        },
        onError: (error) => {
          notifyError(error.response.data.errorMsg);
        },
      }
    );
  };

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setValue((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className='w-[75vw] max-w-[100vw] md:w-[40rem] flex flex-col'>
        <FormRow
          name='projectObjectiveName'
          type='text'
          labelText='Project Objective Name'
          required={true}
          placeholder='Project Objective Name'
          value={value.projectObjectiveName}
          handleChange={handleChange}
        />
        <FormRow
          name='projectObjectiveDescription'
          type='text'
          labelText='Project Objective Description'
          required={true}
          placeholder='Project Objective Description'
          value={value.projectObjectiveDescription}
          handleChange={handleChange}
        />

        <label htmlFor='projectObjectivePriority'>
          Project Objective Priority
        </label>
        <select
          onChange={handleChange}
          value={value.projectObjectivePriority}
          name='projectObjectivePriority'
          id='projectObjectivePriority'
          className='outline-none border-[1px] border-slate-400  p-2 mb-8 rounded-xl mt-4'>
          <option value=''>Select Priority</option>
          <option value='High'>High</option>
          <option value='Medium'>Medium</option>
          <option value='Low'>Low</option>
        </select>

        {/* <FormRow
          name="projectObjectivePriority"
          type="text"
          labelText="Project Objective Priority"
          required={true}
          placeholder="Project Objective Priority"
          value={value.projectObjectivePriority}
          handleChange={handleChange}
        /> */}

        <SaveButton className='grid place-items-center' isLoading={isLoading} />
      </form>
    </>
  );
};

export default AddProjectObjectiveFrom;
