import { Authenticated, ErrorComponent, Refine } from '@refinedev/core';
import { RefineKbarProvider } from '@refinedev/kbar';

import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6';

import { ForgotPassword } from 'pages/forgotPassword';
import { Login } from 'pages/login';
import { Register } from 'pages/register';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import { authProvider } from './authProvider';
import Layout from 'layout';
import Discovery from 'pages/userStory';
import { KPI } from 'pages/kpi/kpi';
import { DataSource } from 'pages/dataSource';
import UploadSampleData from 'pages/uploadOfSample';
import Branding from 'pages/branding';
import { dataProvider } from 'rest-data-provider';
import SecurityPage from 'pages/security';
import ConfirmationPage from 'pages/confirmation';
import ShowHistory from 'pages/kpi/showHistory';
import ProjectObjectivePage from 'pages/projectObjectives';
import Dashboard from 'pages/dashboard';
import SingleProject from 'pages/projectList/showSingleProject';
import AccountLayout from 'layout/accountLayout';
import AccountSettings from 'components/accounts/accountSettings';
import SecurityLogin from 'components/accounts/securityLogin';
import Projects from 'pages/projects';
import { ToastContainer } from 'react-toastify';
import NotFound from 'components/errorComponent/notFound';
// import DiscoveryCostCalculator from 'pages/costCalculator/DiscoveryCostCalculator';
import KPIBusinessGlossary from 'pages/business-glossary';

function App() {
  return (
    <>
      <BrowserRouter>
        <RefineKbarProvider>
          <Refine
            dataProvider={dataProvider(
              process.env.REACT_APP_SERVER_API_URL ?? ''
            )}
            routerProvider={routerBindings}
            authProvider={authProvider}
            resources={[
              {
                name: 'dashboard',
                list: '/dashboard',
              },
              {
                name: 'project-objective',
                list: '/project-objective',
              },
              {
                name: 'user-story',
                list: '/user-story',
              },

              {
                name: 'kpi',
                show: '/kpi/show/:id',
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to='/login' />}>
                    <Layout>
                      <Outlet />
                    </Layout>
                  </Authenticated>
                }
              >
                {/* <Route index element={<NavigateToResource resource="/" />} /> */}

                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/' element={<Dashboard />} />
                <Route
                  path='/business-glossary'
                  element={<KPIBusinessGlossary />}
                />
                <Route path='datasource' element={<DataSource />} />
                <Route path='user-story' element={<Discovery />} />
                {/* uncomment this */}
                {/* <Route path='manage-user' element={<Discovery />} /> */}
                <Route path='single-project/:id' element={<SingleProject />} />
                <Route path='kpi'>
                  <Route index element={<KPI />} />
                  <Route path='show/:id' element={<ShowHistory />} />
                </Route>
                <Route
                  path='upload-sample-report'
                  element={<UploadSampleData />}
                />
                <Route path='branding' element={<Branding />} />
                <Route path='security-details' element={<SecurityPage />} />
                <Route path='confirmation' element={<ConfirmationPage />} />
                <Route path='project' element={<Projects />} />
                <Route
                  path='project-objective'
                  element={<ProjectObjectivePage />}
                />
                <Route path='account' element={<AccountLayout />}>
                  <Route index element={<AccountSettings />} />
                  <Route
                    path='account-settings'
                    element={<AccountSettings />}
                  />
                  <Route path='security-login' element={<SecurityLogin />} />
                </Route>
              </Route>
              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
              </Route>
              <Route
                element={
                  <Authenticated>
                    <Outlet />
                  </Authenticated>
                }
              >
                <Route path='*' element={<NotFound />} />

                <Route path='*' element={<ErrorComponent />} />
              </Route>

              <Route path='/' element={<Navigate to='dashboard' />} />
              {/* <Route
                path='/discovery-cost-calculator'
                element={<DiscoveryCostCalculator />}
              /> */}
            </Routes>

            <UnsavedChangesNotifier />
          </Refine>
        </RefineKbarProvider>
      </BrowserRouter>

      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </>
  );
}

export default App;
