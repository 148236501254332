import { BaseKey, BaseRecord, GetOneResponse } from '@refinedev/core';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ISecurity } from 'types/securityTypes';

interface ISecurityDetailsProps {
  projects: GetOneResponse<BaseRecord> | undefined;
  setSecurityItem: React.Dispatch<React.SetStateAction<ISecurity | undefined>>;
  setOpenSecurityViewModal: React.Dispatch<boolean>;
  setEditSecurityItem: React.Dispatch<
    React.SetStateAction<ISecurity | undefined>
  >;
  setOpenSecurityEditModal: React.Dispatch<boolean>;
  addProjectItemID: (id: string) => void;
  id: BaseKey | undefined;
  deleteProjectItem: (id: string, resource: string) => void;
  clearProjectStorage: () => void;
  navigateResource: string;
}

const SecurityDetailsSection: FC<ISecurityDetailsProps> = ({
  projects,
  setSecurityItem,
  setOpenSecurityViewModal,
  setEditSecurityItem,
  setOpenSecurityEditModal,
  id,
  addProjectItemID,
  deleteProjectItem,
  clearProjectStorage,
  navigateResource,
}) => {
  const navigate = useNavigate();
  return (
    <div className='p-4 bg-white board-bg rounded-lg h-fit w-full'>
      <div className='w-64'>
        <h4 className='text-xl font-bold'>Security Details</h4>
        {projects?.data.securities.length > 0 ? (
          projects?.data.securities.map(
            (
              item: {
                _id: string;
                compliance: string;
                dataSensitiveItem: string;
                details: string;
              },
              index: number
            ) => {
              const { dataSensitiveItem, _id: id } = item;
              const resource = 'security/delete-security';
              return (
                <div key={index} className='mt-4 bg-white p-3 rounded-lg'>
                  <div>
                    <div className='mb-10'>
                      <span className='font-semibold'>
                        Data Sensitive Item:
                      </span>
                      <p>{dataSensitiveItem}</p>
                    </div>

                    <div className='flex justify-end gap-3 mt-4'>
                      <button
                        onClick={() => {
                          setOpenSecurityViewModal(true);
                          setSecurityItem(item);
                        }}
                        className='p-2 rounded-lg text-white bg-blue-400 w-fit text-xs'>
                        View Details
                      </button>
                      <button
                        onClick={() => {
                          setOpenSecurityEditModal(true);
                          setEditSecurityItem(item);
                        }}
                        className='p-2 rounded-lg text-white bg-blue-400 w-fit'>
                        Edit
                      </button>
                      <button
                        onClick={() => deleteProjectItem(id, resource)}
                        className='p-2 rounded-lg text-white bg-pink-500 w-fit'>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              );
            }
          )
        ) : (
          <div className='mt-4 bg-white p-3 rounded-lg '>
            <div className='w-fit h-fit m-auto'>
              <button
                onClick={() => {
                  addProjectItemID(id as string);
                  clearProjectStorage();
                  navigate(navigateResource);
                }}>
                Create New Data
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SecurityDetailsSection;
