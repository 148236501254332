import { useDelete } from '@refinedev/core';

import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { KPIEditForms } from 'components/kpi/editForms';

import { useKPIStore } from 'store/kpi.store';
import { AddKPIForms } from 'components/kpi/addkpiforms';
import { IKPI } from 'types/kpi.types';
import Modal from 'components/shared/Modal';
import KPITable from 'components/kpi/table';
import Assistance from 'components/shared/Assistance';

export const KPI = () => {
  const [projectId] = useSearchParams();
  const navigate = useNavigate();
  const removeKPIItemID = useKPIStore((state) => state.removeKPIItemID);
  const removeKPIItem = useKPIStore((state) => state.removeKPIItem);

  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteItemID, setDeleteItemID] = useState<string>('');
  const [kpiItemID, setKpiItemID] = useState<IKPI | undefined>();
  const [isAssistance, setIsAssistance] = useState<boolean>(false);

  const { mutate, isLoading } = useDelete();

  return (
    <>
      <Modal
        isOpen={openModal}
        close={setOpenModal}
        className='fixed w-fit  h-[60vh] overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'
      >
        <AddKPIForms setOpenModal={setOpenModal} />
      </Modal>

      <Modal
        isOpen={openEditModal}
        close={setOpenEditModal}
        className='fixed w-fit  h-[60vh] overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'
      >
        <KPIEditForms
          kpiItemID={kpiItemID}
          setOpenEditModal={setOpenEditModal}
        />
      </Modal>

      <Modal
        isOpen={deleteModal}
        close={setDeleteModal}
        className='fixed w-fit  h-fit overflow-y-auto scrollbar-hide mx-auto top-32 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'
      >
        <div>
          <p className='text-xl text-center font-semibold mb-12'>
            Are you sure you want to
            <br /> delete?
          </p>
          <div className='flex gap-4 justify-center'>
            <button
              onClick={() => setDeleteModal(false)}
              className='w-32 bg-white border border-slate-200 p-3 rounded-xl'
            >
              Cancel
            </button>
            <button
              disabled={isLoading}
              onClick={() => {
                mutate(
                  { resource: 'kpi', id: deleteItemID },
                  {
                    onSuccess: (data) => {
                      if (data.data) {
                        removeKPIItemID(data.data._id);
                        removeKPIItem(data.data._id);
                        setDeleteModal(false);
                      }
                    },
                  }
                );
              }}
              className='w-32 bg-pink-500 text-white p-3 rounded-xl'
            >
              {isLoading ? 'Deleting...' : 'Delete'}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isAssistance}
        close={setIsAssistance}
        className='fixed w-[30vw]  h-fit overflow-y-auto scrollbar-hide mx-auto top-4 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'
      >
        <Assistance setIsAssistance={setIsAssistance} />
      </Modal>

      <div className='relative tables p-4 mt-4 mb-8 w-full h-[90vh] border-2 border-white'>
        <div className='flex justify-between mb-4'>
          <p className='font-semibold text-xl'>Key Performance Indicator</p>
          <div className='flex space-x-8 items-center'>
            <button
              onClick={() => setIsAssistance(true)}
              className='text-blue-400 underline'
            >
              Need assistance?
            </button>
            <button
              className='p-2 bg-blue-400 text-white rounded-lg capitalize'
              onClick={() => setOpenModal(true)}
            >
              Add KPI
            </button>
          </div>
        </div>

        {/* iteration */}
        <div className='absolute overflow-x-auto shadow-md rounded-lg w-full md:w-[98%] h-fit'>
          <KPITable
            setDeleteItemID={setDeleteItemID}
            setDeleteModal={setDeleteModal}
            setKpiItemID={setKpiItemID}
            setOpenEditModal={setOpenEditModal}
          />
        </div>

        <div className='absolute mx-auto left-0 right-0 bottom-12 text-center'>
          <div className=''>
            <button
              onClick={() => navigate('/datasource')}
              className='bg-blue-400 p-4 text-white rounded-xl w-28 mr-8'
            >
              Go back
            </button>
            <button
              onClick={() =>
                navigate(`/upload-sample-report?id=${projectId.get('id')}`)
              }
              className='bg-blue-400 p-4 text-white rounded-xl w-28'
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
