import { useUpdate } from '@refinedev/core';
import { notify, notifyError } from 'components/shared/Toastify';
import FormRow from 'HOC/FormRow';
import React, { SetStateAction } from 'react';
import { useSecurityStore } from 'store/securityDetail.store';
import { ISecurity } from 'types/securityTypes';

const EditForm = ({
  setOpenEditModal,

  securityItemID,
}: {
  setOpenEditModal: React.Dispatch<SetStateAction<boolean>>;
  securityItemID: ISecurity | undefined;
}) => {
  const updateSecurityItem = useSecurityStore(
    (state) => state.updateSecurityItem
  );
  const initialValues = {
    id: securityItemID?._id,
    dataSensitiveItem: securityItemID?.dataSensitiveItem,
    details: securityItemID?.details,
    compliance: securityItemID?.compliance,
  };

  const [value, setValue] = React.useState(initialValues);

  const { mutate, isLoading } = useUpdate<ISecurity>();

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setValue((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const payload = {
      dataSensitiveItem: value?.dataSensitiveItem,
      details: value?.details,
      compliance: value?.compliance,
    };
    const id = value.id as string;

    mutate(
      {
        resource: 'security/update-security',
        values: payload,
        id: id,
        invalidates: ['all'],
      },
      {
        onSuccess: (data) => {
          if (data.data) {
            updateSecurityItem(data.data);
            notify('Security details updated');
            setOpenEditModal(false);
          }
        },
        onError(error) {
          notifyError(error.response.data.errorMsg);
        },
      }
    );
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className='w-[75vw] max-w-[100vw] md:w-[40rem]'>
        <FormRow
          labelText='Data Sensitive Item'
          name='dataSensitiveItem'
          type='text'
          value={value.dataSensitiveItem}
          handleChange={handleChange}
        />
        <FormRow
          name='details'
          type='text'
          value={value.details}
          handleChange={handleChange}
        />
        <FormRow
          name='compliance'
          type='text'
          value={value.compliance}
          handleChange={handleChange}
        />

        <div className='grid place-items-center'>
          <button
            disabled={isLoading}
            type='submit'
            className={
              isLoading
                ? 'cursor-not-allowed w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
                : 'w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
            }>
            {isLoading ? 'Updating...' : 'Update'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditForm;
