import { BaseKey, BaseRecord, GetOneResponse } from '@refinedev/core';
import React, { FC } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

interface IReportProps {
  projects: GetOneResponse<BaseRecord> | undefined;

  addProjectItemID: (id: string) => void;
  id: BaseKey | undefined;
  deleteProjectItem: (id: string, resource: string) => void;
  clearProjectStorage: () => void;
  navigateResource: string;
}

const ReportSection: FC<IReportProps> = ({
  projects,
  id,
  addProjectItemID,
  deleteProjectItem,
  clearProjectStorage,
  navigateResource,
}) => {
  const navigate = useNavigate();

  return (
    <div className='mb-6 w-64'>
      <h4 className='text-xl font-bold'>Sample Reports</h4>
      {projects?.data.sampleReports.length > 0 ? (
        projects?.data.sampleReports.map(
          (
            item: {
              _id: string;
              fileName: string;
              fileURL: string;
              originalFileName: string;
            },
            index: number
          ) => {
            const { _id: id, fileURL, originalFileName } = item;
            const resource = 'report/delete-report';

            return (
              <div key={index} className='mt-4 bg-white p-3 rounded-lg'>
                <div className='mb-10 flex space-x-2'>
                  <a href={fileURL} className='underline'>
                    {originalFileName.substring(0, 20)}...
                  </a>
                  <button
                    type='button'
                    onClick={() => deleteProjectItem(id, resource)}>
                    <IoMdClose className='text-red-500 text-xl' />
                  </button>
                </div>
              </div>
            );
          }
        )
      ) : (
        <div className='mt-4 bg-white p-3 rounded-lg '>
          <div className='w-fit h-fit m-auto'>
            <button
              onClick={() => {
                addProjectItemID(id as string);
                clearProjectStorage();
                navigate(navigateResource);
              }}>
              Add New File
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportSection;
