import { GetListResponse } from '@refinedev/core';
import moment from 'moment';
import React, { FC, useRef } from 'react';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { IProject } from 'types/projectTypes';

interface IProjectItems {
  setInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setProjectId: React.Dispatch<React.SetStateAction<string>>;
  setDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteItemID: React.Dispatch<React.SetStateAction<string>>;
  setIsEditProject: React.Dispatch<React.SetStateAction<boolean>>;
  setEditItem: React.Dispatch<React.SetStateAction<IProject | undefined>>;
  isEditProject: boolean;
  isSelectedCards: number | null;
  handleSelectedCards: (index: number) => void;
  setIsSelectedCards: React.Dispatch<React.SetStateAction<number | null>>;
  project: GetListResponse<IProject> | undefined;
}

const ProjectItems: FC<IProjectItems> = ({
  setInviteModal,
  setProjectId,
  setDeleteModal,
  setDeleteItemID,
  setIsEditProject,
  setEditItem,
  isEditProject,
  isSelectedCards,
  setIsSelectedCards,
  handleSelectedCards,
  project,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const checkIfClickedOutside = (e: any) => {
    if (
      ref.current &&
      !ref.current.contains(e.target) &&
      !e.target.classList.contains('edit-button') &&
      !e.target.classList.contains('delete-button')
    ) {
      setIsSelectedCards(null);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {project?.data.map((item, index: number) => {
        const { _id, name, priority, updatedAt } = item;
        const isCardSelected = index === isSelectedCards;

        return (
          <div
            key={index}
            className='w-full bg-white rounded-lg p-4 mt-4 hover:bg-blue-400 hover:text-white'
          >
            <div
              ref={ref}
              className=' relative flex justify-end space-x-4 mb-2'
            >
              <div className='flex gap-3'>
                <button
                  onClick={() => {
                    setProjectId(_id);
                    setInviteModal(true);
                  }}
                >
                  <AiOutlineUserAdd />
                </button>
                <button onClick={() => handleSelectedCards(index)}>
                  <BiDotsVerticalRounded />
                </button>
              </div>

              {isCardSelected && (
                <div className='absolute p-4 flex flex-col gap-2 shadow-lg rounded-lg bg-white text-black'>
                  <button
                    onClick={() => {
                      setEditItem(item);
                      setIsEditProject(!isEditProject);
                    }}
                    className='capitalize text-[#57CC99] text-left edit-button'
                  >
                    edit
                  </button>
                  <button
                    onClick={() => {
                      setDeleteItemID(_id);
                      setDeleteModal(true);
                    }}
                    className='capitalize text-red-300 text-left delete-button'
                  >
                    delete
                  </button>
                </div>
              )}
            </div>
            <div>
              <p className='font-bold'>{name}</p>
            </div>
            <Link to={`/single-project/${_id}`}>
              <div className='flex flex-col  md:items-center pt-11 md:flex-row md:justify-between'>
                <div>
                  Priority:{' '}
                  <span
                    className={
                      priority === 'High'
                        ? 'font-bold text-red-300'
                        : priority === 'Medium'
                        ? 'font-bold text-yellow-300'
                        : priority === 'Low'
                        ? 'font-bold text-green-300'
                        : ''
                    }
                  >
                    {priority}
                  </span>
                </div>

                <p className='text-xs mt-2 md:mt-0'>
                  {moment(updatedAt).format('L')}
                </p>
              </div>
            </Link>
          </div>
        );
      })}
    </>
  );
};

export default ProjectItems;
