import { BaseRecord } from '@refinedev/core';
import NoData from '../../components/errorComponent/noData';
import React, { Dispatch, FC } from 'react';
import { MdDeleteSweep } from 'react-icons/md';
import { RiEdit2Fill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { IProjectObjectiveItem } from 'store/projectObjectives.store';

interface IProjectObjectiveItemProps {
  projectObjectiveItem: IProjectObjectiveItem[];
  setIsAssistance: Dispatch<React.SetStateAction<boolean>>;
  setOpenModal: Dispatch<React.SetStateAction<boolean>>;
  setProjectObjectiveItemID: Dispatch<
    React.SetStateAction<BaseRecord | undefined>
  >;
  setOpenEditModal: Dispatch<React.SetStateAction<boolean>>;
  setDeleteItemID: Dispatch<React.SetStateAction<string>>;
  setDeleteModal: Dispatch<React.SetStateAction<boolean>>;
}

const List: FC<IProjectObjectiveItemProps> = ({
  projectObjectiveItem,
  setDeleteItemID,
  setDeleteModal,
  setIsAssistance,
  setOpenEditModal,
  setOpenModal,
  setProjectObjectiveItemID,
}) => {
  const navigate = useNavigate();

  return (
    <div className='relative tables p-4 mt-4 mb-8 w-full h-[90vh] border-2 border-white'>
      <div className='flex justify-between mb-4'>
        <p className='font-semibold text-xl'>Project Objectives</p>
        <div className='flex space-x-8 items-center'>
          <button
            onClick={() => setIsAssistance(true)}
            className='text-blue-400 underline'>
            Need assistance?
          </button>
          <button
            className='p-2 bg-blue-400 text-white rounded-lg capitalize'
            onClick={() => {
              setOpenModal(true);
            }}>
            Add Project Objective
          </button>
        </div>
      </div>

      {/* iteration */}
      <div className='absolute overflow-x-auto shadow-md rounded-lg w-full md:w-[98%] h-fit'>
        <table className='w-full text-sm text-left text-gray-500 '>
          <thead className='text-xs uppercase bg-white'>
            <tr>
              <th scope='col' className='p-4'>
                id
              </th>
              <th scope='col' className='px-6 py-6'>
                Objective Name
              </th>
              <th scope='col' className='px-6 py-6'>
                Objective Description
              </th>
              <th scope='col' className='px-6 py-6'>
                Objective Priority
              </th>

              <th scope='col' className='px-6 py-6'>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {projectObjectiveItem.map((item, index: number) => {
              const {
                _id,
                projectObjectiveName,
                projectObjectiveDescription,
                projectObjectivePriority,
              } = item;

              return (
                <tr
                  key={index}
                  className='bg-none border-b-2 border-white hover:bg-white '>
                  <td className='px-6 py-4'>{_id}</td>
                  <td className='px-6 py-4'>{projectObjectiveName}</td>
                  <td className='px-6 py-4'>{projectObjectiveDescription}</td>
                  <td className='px-6 py-4'>{projectObjectivePriority}</td>

                  <td className='text-right flex gap-8 px-6 py-4'>
                    <button
                      data-testid='edit-button'
                      onClick={() => {
                        setProjectObjectiveItemID(item);
                        setOpenEditModal(true);
                      }}>
                      <RiEdit2Fill className='text-2xl text-green-400' />
                    </button>
                    <button
                      data-testid='delete-button'
                      onClick={() => {
                        setDeleteItemID(_id);
                        setDeleteModal(true);
                      }}>
                      <MdDeleteSweep className='text-2xl text-red-500' />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {projectObjectiveItem.length < 1 ? <NoData /> : null}
      </div>

      <div className='absolute mx-auto left-0 right-0 bottom-12 text-center'>
        <div className=''>
          <button
            onClick={() => navigate('/user-story')}
            className='bg-blue-400 p-4 text-white rounded-xl w-28'>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default List;
