import { KPIItem } from 'pages/projectList/showSingleProject';
import React from 'react';
import { useUpdate, useInvalidate, useList } from '@refinedev/core';
import { GrClose, GrFormClose } from 'react-icons/gr';
import FormRow from 'HOC/FormRow';

const EditKPIDetails = ({
  setOpenKPIEditModal,
  editKPIItem,
}: {
  setOpenKPIEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  editKPIItem: KPIItem | undefined;
}) => {
  const invalidate = useInvalidate();
  const initialValues = {
    id: editKPIItem?._id,
    metric: editKPIItem?.metric,
    metricDefinition: editKPIItem?.metricDefinition,
    metricCalculation: editKPIItem?.metricCalculation,
    comparators: editKPIItem?.comparator,
  };

  const [value, setValue] = React.useState(initialValues);

  const [comparatorDefaultValue, setComparatorDefaultValue] = React.useState<
    string[] | undefined
  >(value.comparators);
  const [comparatorInput, setComparatorInput] = React.useState<string>('');
  const [comparitorValue, setComparatorValue] = React.useState<string>('');
  const [dataSourceValue, setDataSourceValue] = React.useState<string>('');
  const [dataSourceName, setDataSourceName] = React.useState<string | null>('');
  const [dataSourceDefaultValue, setDataSourceDefaultValue] = React.useState(
    editKPIItem?.dataSource
  );
  const [tableDefaultValue, setTableDefaultValue] = React.useState<
    string[] | undefined
  >(editKPIItem?.table);
  const [tableInput, setTableInput] = React.useState<string>('');
  const [dimensionalityDefaultValue, setDimensionalityDefaultValue] =
    React.useState<string[] | undefined>(editKPIItem?.dimensionality);
  const [dimensionalityInput, setDimensionalityInput] =
    React.useState<string>('');

  const { data: dataSourceList } = useList({ resource: 'datasource' });

  const { mutate, isLoading } = useUpdate();

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setValue((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleEditComparator = () => {
    if (comparitorValue === 'custom') {
      comparatorDefaultValue?.push(comparatorInput);
    } else {
      comparatorDefaultValue?.push(comparitorValue);
    }
    setComparatorInput('');
    setComparatorValue('');
  };

  const handleEditDataSource = () => {
    const dataName = dataSourceName as string;
    if (dataName !== '') {
      setDataSourceDefaultValue((prev) => [
        ...(prev || []), // use an empty array if prev is undefined
        {
          _id: dataSourceValue,
          dataSourceName: dataName,
        },
      ]);
    }
  };

  const handleEditTable = () => {
    setTableDefaultValue((prev) => [
      ...(prev || []), // use an empty array if prev is undefined
      tableInput,
    ]);
    setTableInput('');
  };

  const handleEditDimensionality = () => {
    setDimensionalityDefaultValue((prev) => [
      ...(prev || []), // use an empty array if prev is undefined
      dimensionalityInput,
    ]);
    setDimensionalityInput('');
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const payload = {
      metric: value?.metric,
      metricDefinition: value?.metricDefinition,
      metricCalculation: value?.metricCalculation,
      comparator: comparatorDefaultValue,
      dataSource: dataSourceDefaultValue?.map((dataSource) => dataSource._id),
      table: tableDefaultValue,
      dimensionality: dimensionalityDefaultValue,
    };
    const id = value.id as string;

    mutate(
      { resource: 'kpi', values: payload, id: id },
      {
        onSuccess: (data) => {
          if (data.data) {
            invalidate({
              resource: 'project-objective',
              invalidates: ['all'],
            });
            setOpenKPIEditModal(false);
          }
        },
      }
    );
  };

  return (
    <div>
      <div className='flex justify-end mb-8'>
        <button
          className='font-semibold'
          onClick={() => setOpenKPIEditModal(false)}>
          <GrClose className='text-2xl' />
        </button>
      </div>
      <form
        onSubmit={handleSubmit}
        className='w-[75vw] max-w-[100vw] md:w-[40rem]'>
        <FormRow
          name='metric'
          type='text'
          value={value.metric}
          handleChange={handleChange}
        />
        <FormRow
          labelText='Metric Definition'
          name='metricDefinition'
          value={value.metricDefinition}
          handleChange={handleChange}
        />

        <FormRow
          labelText='Metric Calculation'
          name='metricCalculation'
          type='text'
          value={value.metricCalculation}
          handleChange={handleChange}
        />

        <div className='grid grid-cols-2  items-center gap-4 md:grid-cols-[1fr_100px]'>
          <div className='flex flex-col'>
            <label htmlFor='comparitor' className='capitalize'>
              Comparator
            </label>
            {comparitorValue !== 'custom' ? (
              <select
                id='comparitor'
                name='comparator'
                value={comparitorValue}
                onChange={(e) => {
                  setComparatorValue(e.target.value);
                }}
                className='w-full outline-none border-[1px] border-slate-400 rounded-xl p-2 mb-8 md:rounded-xl mt-4'>
                <option value=''>Select Comparator</option>
                <option value='Last-Year'>Last Year</option>
                <option value='Last-Month'>Last Month</option>
                <option value='Last-Week'>Last Week</option>
                <option value='Last-Day'>Last Day</option>
                <option value='custom'>Custom</option>
              </select>
            ) : (
              <input
                type='text'
                name='comparator'
                value={comparatorInput}
                onChange={(e) => {
                  setComparatorInput(e.target.value);
                }}
                placeholder='Add Custom Comparator'
                className='outline-none border-[1px] border-slate-400  p-2 mb-8 rounded-xl'
              />
            )}
          </div>
          <div className='flex justify-end md:block'>
            <button
              disabled={
                !comparitorValue ||
                (comparitorValue === 'custom' && !comparatorInput)
              }
              type='button'
              onClick={handleEditComparator}
              className={
                !comparitorValue ||
                (comparitorValue === 'custom' && !comparatorInput)
                  ? ' p-3 text-white rounded-xl bg-blue-300 cursor-not-allowed'
                  : ' p-3 text-white rounded-xl bg-blue-400'
              }>
              Add more
            </button>
          </div>

          <div className='mb-4'>
            {comparatorDefaultValue?.map((item, index) => {
              return (
                <div
                  key={index}
                  className='flex justify-between items-center p-2 bg-slate-100 mb-2 rounded-lg'>
                  <p>{item}</p>
                  <GrFormClose
                    className='cursor-pointer text-2xl'
                    onClick={() => {
                      // setValue((prevValue) => ({
                      //   ...prevValue,
                      //   comparator: prevValue.comparator?.filter(
                      //     (_, i) => i !== index
                      //   ),
                      // }));

                      setComparatorDefaultValue((prev) =>
                        prev?.filter((_, i) => i !== index)
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className='grid grid-cols-2  items-center gap-4 md:grid-cols-[1fr_100px]'>
          <div className='flex flex-col'>
            <label htmlFor='datasource' className='capitalize'>
              Data Source
            </label>
            <select
              id='datasource'
              name='datasource'
              onChange={(e) => {
                const selectedIndex = e.target.selectedIndex;
                const selectedOption = e.target.options[selectedIndex];
                const dataName = selectedOption.textContent;
                setDataSourceName(dataName);

                setDataSourceValue(e.target.value);
              }}
              className='w-full outline-none border-[1px] border-slate-400 rounded-xl p-2 mb-8 md:rounded-xl mt-4'>
              <option value=''>Select Data Source</option>
              {dataSourceList?.data?.map((item) => {
                return (
                  <option value={item._id} key={item._id}>
                    {item.dataSourceName}
                  </option>
                );
              })}
            </select>
          </div>

          <div className='flex justify-end md:block'>
            <button
              disabled={!dataSourceValue}
              type='button'
              onClick={handleEditDataSource}
              className={
                !dataSourceValue
                  ? ' p-3 text-white rounded-xl bg-blue-300 cursor-not-allowed'
                  : ' p-3 text-white rounded-xl bg-blue-400'
              }>
              Add more
            </button>
          </div>

          <div className='mb-4'>
            {dataSourceDefaultValue?.map((item, index) => {
              return (
                <div
                  key={index}
                  className='flex justify-between items-center p-2 bg-slate-100 mb-2 rounded-lg'>
                  <p>{item.dataSourceName}</p>
                  <GrFormClose
                    className='cursor-pointer text-2xl'
                    onClick={() => {
                      setDataSourceDefaultValue((prev) =>
                        prev?.filter((_, i) => i !== index)
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className='grid grid-cols-2  items-center gap-4 md:grid-cols-[1fr_100px]'>
          <FormRow
            name='table'
            type='text'
            value={tableInput}
            handleChange={(e) => {
              setTableInput(e.target.value);
            }}
          />
          <div className='flex justify-end md:block'>
            <button
              disabled={tableInput === ''}
              type='button'
              onClick={handleEditTable}
              className={
                tableInput === ''
                  ? ' p-3 text-white rounded-xl bg-blue-300 cursor-not-allowed'
                  : ' p-3 text-white rounded-xl bg-blue-400'
              }>
              Add more
            </button>
          </div>

          <div className='mb-4'>
            {tableDefaultValue?.map((item, index) => {
              return (
                <div
                  key={index}
                  className='flex justify-between items-center p-2 bg-slate-100 mb-2 rounded-lg'>
                  <p>{item}</p>
                  <GrFormClose
                    className='cursor-pointer text-2xl'
                    onClick={() => {
                      setTableDefaultValue((prev) =>
                        prev?.filter((_, i) => i !== index)
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className='grid grid-cols-2  items-center gap-4 md:grid-cols-[1fr_100px]'>
          <FormRow
            name='dimensionality'
            type='text'
            value={dimensionalityInput}
            handleChange={(e) => setDimensionalityInput(e.target.value)}
          />
          <div className='flex justify-end md:block'>
            <button
              disabled={dimensionalityInput === ''}
              type='button'
              onClick={handleEditDimensionality}
              className={
                dimensionalityInput === ''
                  ? ' p-3 text-white rounded-xl bg-blue-300 cursor-not-allowed'
                  : ' p-3 text-white rounded-xl bg-blue-400'
              }>
              Add more
            </button>
          </div>

          <div className='mb-4'>
            {dimensionalityDefaultValue?.map((item, index) => {
              return (
                <div
                  key={index}
                  className='flex justify-between items-center p-2 bg-slate-100 mb-2 rounded-lg'>
                  <p>{item}</p>
                  <GrFormClose
                    className='cursor-pointer text-2xl'
                    onClick={() => {
                      setDimensionalityDefaultValue((prev) =>
                        prev?.filter((_, i) => i !== index)
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className='grid place-items-center'>
          <button
            disabled={isLoading}
            type='submit'
            className={
              isLoading
                ? 'cursor-not-allowed w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
                : 'w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
            }>
            {isLoading ? 'Updating...' : 'Update'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditKPIDetails;
