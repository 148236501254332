import { IProject } from 'types/projectTypes';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface IProjectStore {
  projectItemID: string;
  projectItem: IProject[];
  addProjectItemID: (id: string) => void;
  addProjectItem: (item: IProject) => void;
  removeProjectItemID: (id: string) => void;
  clearProjectItemID?: () => void;
  clearProjectItem: () => void;
  removeProjectItem: (id: string) => void;
}

export const useProjectStore = create<IProjectStore>()(
  persist(
    (set, get) => ({
      projectItemID: '',
      projectItem: [],
      addProjectItemID: (id: string) => {
        set((state) => ({ projectItemID: id }));
      },
      addProjectItem: (item: IProject) => {
        set((state) => ({ projectItem: [...state.projectItem, item] }));
      },
      removeProjectItemID: (id: string) => {
        set((state) => ({ projectItemID: '' }));
      },

      removeProjectItem: (id: string) => {
        set((state) => ({
          projectItem: state.projectItem.filter((item) => item._id !== id),
        }));
      },

      clearProjectItemID: () => {
        set((state) => ({ projectItemID: '' }));
      },
      clearProjectItem: () => {
        set((state) => ({ projectItem: [] }));
      },
    }),

    {
      name: 'project-storage',
    }
  )
);
