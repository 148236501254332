import { notify, notifyError } from 'components/shared/Toastify';
import FormRow from 'HOC/FormRow';
import { initFirebase } from 'config/firebase';
import { getAuth, updatePassword } from 'firebase/auth';
import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';

export type FormValues = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const SecurityLogin = () => {
  initFirebase();
  const auth = getAuth();
  const user = auth.currentUser;

  const {
    register,
    handleSubmit,
    resetField,

    watch,
    formState: { errors },
  } = useForm<FormValues>();
  const onSubmit = (data: FormValues) => {
    const { confirmPassword, newPassword } = data;
    updatePassword(user!, confirmPassword)
      .then(() => {
        notify('Password updated successfully');
      })
      .catch((error) => {
        notifyError(error.message);
      });
  };

  return (
    <>
      <div className='w-full md:w-1/2 h-fit mx-auto bg-white drop-shadow-7xl rounded-xl p-8 mb-4'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-4'>
            <label className='font-semibold' htmlFor='new_password'>
              New Password
            </label>
            <input
              id='new_password'
              placeholder='New Password'
              type='password'
              {...register('newPassword', { required: true })}
              className={
                errors.newPassword
                  ? 'outline-none border-[1px] border-slate-400  p-2 rounded-lg'
                  : 'outline-none border-[1px] border-slate-400  p-2 mb-4 rounded-lg'
              }
            />
            {errors.newPassword && (
              <p className='text-red-400  mb-4'>This field is required</p>
            )}
          </div>

          <div className='flex flex-col gap-4'>
            <label className='font-semibold' htmlFor='confirm_password'>
              Confirm Password
            </label>
            <input
              id='confirm_password'
              placeholder='Confirm new Password'
              type='password'
              {...register('confirmPassword', {
                required: true,
                validate: (val: string) => {
                  if (watch('newPassword') !== val) {
                    return "Passwords don't match";
                  }
                },
              })}
              className={
                errors.confirmPassword
                  ? 'outline-none border-[1px] border-slate-400  p-2 rounded-lg'
                  : 'outline-none border-[1px] border-slate-400  p-2 mb-4 rounded-lg'
              }
            />
            {errors.confirmPassword && (
              <p className='text-red-400  mb-4'>
                {errors.confirmPassword.message as string}
              </p>
            )}
          </div>
          {/* <FormRow
            register={register}
            labelText='New Password'
            name='newPassword'
            type='password'
            placeholder='Enter new password'
          />

          <FormRow
            labelText='Confirm Password'
            name='confirmPassword'
            type='password'
            placeholder='Confirm new password'
          /> */}
          <div className='flex justify-center'>
            <button
              className='p-3 text-white bg-blue-400 rounded-lg'
              type='submit'>
              Change Password
            </button>
          </div>
        </form>
      </div>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </>
  );
};

export default SecurityLogin;
