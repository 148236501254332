import NoDataIcon from '../../assets/SVG/nodata';
import React from 'react';

const NoData = () => {
  return (
    <div className='flex justify-center'>
      <div className='flex flex-col items-center my-8'>
        <NoDataIcon />
        <p className='mt-4 font-bold'>No Data Found</p>
        <p className='text-sm'>Start creating your documents</p>
      </div>
    </div>
  );
};

export default NoData;
