import React from 'react';

const SaveButton = ({
  isLoading,
  className,
}: {
  isLoading: boolean;
  className: string;
}) => {
  return (
    <div className={className}>
      <button
        disabled={isLoading}
        type='submit'
        className={
          isLoading
            ? 'cursor-not-allowed w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
            : 'w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
        }>
        {isLoading ? 'Saving...' : 'Save'}
      </button>
    </div>
  );
};

export default SaveButton;
