import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface IUserData {
  _id?: string;
  email: string;
  fullName: string;
  profileImage: string;
  phoneNumber: string;
  bio?: string;
}

interface IUser {
  user: IUserData;
  addUser: (item: IUserData) => void;
  removeUser: (id: string) => void;
  clearUser?: () => void;
}

export const useUserStore = create<IUser>()(
  persist(
    (set, get) => ({
      user: {
        _id: '',
        email: '',
        fullName: '',
        profileImage: '',
        phoneNumber: '',
        bio: '',
      },

      addUser: (item) => {
        set((state) => ({ user: { ...state.user, ...item } }));
      },

      removeUser: (id: string) => {
        set((state) => ({ user: { ...state.user, _id: '' } }));
      },

      clearUser: () => {
        set((state) => ({ user: { ...state.user, _id: '' } }));
      },
    }),
    {
      name: 'user-storage',
    }
  )
);
