import { DatasourceItem } from 'pages/projectList/showSingleProject';
import React from 'react';
import { GrClose } from 'react-icons/gr';

const ViewDetails = ({
  dataSourceItem,
  setOpenDataSourceModal,
}: {
  dataSourceItem: DatasourceItem | undefined;
  setOpenDataSourceModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  console.log(dataSourceItem);

  return (
    <div className='w-[75vw] max-w-[100vw] md:w-[30vw] '>
      <div className='flex justify-end mb-8'>
        <button
          className='font-semibold'
          onClick={() => setOpenDataSourceModal(false)}>
          <GrClose className='text-2xl' />
        </button>
      </div>
      <h4 className='font-semibold text-center text-xl mb-4'>
        Data Source Details
      </h4>
      <div className='my-6'>
        <span className='font-semibold'>ID:</span>
        <p>{dataSourceItem?._id}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Data Source Name:</span>
        <p>{dataSourceItem?.dataSourceName}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Data Source Type:</span>
        <p>{dataSourceItem?.dataSourceType}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Data Source Sub-Type:</span>
        <p>{dataSourceItem?.dataSourceSubType}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Estimate Volume in GB:</span>
        <p>{dataSourceItem?.estimateVolumeInGB}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Attached file:</span>
        <br />
        <a href={dataSourceItem?.fileURL} className='underline'>
          {dataSourceItem?.fileName}
        </a>
      </div>
    </div>
  );
};

export default ViewDetails;
