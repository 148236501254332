import { BaseKey, BaseRecord, GetOneResponse } from '@refinedev/core';
import { KPIItem } from 'pages/projectList/showSingleProject';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface IKPIProps {
  projects: GetOneResponse<BaseRecord> | undefined;
  setKpiItem: React.Dispatch<React.SetStateAction<KPIItem | undefined>>;
  setOpenKPIViewModal: React.Dispatch<boolean>;
  setEditKPIItem: React.Dispatch<React.SetStateAction<KPIItem | undefined>>;
  setOpenKPIEditModal: React.Dispatch<boolean>;
  addProjectItemID: (id: string) => void;
  id: BaseKey | undefined;
  deleteProjectItem: (id: string, resource: string) => void;
  clearProjectStorage: () => void;
  navigateResource: string;
}

const KPISection: FC<IKPIProps> = ({
  projects,
  setKpiItem,
  setOpenKPIViewModal,
  setEditKPIItem,
  setOpenKPIEditModal,
  id,
  addProjectItemID,
  deleteProjectItem,
  clearProjectStorage,
  navigateResource,
}) => {
  const navigate = useNavigate();
  return (
    <div className='p-4 bg-white board-bg rounded-lg h-fit w-full'>
      <div className='w-64'>
        <h4 className='text-xl font-bold'>Key Performance Indicator</h4>
        {projects?.data.kpis.length > 0 ? (
          projects?.data.kpis.map(
            (
              item: {
                _id: string;
                comparator: string[];
                dataSource: { _id: string; dataSourceName: string }[];
                dimensionality: string[];
                metric: string;
                metricCalculation: string;
                metricDefinition: string;
                reportedFrequency: string;
                table: string[];
                createdAt: string;
                updatedAt: string;
              },
              index: number
            ) => {
              const { metric, _id: id } = item;
              const resource = 'kpi';
              return (
                <div key={index} className='mt-4 bg-white p-3 rounded-lg'>
                  <div>
                    <div>
                      <div className='flex justify-end'>
                        <button onClick={() => navigate(`/kpi/show/${id}`)}>
                          History
                        </button>
                      </div>
                      <div>
                        <span className='font-semibold'>Metric:</span>
                        <p className='font-semibold'>{metric}</p>
                      </div>
                    </div>

                    <div className='flex justify-end gap-3 mt-8'>
                      <button
                        onClick={() => {
                          setOpenKPIViewModal(true);
                          setKpiItem(item);
                        }}
                        className='p-2 rounded-lg text-white bg-blue-400 w-fit text-xs'
                      >
                        View Details
                      </button>
                      <button
                        onClick={() => {
                          setOpenKPIEditModal(true);
                          setEditKPIItem(item);
                        }}
                        className='p-2 rounded-lg text-white bg-blue-400 w-fit'
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => deleteProjectItem(id, resource)}
                        className='p-2 rounded-lg text-white bg-pink-500 w-fit'
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              );
            }
          )
        ) : (
          <div className='mt-4 bg-white p-3 rounded-lg '>
            <div className='w-fit h-fit m-auto'>
              <button
                onClick={() => {
                  addProjectItemID(id as string);
                  clearProjectStorage();
                  navigate(navigateResource);
                }}
              >
                Create New Data
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default KPISection;
