import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface IDataSourceItem {
  _id: string;
  dataSourceType: string;
  dataSourceSubType: string;
  other: string;
  dataSourceName: string;
  estimateVolumeInGB: number;
  project: string[];
}

interface IDataSource {
  dataSourceItemID: string[];
  dataSourceItem: IDataSourceItem[];
  addDataSourceItemID: (id: string) => void;
  addDataSourceItem: (item: IDataSourceItem) => void;
  updateDataSourceItem: (item: IDataSourceItem) => void;
  removeDataSourceItemID: (id: string) => void;
  removeDataSourceItem: (id: string) => void;
  clearDataSourceItemID: () => void;
  clearDataSourceItem: () => void;
}

export const useDataSourceStore = create<IDataSource>()(
  persist(
    (set, get) => ({
      dataSourceItemID: [],
      dataSourceItem: [],

      addDataSourceItemID: (id: string) => {
        set((state) => ({ dataSourceItemID: [...state.dataSourceItemID, id] }));
      },

      addDataSourceItem: (item: IDataSourceItem) => {
        set((state) => ({ dataSourceItem: [...state.dataSourceItem, item] }));
      },

      updateDataSourceItem: (updateItem: IDataSourceItem) => {
        set((state) => ({
          dataSourceItem: state.dataSourceItem.map((item) =>
            item._id === updateItem._id ? updateItem : item
          ),
        }));
      },

      removeDataSourceItemID: (id: string) => {
        set((state) => ({
          dataSourceItemID: state.dataSourceItemID.filter(
            (item) => item !== id
          ),
        }));
      },
      removeDataSourceItem: (id: string) => {
        set((state) => ({
          dataSourceItem: state.dataSourceItem.filter(
            (item) => item._id !== id
          ),
        }));
      },

      clearDataSourceItemID: () => {
        set((state) => ({ dataSourceItemID: [] }));
      },

      clearDataSourceItem: () => {
        set((state) => ({ dataSourceItem: [] }));
      },
    }),

    {
      name: 'datasource-storage',
    }
  )
);
