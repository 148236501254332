import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';

const AccountLayout = () => {
  return (
    <div className='mt-4 mb-8 p-8 bg-white rounded-lg'>
      <div className='flex gap-8 mb-4  py-2 md:text-lg'>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? 'text-blue-400 border-b-2 pb-2 border-blue-400'
              : undefined
          }
          to='account-settings'>
          Account Settings
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? 'text-blue-400 border-b-2 pb-2 border-blue-400'
              : undefined
          }
          to='security-login'>
          Login & Security
        </NavLink>
      </div>

      <Outlet />
    </div>
  );
};

export default AccountLayout;
