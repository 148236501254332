import { useCreate, useDelete, useInvalidate } from '@refinedev/core';
import { notify, notifyError } from 'components/shared/Toastify';
import Loading from 'components/shared/loading';
import React, { ChangeEvent } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useSampleReportStore } from 'store/sampleReport.store';

import { useProjectStore } from 'store/projects.store';
import { ISampleReport } from 'types/sampleReport.types';

const UploadSampleData = () => {
  const projectId = useProjectStore((state) => state.projectItemID);
  const addSampleReport = useSampleReportStore(
    (state) => state.addSampleReport
  );
  const removeSampleReport = useSampleReportStore(
    (state) => state.removeSampleReport
  );
  const sampleReport = useSampleReportStore((state) => state.SampleReport);
  const navigate = useNavigate();

  const [imageFile, setImageFile] = React.useState<FileList | null>(null);

  const { mutate, isLoading } = useCreate<ISampleReport>();

  const { mutate: deleteItem } = useDelete();
  const invalidate = useInvalidate();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    setImageFile(files);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const form = new FormData();

    if (imageFile !== null) {
      for (const files of imageFile) {
        form.append('reportFiles', files);
      }
    }
    form.append('projectId', projectId);

    mutate(
      {
        resource: 'report/upload-sample-report',
        values: form,
      },
      {
        onSuccess(data) {
          addSampleReport(data.data);
          invalidate({
            resource: 'report/get-all-reports',
            invalidates: ['all'],
          });
          notify('Report uploaded successfully');
        },
      }
    );
  };

  return (
    <div className='relative tables p-4 mt-4 mb-8 w-full h-[90vh] border-2 border-white scroll-auto'>
      {isLoading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit} encType='multipart/form-data'>
          <div className='flex justify-center '>
            <div className='text-center'>
              <p className='font-semibold text-xl'>Upload Sample Report</p>
              <p className='text-sm'>
                Upload any examples of report ideas - Optional
              </p>
              {/* <div className='md:w-[40rem] h-96 border-2 border-dashed border-slate-400 rounded-xl my-8'>


           </div> */}
              <div className='flex items-center justify-center w-full'>
                <label
                  htmlFor='dropzone-file'
                  className='flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer  hover:bg-blue-50 md:w-[40rem] my-8'>
                  <div className='flex flex-col items-center justify-center pt-5 pb-6'>
                    <svg
                      aria-hidden='true'
                      className='w-10 h-10 mb-3 text-gray-400'
                      fill='none'
                      stroke='currentColor'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'></path>
                    </svg>
                    <p className='mb-2 text-sm text-gray-500 '>
                      <span className='font-semibold'>Click to upload</span> or
                      drag and drop
                    </p>
                    <p className='text-xs text-gray-500 '>
                      SVG, PNG, JPG, etc.
                    </p>
                  </div>
                  <input
                    id='dropzone-file'
                    type='file'
                    name='reportFiles'
                    className='hidden'
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className='flex justify-start mb-4'>
                <div className='flex flex-col gap-2 text-left'>
                  <p>Attached files</p>
                  {sampleReport.map((item, index) => {
                    return (
                      <div key={index}>
                        <a href={item.fileURL} className='text-left underline'>
                          {/* onClick={() => handleDownload(item.fileName)}> */}
                          {item.originalFileName}
                        </a>
                        <button
                          type='button'
                          onClick={() => {
                            deleteItem(
                              {
                                resource: 'report/delete-report',
                                id: item._id,
                                invalidates: ['all'],
                              },
                              {
                                onSuccess: (data) => {
                                  removeSampleReport(data.data._id);
                                  notify('Report successfully deleted');
                                },
                                onError(error) {
                                  notifyError(error.response.data.errorMsg);
                                },
                              }
                            );
                          }}>
                          <IoMdClose className='text-red-500 text-xl' />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <button
                disabled={isLoading}
                type='submit'
                className={
                  isLoading
                    ? 'cursor-not-allowed w-24 h-fit bg-blue-400 text-white p-3 rounded-md'
                    : 'w-24 h-fit bg-blue-400 text-white p-3 rounded-md'
                }>
                {isLoading ? 'Uploading...' : 'Upload'}
              </button>
            </div>
          </div>
        </form>
      )}
      <div className='absolute mx-auto left-0 right-0 bottom-12 text-center'>
        <div className=''>
          <button
            onClick={() => navigate('/kpi')}
            className='bg-blue-400 p-4 text-white rounded-xl w-28 mr-8'>
            Go back
          </button>
          <button
            onClick={() => navigate(`/branding`)}
            className='bg-blue-400 p-4 text-white rounded-xl w-28'>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadSampleData;
