import { ISampleReport } from 'types/sampleReport.types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface ISampleReportStore {
  SampleReport: ISampleReport[];

  addSampleReport: (item: ISampleReport) => void;
  updateSampleReport: (updateItem: ISampleReport) => void;
  removeSampleReport: (id: string) => void;
  clearSampleReport: () => void;
}

export const useSampleReportStore = create<ISampleReportStore>()(
  persist(
    (set, get) => ({
      SampleReport: [],

      addSampleReport: (item: ISampleReport) => {
        set((state) => ({
          SampleReport: [...state.SampleReport, item],
        }));
      },
      updateSampleReport: (updateItem: ISampleReport) => {
        set((state) => ({
          SampleReport: state.SampleReport.map((item) =>
            item._id === updateItem._id ? updateItem : item
          ),
        }));
      },

      removeSampleReport: (id: string) => {
        set((state) => ({
          SampleReport: state.SampleReport.filter((item) => item._id !== id),
        }));
      },

      clearSampleReport: () => {
        set((state) => ({ SampleReport: [] }));
      },
    }),
    {
      name: 'sampleReport-storage',
    }
  )
);
