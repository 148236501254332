import { ISecurity } from 'types/securityTypes';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface ISecurityStore {
  securityItemID: string[];
  securityItem: ISecurity[];
  addSecurityItemID: (id: string) => void;
  addSecurityItem: (item: ISecurity) => void;
  updateSecurityItem: (item: ISecurity) => void;
  removeSecurityItemID: (id: string) => void;
  removeSecurityItem: (id: string) => void;
  clearSecurityItemID: () => void;
  clearSecurityItem: () => void;
}

export const useSecurityStore = create<ISecurityStore>()(
  persist(
    (set, get) => ({
      securityItemID: [],
      securityItem: [],

      addSecurityItemID: (id: string) => {
        set((state) => ({ securityItemID: [...state.securityItemID, id] }));
      },
      addSecurityItem: (item: ISecurity) => {
        set((state) => ({ securityItem: [...state.securityItem, item] }));
      },

      updateSecurityItem: (updateItem: ISecurity) => {
        set((state) => ({
          securityItem: state.securityItem.map((item) =>
            item._id === updateItem._id ? updateItem : item
          ),
        }));
      },
      removeSecurityItemID: (id: string) => {
        set((state) => ({
          securityItemID: state.securityItemID.filter((item) => item !== id),
        }));
      },

      removeSecurityItem: (id: string) => {
        set((state) => ({
          securityItem: state.securityItem.filter((item) => item._id !== id),
        }));
      },

      clearSecurityItemID: () => {
        set((state) => ({ securityItemID: [] }));
      },
      clearSecurityItem: () => {
        set((state) => ({ securityItem: [] }));
      },
    }),

    {
      name: 'security-details-storage',
    }
  )
);
