import { useMany } from '@refinedev/core';
import React from 'react';
import { useProjectStore } from 'store/projects.store';
import { useStoryStore } from 'store/userStory.store';
import { IUser } from 'types/user.types';
import { IUserStory } from 'types/userStory.types';
import { filterConfirmationData } from 'utils/filterConfirmationData';

const Story = () => {
  const storyItemsID = useStoryStore((state) => state.storyItemID);
  const { data } = useMany<IUserStory>({
    resource: 'user-story/get-multiple-story',
    ids: storyItemsID,
  });

  const projectItemId = useProjectStore((state) => state.projectItemID);

  const projectStory = filterConfirmationData(data, projectItemId);

  return (
    <div className='p-4 mb-8'>
      <div className=' uppercase bg-white p-4 rounded-xl font-bold'>
        Story Details
      </div>
      <table className='w-full text-sm text-left text-gray-500 '>
        <thead className='uppercase font-bold bg-none border-b-2 border-white'>
          <tr>
            <th scope='col' className='px-6 py-6'>
              Story / Report
            </th>
            <th scope='col' className='px-6 py-6'>
              Story Detail
            </th>
            <th scope='col' className='px-6 py-6'>
              Intended Audience
            </th>
            {/* 
            <th scope='col' className='px-6 py-6'>
              Actions
            </th> */}
          </tr>
        </thead>
        <tbody>
          {projectStory?.map((item, index: number) => {
            const { _id, story, intendedAudience, storyDetails } = item;
            return (
              <tr
                className='bg-none border-b-2 border-white hover:bg-white '
                key={index}
              >
                {/* <th className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap '> */}

                <td className='px-6 py-4'>{story}</td>
                <td className='px-6 py-4'>
                  {storyDetails.substring(0, 200)}
                  {storyDetails.length > 200 ? '...' : ''}
                </td>
                <td className='px-6 py-4'>{intendedAudience}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Story;
