import { BaseRecord, GetOneResponse } from '@refinedev/core';
import React from 'react';
import { IDataSources } from 'types/datasourceinterface';
import { IKPI } from 'types/kpi.types';
import { ISecurity } from 'types/securityTypes';
import { IUserStory } from 'types/userStory.types';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const PDFDownloadButton = ({
  projects,
}: {
  projects: GetOneResponse<BaseRecord> | undefined;
}) => {
  const downloadPDF = () => {
    const doc = new jsPDF('landscape', 'mm', 'a4');

    const kpiTable = projects?.data.kpis.map((data: IKPI) => {
      return [
        data.metric,
        data.metricDefinition,
        data.metricCalculation,
        data.reportedFrequency,
        data.comparator.join('\n'),
        data.dataSource
          .map((data: { dataSourceName: string[] }) => data.dataSourceName)
          .join('\n'),
        data.table.join('\n'),
        data.dimensionality.join('\n'),
      ];
    });

    const userStoryTable = projects?.data.userStories.map(
      (data: IUserStory) => {
        return [data.story, data.storyDetails, data.intendedAudience];
      }
    );

    const dataSourceTable = projects?.data.dataSources.map(
      (data: IDataSources) => {
        return [
          data.dataSourceName,
          data.dataSourceType,
          data.dataSourceSubType,
          data.estimateVolumeInGB,
        ];
      }
    );

    const securityTable = projects?.data.securities.map((data: ISecurity) => {
      return [data.dataSensitiveItem, data.details, data.compliance];
    });

    if (projects?.data.kpis.length > 0) {
      autoTable(doc, {
        head: [
          [
            'Metric',
            'Metric Definition',
            'Metric Calculation',
            'Reported Frequency',
            'Comparator',
            'Data Source',
            'Table',
            'Dimentionality',
          ],
        ],
        body: kpiTable,
      });
    }

    if (projects?.data.userStories.length > 0) {
      autoTable(doc, {
        head: [['Story', 'Story Details', 'Intended Audience']],
        body: userStoryTable,
      });
    }

    if (projects?.data.dataSources.length > 0) {
      autoTable(doc, {
        head: [
          [
            'Data Source Name',
            'Data Source Type',
            'Data Source Sub Type',
            'Estimate Volume in GB',
          ],
        ],
        body: dataSourceTable,
      });
    }

    if (projects?.data.securities.length > 0) {
      autoTable(doc, {
        head: [['Data Sensitive Item', 'Details', 'Compliance']],

        body: securityTable,
      });
    }
    projects?.data.branding.forEach((element: { fileURL: string }) => {
      doc.html(`
          <a href="${element.fileURL}" download="table.pdf">
            Click here to download the PDF
          </a>
        `);
    });

    doc.save('Future_Analytics_Requirements.pdf');
  };
  return (
    <button
      onClick={downloadPDF}
      className='text-white p-3 bg-blue-400 rounded-lg'
    >
      Download Requirements
    </button>
  );
};

export default PDFDownloadButton;
