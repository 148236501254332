import {
  LoginFormTypes,
  useActiveAuthProvider,
  useLogin,
} from '@refinedev/core';
// import { LoginSVG } from 'assets/LoginSVG';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';
import { BsMicrosoft } from 'react-icons/bs';
import { notifyError } from '../../components/shared/Toastify';
const initialValue = {
  email: '',
  password: '',
};

export const Login = () => {
  const authProvider = useActiveAuthProvider();
  const { mutate: login } = useLogin<LoginFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });

  const [values, setValues] = useState(initialValue);

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setValues((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    login(
      { email: values.email, password: values.password },
      {
        onSuccess(data) {
          if (data.error) {
            notifyError(data.error.message);
          }
        },
      }
    );
    e.preventDefault();
  };
  return (
    <>
      <div className='h-screen w-screen bg-white '>
        <div className='flex flex-col items-center '>
          <form
            className='w-[90%] flex justify-center flex-col gap-2 p-8 bg-white rounded-xl drop-shadow-7xl mb-4  mt-16 md:mt-16   md:w-[25rem] '
            onSubmit={handleSubmit}>
            <h3 className='text-4xl text-primary-green text-center mb-8 font-semibold'>
              Login
            </h3>

            <label htmlFor='username'>Email</label>
            <input
              className='outline-none h-5 border-b-[2px] border-slate500 border-opacity-20 mb-6 '
              type='email'
              name='email'
              id='username'
              required
              value={values.email}
              onChange={handleChange}
            />
            <label htmlFor='pass'>Password</label>
            <input
              className='outline-none h-5 border-b-[2px] border-slate500 border-opacity-20 mb-6 '
              type='password'
              name='password'
              id='pass'
              required
              value={values.password}
              onChange={handleChange}
            />
            <div className='text-center mb-3'>
              <Link to='/forgotPassword' className='text-links'>
                Forgot Password?
              </Link>
            </div>
            <input
              type='submit'
              value='Login'
              className='bg-slate-500 w-40 rounded-full mx-auto p-4 text-white cursor-pointer font-semibold'
              // onClick={handleSubmit}
            />

            <p className='font-semibold text-center my-2 text-xl'>or</p>
            <div className='flex flex-col gap-4 justify-center'>
              <button
                onClick={() => login({ providerName: 'google' })}
                type='button'
                className='flex gap-4 items-center cursor-pointer p-4 border border-slate400 rounded-md'>
                <FcGoogle className='text-4xl' />
                Continue with Google
              </button>
              {/* <button
                onClick={() => login({ providerName: 'microsoft' })}
                type='button'
                className='flex gap-4 items-center cursor-pointer p-4 border border-slate400 rounded-md'>
                <BsMicrosoft className='text-3xl' />
                <p>Continue with Microsoft</p>
              </button> */}
            </div>

            <p className='text-center mt-3'>
              Not a member yet?{' '}
              <Link to='/register' className='text-blue-500'>
                Register
              </Link>
            </p>
          </form>
        </div>
      </div>
    </>
  );
};
