import { AuthBindings } from '@refinedev/core';
import { CheckResponse } from '@refinedev/core/dist/interfaces';
import { notify } from 'components/shared/Toastify';
import { initFirebase } from 'config/firebase';
import {
  OAuthProvider,
  User,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
} from 'firebase/auth';
import { GoogleAuthProvider } from 'firebase/auth';
import { axiosInstance } from 'rest-data-provider/utils';

const googleProvider = new GoogleAuthProvider();

const mircosoftProvider = new OAuthProvider('microsoft.com');

export const TOKEN_KEY = 'refine-auth';

export const authProvider: AuthBindings = {
  login: async ({ username, email, password, providerName }) => {
    initFirebase();
    const auth = getAuth();
    try {
      if (email || password) {
        const userdata = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );

        const user = userdata.user;
        if (user.emailVerified) {
          return {
            success: true,
            redirectTo: '/dashboard',
          };
        } else {
          // signOut(auth);
          sendEmailVerification(auth.currentUser!).then(() => {});

          return {
            success: false,

            error: {
              name: 'LoginError',
              message: 'Please verify your email',
            },
          };
        }
      }
    } catch (error) {
      return {
        success: false,
        error: {
          name: 'FirebaseError',
          message: 'Invalid email or password',
        },
      };
    }

    if (providerName === 'google') {
      try {
        const result = await signInWithPopup(auth, googleProvider);
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;
        // The signed-in user info.
        const user = result.user;
        if (user) {
          axiosInstance.post(
            `${process.env.REACT_APP_SERVER_API_URL}/user/saveuser`
          );

          return {
            success: true,
            redirectTo: '/dashboard',
          };
        }
      } catch (error) {
        return {
          success: false,
          error: {
            name: 'LoginError',
            message: 'Google login failed',
          },
        };
      }
    } else if (providerName === 'microsoft') {
      try {
        const result = await signInWithPopup(auth, mircosoftProvider);
        const credential = OAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;

        if (token) {
          return {
            success: true,
            redirectTo: '/dashboard',
          };
        }
      } catch (error) {
        console.log(error);
        return {
          success: false,
          error: {
            name: 'LoginError',
            message: 'Microsoft login failed',
          },
        };
      }
    }

    return {
      success: false,
      error: {
        name: 'LoginError',
        message: 'Something went wrong please try again',
      },
    };
  },

  register: async ({ fullName, email, password }) => {
    initFirebase();
    const auth = getAuth();
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    const user = userCredential.user;
    updateProfile(userCredential.user, {
      displayName: fullName,
      photoURL:
        'https://res.cloudinary.com/dyvisacbu/image/upload/v1658867832/User%20Image/tmp-1-1658867831169_qtxlkx.png',
    });
    await axiosInstance.post(
      `${process.env.REACT_APP_SERVER_API_URL}/user/signup`,
      {
        uid: user.uid,
        email,
        fullName,
      }
    );
    if (user && user.emailVerified) {
      return {
        success: true,
      };
    } else {
      sendEmailVerification(auth.currentUser!).then(() => {
        notify('Verification email sent. Please verify your email');
      });
      return {
        success: false,
        error: new Error('Something went wrong'),
      };
    }
  },

  logout: async () => {
    localStorage.removeItem('user-storage');

    initFirebase();
    const auth = getAuth();

    signOut(auth);
    return {
      success: true,
      redirectTo: '/login',
    };
  },
  check: async () => {
    initFirebase();
    const auth = getAuth();

    const authStatePromise: Promise<CheckResponse> = new Promise(
      (resolve, reject) => {
        onAuthStateChanged(auth, (user) => {
          if (user !== null) {
            resolve({
              authenticated: true,
            });
          } else {
            resolve({
              authenticated: false,
              redirectTo: '/login',
            });
          }
        });
      }
    );

    const authState = await authStatePromise;

    if (authState.authenticated === true && auth.currentUser?.emailVerified) {
      return {
        authenticated: true,
      };
    } else {
      signOut(auth);

      return {
        authenticated: false,
        redirectTo: '/login',
      };
    }
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    // const token = localStorage.getItem(TOKEN_KEY);
    // if (token) {
    //   return {
    //     id: 1,
    //     name: 'John Doe',
    //     avatar: 'https://i.pravatar.cc/300',
    //   };
    // }

    initFirebase();
    const auth = getAuth();

    const authStatePromise: Promise<User> = new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        if (user !== null) {
          resolve(user);
        } else {
          reject(user);
        }
      });
    });

    const authState: User = await authStatePromise;

    if (authState !== null) {
      return {
        id: authState.uid,
        email: authState.email,
        fullName: authState.displayName,
        photoURL: authState.photoURL,
      };
    }
    return null;
  },
  onError: async (error) => {
    console.error('erororoe', error);
    return { error };
  },
};
