import { IResourceComponentsProps, useDelete } from '@refinedev/core';
import { RiEdit2Fill } from 'react-icons/ri';
import { MdDeleteSweep } from 'react-icons/md';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { modalBackdrop, modalContent, modalWrapper } from 'utils/modal-framer';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { IDataSources } from 'types/datasourceinterface';
import EditForm from 'components/datasource/editForm';

import { GrClose } from 'react-icons/gr';
import { InlineWidget } from 'react-calendly';
import NoData from 'components/errorComponent/noData';
import { useDataSourceStore } from 'store/dataSource.store';
import AddDataSourceForm from 'components/datasource/addForm';

export const DataSource: React.FC<IResourceComponentsProps> = () => {
  const [projectId] = useSearchParams();

  // const addDataSourceItem = useDataSourceStore(
  //   (state) => state.addDataSourceItemID
  // );
  const dataSourceItem = useDataSourceStore((state) => state.dataSourceItem);
  const removeDataSourceItemId = useDataSourceStore(
    (state) => state.removeDataSourceItemID
  );
  const removeDataSourceItem = useDataSourceStore(
    (state) => state.removeDataSourceItem
  );

  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteItemID, setDeleteItemID] = useState<string>('');
  const [dataSourceItemID, setDataSourceItemID] = useState<IDataSources>();
  const [isAssistance, setIsAssistance] = useState<boolean>(false);

  const { mutate, isLoading } = useDelete();
  const navigate = useNavigate();

  // const handleCheckboxChange = (event: {
  //   target: { value: string; checked: boolean };
  // }) => {
  //   const value = event.target.value;
  //   const isChecked = event.target.checked;
  //   if (isChecked) {
  //     addDataSourceItem(value);
  //   } else {
  //     removeDataSourceItem(value);
  //   }
  // };

  return (
    <>
      <AnimatePresence>
        {openModal && (
          <>
            <motion.div
              variants={modalBackdrop}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed h-screen w-screen bg-[#000] bg-opacity-50 top-0 left-0 z-50'
              onClick={() => setOpenModal(false)}
            />

            <motion.div
              variants={modalWrapper}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed w-fit  h-[60vh] overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
              <motion.div
                variants={modalContent}
                initial='hidden'
                animate='vissible'
                exit='exit'>
                <AddDataSourceForm setOpenModal={setOpenModal} />
              </motion.div>
            </motion.div>
          </>
        )}

        {/* edit user modal */}
        {openEditModal ? (
          <>
            <motion.div
              variants={modalBackdrop}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed h-screen w-screen bg-[#000] bg-opacity-50 top-0 left-0 z-50'
              onClick={() => setOpenEditModal(false)}
            />

            <motion.div
              variants={modalWrapper}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed w-fit  h-[60vh] overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
              <motion.div
                variants={modalContent}
                initial='hidden'
                animate='vissible'
                exit='exit'>
                <EditForm
                  dataSourceItemID={dataSourceItemID}
                  setOpenEditModal={setOpenEditModal}
                />
              </motion.div>
            </motion.div>
          </>
        ) : null}
        {/* end edit user modal */}

        {/* delete modal */}
        {deleteModal ? (
          <>
            <motion.div
              variants={modalBackdrop}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed h-screen w-screen bg-[#000] bg-opacity-50 top-0 left-0 z-50'
              onClick={() => setDeleteModal(false)}
            />

            <motion.div
              variants={modalWrapper}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed w-fit  h-fit overflow-y-auto scrollbar-hide mx-auto top-32 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
              <motion.div
                variants={modalContent}
                initial='hidden'
                animate='vissible'
                exit='exit'>
                <div>
                  <p className='text-xl text-center font-semibold mb-12'>
                    Are you sure you want to
                    <br /> delete?
                  </p>
                  <div className='flex gap-4 justify-center'>
                    <button
                      onClick={() => setDeleteModal(false)}
                      className='w-32 bg-white border border-slate-200 p-3 rounded-xl'>
                      Cancel
                    </button>
                    <button
                      disabled={isLoading}
                      onClick={() => {
                        mutate(
                          { resource: 'datasource', id: deleteItemID },
                          {
                            onSuccess: (data) => {
                              if (data.data) {
                                removeDataSourceItem(data.data._id);
                                removeDataSourceItemId(data.data._id);
                                setDeleteModal(false);
                              }
                            },
                          }
                        );
                      }}
                      className='w-32 bg-pink-500 text-white p-3 rounded-xl'>
                      {isLoading ? 'Deleting...' : 'Delete'}
                    </button>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          </>
        ) : null}
        {/* end delete modal */}

        {isAssistance ? (
          <>
            <motion.div
              variants={modalBackdrop}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed h-screen w-screen bg-[#000] bg-opacity-50 top-0 left-0 z-50'
              onClick={() => setIsAssistance(false)}
            />

            <motion.div
              variants={modalWrapper}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed w-[30vw]  h-fit overflow-y-auto scrollbar-hide mx-auto top-4 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
              <motion.div
                variants={modalContent}
                initial='hidden'
                animate='vissible'
                exit='exit'>
                <div>
                  <div className='flex justify-end mb-4'>
                    <GrClose
                      className='cursor-pointer font-bold text-2xl'
                      onClick={() => setIsAssistance(false)}
                    />
                  </div>
                  <InlineWidget url='https://calendly.com/alexander-186/experiment1et' />
                </div>
              </motion.div>
            </motion.div>
          </>
        ) : null}
      </AnimatePresence>
      <div className='relative tables p-4 mt-4 mb-8 w-full h-[90vh] border-2 border-white scroll-auto'>
        <div className='flex justify-between mb-4'>
          <p className='font-semibold text-xl'>Data Source</p>
          <div className='flex space-x-8 items-center'>
            <button
              onClick={() => setIsAssistance(true)}
              className='text-blue-400 underline'>
              Need assistance?
            </button>
            <button
              className='p-2 bg-blue-400 text-white rounded-lg capitalize'
              onClick={() => setOpenModal(true)}>
              Add Data Source
            </button>
          </div>
        </div>

        <div className='relative overflow-x-auto shadow-md rounded-lg'>
          <table className='w-full text-sm text-left text-gray-500 '>
            <thead className='text-xs uppercase bg-white'>
              <tr>
                <th scope='col' className='px-6 py-6'>
                  Data Source Name
                </th>
                <th scope='col' className='px-6 py-6'>
                  Data Source Type
                </th>
                <th scope='col' className='px-6 py-6'>
                  Data Source Sub Type
                </th>

                <th scope='col' className='px-6 py-6'>
                  Estimate Volume in GB
                </th>
                <th scope='col' className='px-6 py-6'>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {dataSourceItem.map((item, index: number) => {
                const {
                  _id,
                  dataSourceName,
                  dataSourceSubType,
                  dataSourceType,
                  estimateVolumeInGB,
                } = item;
                return (
                  <tr
                    key={index}
                    className='bg-none border-b-2 border-white hover:bg-white '>
                    <td className='px-6 py-4'>{dataSourceName}</td>
                    <td className='px-6 py-4'>{dataSourceType}</td>
                    <td className='px-6 py-4'>{dataSourceSubType}</td>
                    <td className='px-6 py-4'>{estimateVolumeInGB}</td>
                    <td className='text-right flex gap-8 px-6 py-4'>
                      <button
                        onClick={() => {
                          setDataSourceItemID(item);
                          setOpenEditModal(true);
                        }}>
                        <RiEdit2Fill className='text-2xl text-green-400' />
                      </button>
                      <button
                        onClick={() => {
                          setDeleteItemID(_id);
                          setDeleteModal(true);
                        }}>
                        <MdDeleteSweep className='text-2xl text-red-500' />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {dataSourceItem.length < 1 ? <NoData /> : null}
        </div>
        <div className='absolute mx-auto left-0 right-0 bottom-12 text-center'>
          <div className=''>
            <button
              onClick={() => navigate('/user-story')}
              className='bg-blue-400 p-4 text-white rounded-xl w-28 mr-8'>
              Go back
            </button>
            <button
              onClick={() => navigate(`/kpi?id=${projectId.get('id')}`)}
              className='bg-blue-400 p-4 text-white rounded-xl w-28'>
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
