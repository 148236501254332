import { IKPI } from 'types/kpi.types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface IProjectNames {
  name: string;
  id: string;
  isChecked: boolean;
}

interface IKPIStore {
  kpiItemID: string[];
  kpiItem: IKPI[];
  kpiTotal: IKPI[];
  kpiTotalFiltered: IKPI[];
  projectNames: IProjectNames[];
  addKPIItemID: (id: string) => void;
  addKPIItem: (item: IKPI) => void;
  addKPITotal: (item: IKPI) => void;
  addProjectNames: (item: IProjectNames) => void;
  updateProjectNames: (name: string) => void;
  updateKPIItem: (item: IKPI) => void;
  updateKPITotal: (item: IKPI) => void;
  removeKPIItemID: (id: string) => void;
  removeKPIItem: (id: string) => void;
  removeKPIItemTotal: (id: string) => void;
  bulkRemoveKPIItemTotal: (id: string) => void;
  removeProjectName: (id: string) => void;
  filterKPITotal: (id: string) => void;
  clearKPIItemID: () => void;
  clearKPIItem: () => void;
}

export const useKPIStore = create<IKPIStore>()(
  persist(
    (set, get) => ({
      kpiItemID: [],
      kpiItem: [],
      kpiTotal: [],
      kpiTotalFiltered: [],
      projectNames: [],
      addKPIItemID: (id: string) => {
        set((state) => ({ kpiItemID: [...state.kpiItemID, id] }));
      },
      addKPIItem: (item: IKPI) => {
        set((state) => ({ kpiItem: [...state.kpiItem, item] }));
      },
      addKPITotal: (item: IKPI) => {
        set((state) => ({
          kpiTotal: [...state.kpiTotal, item],
          kpiTotalFiltered: [...state.kpiTotal, item],
        }));
      },
      addProjectNames: (item: IProjectNames) => {
        set((state) => ({
          projectNames: [...state.projectNames, { ...item }],
        }));
      },
      updateProjectNames: (id: string) => {
        set((state) => ({
          projectNames: state.projectNames.map((item) => {
            if (item.id === id) {
              return { ...item, isChecked: !item.isChecked };
            }
            return item;
          }),
        }));
      },
      updateKPIItem: (updateItem: IKPI) => {
        set((state) => ({
          kpiItem: state.kpiItem.map((item) =>
            item._id === updateItem._id ? updateItem : item
          ),
        }));
      },
      updateKPITotal: (updateTotal: IKPI) => {
        set((state) => ({
          kpiTotal: state.kpiTotal.map((item) =>
            item._id === updateTotal._id ? updateTotal : item
          ),
        }));
      },

      removeKPIItemID: (id: string) => {
        set((state) => ({
          kpiItemID: state.kpiItemID.filter((item) => item !== id),
        }));
      },
      removeKPIItem: (id: string) => {
        set((state) => ({
          kpiItem: state.kpiItem.filter((item) => item._id !== id),
        }));
      },
      removeKPIItemTotal: (id: string) => {
        set((state) => ({
          kpiTotal: state.kpiTotal.filter((item) => item._id !== id),
        }));
      },
      bulkRemoveKPIItemTotal: (id: string) => {
        set((state) => ({
          kpiTotal: state.kpiTotal.filter((item) => item.project[0] !== id),
          kpiTotalFiltered: state.kpiTotal.filter(
            (item) => item.project[0] !== id
          ),
        }));
      },
      removeProjectName: (id: string) => {
        const projectNames = get().projectNames.filter(
          (item) => item.id !== id
        ) as IProjectNames[];
        set((state) => ({
          projectNames,
        }));
      },
      filterKPITotal: (id: string) => {
        set((state) => ({
          kpiTotalFiltered: state.kpiTotal.filter(
            (item) => item.project[0] !== id
          ),
        }));
      },

      clearKPIItemID: () => {
        set((state) => ({ kpiItemID: [] }));
      },
      clearKPIItem: () => {
        set((state) => ({ kpiItem: [] }));
      },
    }),

    {
      name: 'kpi-storage',
    }
  )
);
