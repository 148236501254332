import { useDelete, useOne, useResource } from '@refinedev/core';
import Modal from 'components/shared/Modal';
import { notify } from 'components/shared/Toastify';
import { KPIHistoryEditForms } from 'components/kpi/historyEditForms';

import moment from 'moment';
import { Fragment, useState } from 'react';
import { MdDeleteSweep } from 'react-icons/md';
import { RiEdit2Fill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

import { IKPIHistory } from 'types/kpi.types';

const ShowHistory = () => {
  const navigate = useNavigate();
  const { id } = useResource();

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteItemID, setDeleteItemID] = useState<string>('');
  const [kpiItem, setKpiItem] = useState<IKPIHistory | undefined>();

  const { data } = useOne<IKPIHistory[]>({ resource: 'kpi', id });
  const { mutate, isLoading } = useDelete();

  return (
    <Fragment>
      <Modal
        isOpen={openEditModal}
        close={setOpenEditModal}
        className='fixed w-fit  h-[60vh] overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
        <KPIHistoryEditForms
          kpiItem={kpiItem}
          setOpenEditModal={setOpenEditModal}
        />
      </Modal>

      <Modal
        isOpen={deleteModal}
        close={setDeleteModal}
        className='fixed w-fit  h-fit overflow-y-auto scrollbar-hide mx-auto top-32 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
        <div>
          <p className='text-xl text-center font-semibold mb-12'>
            Are you sure you want to
            <br /> delete?
          </p>
          <div className='flex gap-4 justify-center'>
            <button
              onClick={() => setDeleteModal(false)}
              className='w-32 bg-white border border-slate-200 p-3 rounded-xl'>
              Cancel
            </button>
            <button
              disabled={isLoading}
              onClick={() => {
                mutate(
                  {
                    resource: 'kpi/delete-kpi-history',
                    id: deleteItemID,
                    invalidates: ['all'],
                  },
                  {
                    onSuccess: (data) => {
                      if (data.data) {
                        setDeleteModal(false);
                        notify('Successfully Deleted');
                      }
                    },
                  }
                );
              }}
              className='w-32 bg-pink-500 text-white p-3 rounded-xl'>
              {isLoading ? 'Deleting...' : 'Delete'}
            </button>
          </div>
        </div>
      </Modal>
      <div className='relative table p-4 mt-4 mb-8 w-full h-[90vh] border-2 border-white rounded-xl '>
        <div className='mb-4'>
          <p className='font-semibold text-xl'>
            Key Performance Indicator History
          </p>
        </div>
        <div className='absolute overflow-x-auto shadow-md rounded-lg w-full md:w-[98%] h-fit'>
          <table className='w-full text-sm text-left text-gray-500 '>
            <thead className='text-xs uppercase bg-white'>
              <tr>
                <th scope='col' className='px-6 py-6'>
                  Metric ID
                </th>
                <th scope='col' className='px-6 py-6'>
                  Updated On
                </th>
                <th scope='col' className='px-6 py-6'>
                  Metric
                </th>
                <th scope='col' className='px-6 py-6'>
                  Metric Definition
                </th>
                <th scope='col' className='px-6 py-6'>
                  Metric Calculation
                </th>
                <th scope='col' className='px-6 py-6'>
                  Comparator
                </th>
                <th scope='col' className='px-6 py-6'>
                  Data Source
                </th>
                <th scope='col' className='px-6 py-6'>
                  Table
                </th>
                <th scope='col' className='px-6 py-6'>
                  Dimensionality
                </th>
                <th scope='col' className='px-6 py-6'>
                  Actions
                </th>

                {/* </th>
        <th scope='col' className='px-6 py-6'>
          Actions
        </th> */}
              </tr>
            </thead>
            <tbody>
              {data?.data.map((item, index: number) => {
                const { _id, kpiId, updatedFields, updatedOn } = item;

                return (
                  <>
                    <tr
                      key={index}
                      className='bg-none border-b-2 border-white hover:bg-white '>
                      <td className='px-6 py-4'>{kpiId}</td>
                      <td className='px-6 py-4'>
                        {moment(updatedOn).format('DD-MM-YYYY')}
                      </td>
                      <td className='px-6 py-4'>{updatedFields.metric}</td>
                      <td className='px-6 py-4'>
                        {updatedFields.metricDefinition}
                      </td>
                      <td className='px-6 py-4'>
                        {updatedFields.metricCalculation}
                      </td>
                      <td className='px-6 py-4'>
                        {updatedFields.comparator.map((item, index) => {
                          return <div key={index}>{item}</div>;
                        })}
                      </td>

                      <td className='px-6 py-4'>
                        {updatedFields.dataSource.length > 0 ? (
                          updatedFields.dataSource.map((item, index) => {
                            return <p key={index}>{item.dataSourceName}</p>;
                          })
                        ) : (
                          <p>N/A</p>
                        )}
                      </td>
                      <td className='px-6 py-4'>
                        {updatedFields.table.map((item, index) => {
                          return <div key={index}>{item}</div>;
                        })}
                      </td>
                      <td className='px-6 py-4'>
                        {updatedFields.dimensionality.map((item, index) => {
                          return <div key={index}>{item}</div>;
                        })}
                      </td>
                      <td className='text-right flex gap-8 px-6 py-4'>
                        <button
                          onClick={() => {
                            setKpiItem(item);
                            setOpenEditModal(true);
                          }}>
                          <RiEdit2Fill className='text-2xl text-green-500' />
                        </button>
                        <button
                          onClick={() => {
                            setDeleteItemID(_id);
                            setDeleteModal(true);
                          }}>
                          <MdDeleteSweep className='text-2xl text-red-500' />
                        </button>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className='absolute mx-auto left-0 right-0 bottom-12 text-center'>
          <div className=''>
            <button
              onClick={() => navigate('/kpi')}
              className='bg-blue-400 p-4 text-white rounded-xl w-28 mr-8'>
              Go back
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ShowHistory;
