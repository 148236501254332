import { useUpdate } from '@refinedev/core';
import { BaseRecord } from '@refinedev/core/dist/interfaces';
import { notify, notifyError } from 'components/shared/Toastify';
import FormRow from 'HOC/FormRow';
import React from 'react';
import {
  IProjectObjectiveItem,
  useProjectObjectiveStore,
} from 'store/projectObjectives.store';

const EditProjectObejective = ({
  setOpenEditModal,
  projectObjectiveItemID,
}: {
  projectObjectiveItemID: BaseRecord | undefined;
  setOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const updateProjectObjectiveItem = useProjectObjectiveStore(
    (state) => state.updateProjectObjectiveItem
  );

  const { mutate, isLoading } = useUpdate<IProjectObjectiveItem>();

  const initialValues = {
    projectObjectiveName: projectObjectiveItemID?.projectObjectiveName,
    projectObjectiveDescription:
      projectObjectiveItemID?.projectObjectiveDescription,
    projectObjectivePriority: projectObjectiveItemID?.projectObjectivePriority,
  };

  const [value, setValue] = React.useState(initialValues);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const payload = {
      projectObjectiveName: value.projectObjectiveName,
      projectObjectiveDescription: value.projectObjectiveDescription,
      projectObjectivePriority: value.projectObjectivePriority,
    };

    mutate(
      {
        resource: 'project-objective/update-project-objective',
        values: payload,
        id: projectObjectiveItemID?._id,
        invalidates: ['all'],
      },
      {
        onSuccess: (data) => {
          if (data.data) {
            updateProjectObjectiveItem(data.data);
            notify('Project Objective updated successfully');
            setOpenEditModal(false);
          }
        },
        onError: (error) => {
          notifyError(error.response.data.errorMsg);
        },
      }
    );
  };

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setValue((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className='w-[75vw] max-w-[100vw] md:w-[40rem] flex flex-col'>
        <FormRow
          name='projectObjectiveName'
          type='text'
          labelText='Project Objective Name'
          required={true}
          placeholder='Project Objective Name'
          value={value.projectObjectiveName}
          handleChange={handleChange}
        />
        <FormRow
          name='projectObjectiveDescription'
          type='text'
          labelText='Project Objective Description'
          required={true}
          placeholder='Project Objective Description'
          value={value.projectObjectiveDescription}
          handleChange={handleChange}
        />

        <label htmlFor='projectObjectivePriority'>
          Project Objective Priority
        </label>
        <select
          onChange={handleChange}
          value={value.projectObjectivePriority}
          name='projectObjectivePriority'
          id='projectObjectivePriority'
          className='outline-none border-[1px] border-slate-400  p-2 mb-8 rounded-xl mt-4'>
          <option value='High'>High</option>
          <option value='Medium'>Medium</option>
          <option value='Low'>Low</option>projectObjectivePriority
        </select>
        {/* 
        <FormRow
          name="projectObjectivePriority"
          type="text"
          labelText="Project Objective Priority"
          required={true}
          placeholder="Project Objective Priority"
          value={value.projectObjectivePriority}
          handleChange={handleChange}
        /> */}

        <div className='grid place-items-center'>
          <button
            disabled={isLoading}
            type='submit'
            className={
              isLoading
                ? 'cursor-not-allowed w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
                : 'w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
            }>
            {isLoading ? 'Updating...' : 'Update'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProjectObejective;
