import { BaseRecord, useGetIdentity, useUpdate } from '@refinedev/core';
import { notify, notifyError } from 'components/shared/Toastify';
import FormRow from 'HOC/FormRow';
import React, { Dispatch, SetStateAction } from 'react';
import { IStoryItem, useStoryStore } from 'store/userStory.store';
import { IIdentity } from 'types/identitytypes';

export const EditUserStoryForms = ({
  setOpenEditModal,
  userStoryItemID,
}: {
  setOpenEditModal: Dispatch<SetStateAction<boolean>>;
  userStoryItemID: BaseRecord | undefined;
}) => {
  const updateStoryItem = useStoryStore((state) => state.updateStoryItem);
  const initialValues = {
    id: userStoryItemID?._id,
    story: userStoryItemID?.story,
    storyDetails: userStoryItemID?.storyDetails,
    priority: userStoryItemID?.priority,
    intendedAudience: userStoryItemID?.intendedAudience,
  };

  const { mutate, isLoading } = useUpdate<IStoryItem>();
  const [value, setValue] = React.useState(initialValues);

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setValue((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const payload = {
      story: value.story,
      storyDetails: value.storyDetails,
      intendedAudience: value.intendedAudience,
      priority: value.priority,
    };
    const id = value.id as string;

    mutate(
      { resource: 'user-story', values: payload, id: id },
      {
        onSuccess: (data) => {
          if (data.data) {
            updateStoryItem(data.data);
            notify('Story updated successfully');
            setOpenEditModal(false);
          }
        },
        onError(error) {
          notifyError(error.response.data.errorMsg);
        },
      }
    );
  };

  return (
    <>
      <div className=''>
        <form
          onSubmit={handleSubmit}
          className='w-[75vw] max-w-[100vw] md:w-[40rem] flex flex-col'>
          <FormRow
            labelText='User Story'
            name='story'
            type='text'
            placeholder='E.g. Sales Pipeline'
            value={value.story}
            handleChange={handleChange}
          />
          <FormRow
            labelText='Story Details'
            textArea='textArea'
            name='storyDetails'
            placeholder='E.g. I want to get the details of the sales pipeline'
            value={value.storyDetails}
            handleChange={handleChange}
          />

          <FormRow
            labelText='Intended Audience'
            name='intendedAudience'
            type='text'
            placeholder='E.g. Regional Director'
            value={value.intendedAudience}
            handleChange={handleChange}
          />

          <label htmlFor='userPriority'>Priority</label>
          <select
            onChange={handleChange}
            value={value.priority}
            name='priority'
            id='userPriority'
            className='outline-none border-[1px] border-slate-400  p-2 mb-8 rounded-xl mt-4'>
            <option value='' disabled>
              Select Priority
            </option>
            <option value='High'>High</option>
            <option value='Medium'>Medium</option>
            <option value='Low'>Low</option>
          </select>

          <div className='grid place-items-center'>
            <button
              type='submit'
              className={
                isLoading
                  ? 'cursor-not-allowed w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
                  : 'w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
              }>
              {isLoading ? 'Updating...' : 'Update'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
