import { useDelete, useList } from '@refinedev/core';
import NoDataIcon from 'assets/SVG/nodata';
import NoData from 'components/errorComponent/noData';
import AddForm from 'components/security/addForm';
import EditForm from 'components/security/editForm';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { MdDeleteSweep } from 'react-icons/md';
import { RiEdit2Fill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { ISecurity } from 'types/securityTypes';
import { modalBackdrop, modalContent, modalWrapper } from 'utils/modal-framer';

import { InlineWidget } from 'react-calendly';
import { GrClose } from 'react-icons/gr';
import { useSecurityStore } from 'store/securityDetail.store';

const SecurityPage = () => {
  const addItemID = useSecurityStore((state) => state.addSecurityItemID);
  const removeItemID = useSecurityStore((state) => state.removeSecurityItemID);
  const removeItem = useSecurityStore((state) => state.removeSecurityItem);
  const securityItem = useSecurityStore((state) => state.securityItem);

  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteItemID, setDeleteItemID] = useState<string>('');
  const [securityItemID, setSecurityItemID] = useState<ISecurity | undefined>();
  const [isAssistance, setIsAssistance] = useState<boolean>(false);
  // const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const { data: securityData } = useList<ISecurity>({
    resource: 'security/get-security',
  });
  const { mutate, isLoading } = useDelete();

  const handleCheckboxChange = (event: {
    target: { value: string; checked: boolean };
  }) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      addItemID(value);
      // setSelectedValues([...selectedValues, value]);
    } else {
      removeItemID(value);
      // setSelectedValues(selectedValues.filter((item) => item !== value));
    }
  };

  return (
    <>
      <AnimatePresence>
        {openModal ? (
          <>
            <motion.div
              variants={modalBackdrop}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed h-screen w-screen bg-[#000] bg-opacity-50 top-0 left-0 z-50'
              onClick={() => setOpenModal(false)}
            />

            <motion.div
              variants={modalWrapper}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed w-fit  h-fit overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
              <motion.div
                variants={modalContent}
                initial='hidden'
                animate='vissible'
                exit='exit'>
                <AddForm setOpenModal={setOpenModal} />
              </motion.div>
            </motion.div>
          </>
        ) : null}

        {/* edit form */}
        {openEditModal ? (
          <>
            <motion.div
              variants={modalBackdrop}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed h-screen w-screen bg-[#000] bg-opacity-50 top-0 left-0 z-50'
              onClick={() => setOpenEditModal(false)}
            />

            <motion.div
              variants={modalWrapper}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed w-fit  h-fit overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
              <motion.div
                variants={modalContent}
                initial='hidden'
                animate='vissible'
                exit='exit'>
                <EditForm
                  securityItemID={securityItemID}
                  setOpenEditModal={setOpenEditModal}
                />
              </motion.div>
            </motion.div>
          </>
        ) : null}
        {/* edit form */}

        {/* delete modal */}
        {deleteModal ? (
          <>
            <motion.div
              variants={modalBackdrop}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed h-screen w-screen bg-[#000] bg-opacity-50 top-0 left-0 z-50'
              onClick={() => setDeleteModal(false)}
            />

            <motion.div
              variants={modalWrapper}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed w-fit  h-fit overflow-y-auto scrollbar-hide mx-auto top-32 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
              <motion.div
                variants={modalContent}
                initial='hidden'
                animate='vissible'
                exit='exit'>
                <div>
                  <p className='text-xl text-center font-semibold mb-12'>
                    Are you sure you want to
                    <br /> delete?
                  </p>
                  <div className='flex gap-4 justify-center'>
                    <button
                      onClick={() => setDeleteModal(false)}
                      className='w-32 bg-white border border-slate-200 p-3 rounded-xl'>
                      Cancel
                    </button>
                    <button
                      disabled={isLoading}
                      onClick={() => {
                        mutate(
                          {
                            resource: 'security/delete-security',
                            id: deleteItemID,
                            invalidates: ['all'],
                          },
                          {
                            onSuccess: (data) => {
                              if (data.data) {
                                removeItemID(data.data._id);
                                removeItem(data.data._id);
                                setDeleteModal(false);
                              }
                            },
                          }
                        );
                      }}
                      className='w-32 bg-pink-500 text-white p-3 rounded-xl'>
                      {isLoading ? 'Deleting...' : 'Delete'}
                    </button>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          </>
        ) : null}

        {isAssistance ? (
          <>
            <motion.div
              variants={modalBackdrop}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed h-screen w-screen bg-[#000] bg-opacity-50 top-0 left-0 z-50'
              onClick={() => setIsAssistance(false)}
            />

            <motion.div
              variants={modalWrapper}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed w-[30vw]  h-fit overflow-y-auto scrollbar-hide mx-auto top-4 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
              <motion.div
                variants={modalContent}
                initial='hidden'
                animate='vissible'
                exit='exit'>
                <div>
                  <div className='flex justify-end mb-4'>
                    <GrClose
                      className='cursor-pointer font-bold text-2xl'
                      onClick={() => setIsAssistance(false)}
                    />
                  </div>
                  <InlineWidget url='https://calendly.com/alexander-186/experiment1et' />
                </div>
              </motion.div>
            </motion.div>
          </>
        ) : null}
        {/* end delete modal */}
      </AnimatePresence>
      <div className='relative tables p-4 mt-4 mb-8 w-full h-[90vh] border-2 border-white scroll-auto'>
        <div className='flex justify-between mb-4'>
          <p className='font-semibold text-xl'>Security Details</p>
          <div className='flex space-x-8 items-center'>
            <button
              onClick={() => setIsAssistance(true)}
              className='text-blue-400 underline'>
              Need assistance?
            </button>
            <button
              className='p-2 bg-blue-400 text-white rounded-lg capitalize'
              onClick={() => setOpenModal(true)}>
              Add Security
            </button>
          </div>
        </div>

        <div className='relative overflow-x-auto shadow-md rounded-lg'>
          <table className='w-full text-sm text-left text-gray-500 '>
            <thead className='text-xs uppercase bg-white'>
              <tr>
                <th scope='col' className='px-6 py-6'>
                  Data Sensitive Item
                </th>
                <th scope='col' className='px-6 py-6'>
                  Details
                </th>
                <th scope='col' className='px-6 py-6'>
                  Compliance
                </th>

                <th scope='col' className='px-6 py-6'>
                  Actions
                </th>
              </tr>
            </thead>

            <tbody>
              {securityItem.map((item, index: number) => {
                const {
                  _id: id,
                  compliance,
                  dataSensitiveItem,
                  details,
                } = item;

                return (
                  <tr
                    key={index}
                    className='bg-none border-b-2 border-white hover:bg-white '>
                    <td className='px-6 py-4'>{dataSensitiveItem}</td>
                    <td className='px-6 py-4'>{details}</td>
                    <td className='px-6 py-4'>{compliance}</td>

                    <td className='text-right flex gap-8 px-6 py-4'>
                      <button
                        onClick={() => {
                          setSecurityItemID(item);
                          setOpenEditModal(true);
                        }}>
                        <RiEdit2Fill className='text-2xl text-green-400' />
                      </button>
                      <button
                        onClick={() => {
                          setDeleteItemID(id);
                          setDeleteModal(true);
                        }}>
                        <MdDeleteSweep className='text-2xl text-red-500' />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {securityItem.length < 1 ? <NoData /> : null}
          {/* <NoData /> */}
        </div>

        <div className='absolute mx-auto left-0 right-0 bottom-12 text-center'>
          <div className=''>
            <button
              onClick={() => navigate('/branding')}
              className='bg-blue-400 p-4 text-white rounded-xl w-28 mr-8'>
              Go back
            </button>

            <button
              onClick={() => navigate('/confirmation')}
              className='bg-blue-400 p-4 text-white rounded-xl w-28'>
              {securityItem.length < 1 ? 'Skip?' : 'Next'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecurityPage;
