import { IBranding } from 'types/branding.types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface IBrandingStore {
  Branding: IBranding[];

  addBranding: (item: IBranding) => void;
  updateBranding: (updateItem: IBranding) => void;
  removeBranding: (id: string) => void;
  clearBranding: () => void;
}

export const useBrandingStore = create<IBrandingStore>()(
  persist(
    (set, get) => ({
      Branding: [],

      addBranding: (item: IBranding) => {
        set((state) => ({
          Branding: [...state.Branding, item],
        }));
      },
      updateBranding: (updateItem: IBranding) => {
        set((state) => ({
          Branding: state.Branding.map((item) =>
            item._id === updateItem._id ? updateItem : item
          ),
        }));
      },

      removeBranding: (id: string) => {
        set((state) => ({
          Branding: state.Branding.filter((item) => item._id !== id),
        }));
      },

      clearBranding: () => {
        set((state) => ({ Branding: [] }));
      },
    }),
    {
      name: 'Branding-storage',
    }
  )
);
