import NoData from 'components/errorComponent/noData';
import moment from 'moment';
import React, { FC } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { MdDeleteSweep } from 'react-icons/md';
import { RiEdit2Fill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { useKPIStore } from 'store/kpi.store';
import { IKPI } from 'types/kpi.types';

type KPITableProps = {
  setKpiItemID: React.Dispatch<React.SetStateAction<IKPI | undefined>>;
  setDeleteItemID: React.Dispatch<React.SetStateAction<string>>;
  setOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const KPITable: FC<KPITableProps> = ({
  setKpiItemID,
  setDeleteItemID,
  setOpenEditModal,
  setDeleteModal,
}) => {
  const kpiItems = useKPIStore((state) => state.kpiItem);

  return (
    <>
      <table className='w-full text-sm text-left text-gray-500 '>
        <thead className='text-xs uppercase bg-white'>
          <tr>
            <th scope='col' className='px-6 py-6'>
              Metric ID
            </th>
            <th scope='col' className='px-6 py-6'>
              Metric
            </th>
            <th scope='col' className='px-6 py-6'>
              Metric Definition
            </th>
            <th scope='col' className='px-6 py-6'>
              Metric Calculation
            </th>
            <th scope='col' className='px-6 py-6'>
              Reported Frequency
            </th>
            <th scope='col' className='px-6 py-6'>
              Comparator
            </th>
            <th scope='col' className='px-6 py-6'>
              Data Source
            </th>
            <th scope='col' className='px-6 py-6'>
              Table
            </th>
            <th scope='col' className='px-6 py-6'>
              Dimensionality
            </th>
            <th scope='col' className='px-6 py-6'>
              Date Created
            </th>
            <th scope='col' className='px-6 py-6'>
              Date Modified
            </th>
            <th scope='col' className='px-6 py-6'>
              History
            </th>
            <th scope='col' className='px-6 py-6'>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {kpiItems.map((item, index: number) => {
            const {
              _id,
              reportedFrequency,
              comparator,
              dataSource,
              dimensionality,
              metric,
              metricCalculation,
              metricDefinition,
              table,
              createdAt,
              updatedAt,
            } = item;

            return (
              <tr
                key={index}
                className='bg-none border-b-2 border-white hover:bg-white '
              >
                <td className='px-6 py-4'>{_id}</td>
                <td className='px-6 py-4'>{metric}</td>
                <td className='px-6 py-4'>{metricDefinition}</td>
                <td className='px-6 py-4'>{metricCalculation}</td>
                <td className='px-6 py-4'>{reportedFrequency}</td>
                <td className='px-6 py-4'>
                  {comparator.map((item, index) => {
                    return <div key={index}>{item}</div>;
                  })}
                </td>

                <td className='px-6 py-4'>
                  {dataSource.length > 0 ? (
                    dataSource.map((item, index) => {
                      return <p key={index}>{item.dataSourceName}</p>;
                    })
                  ) : (
                    <p>N/A</p>
                  )}
                </td>
                <td className='px-6 py-4'>
                  {table.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </td>
                <td className='px-6 py-4'>
                  {dimensionality.map((item, index) => {
                    return <p key={index}>{item}</p>;
                  })}
                </td>
                <td className='px-6 py-4'>
                  {/* {item.createdAt} */}
                  {moment(createdAt).format('DD/MM/YYYY')}
                </td>
                <td className='px-6 py-4'>
                  {/* {item.updatedAt} */}
                  {moment(updatedAt).format('DD/MM/YYYY')}
                </td>
                <td className='px-6 py-4'>
                  <Link
                    to={`show/${_id}`}
                    className='p-2 rounded-xl flex space-x-2 items-center bg-blue-100'
                  >
                    <AiOutlineEye />
                    <span>History</span>
                  </Link>
                </td>

                <td className='text-right flex gap-8 px-6 py-4'>
                  <button
                    onClick={() => {
                      setKpiItemID(item);
                      setOpenEditModal(true);
                    }}
                  >
                    <RiEdit2Fill className='text-2xl text-green-400' />
                  </button>
                  <button
                    onClick={() => {
                      setDeleteItemID(_id);
                      setDeleteModal(true);
                    }}
                  >
                    <MdDeleteSweep className='text-2xl text-red-500' />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {kpiItems.length < 1 ? <NoData /> : null}
    </>
  );
};

export default KPITable;
