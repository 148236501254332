import React, { FC, ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { modalBackdrop, modalContent, modalWrapper } from 'utils/modal-framer';

interface IModal {
  isOpen?: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
  className?: string | undefined;
}

const Modal: FC<IModal> = ({ children, className, close, isOpen }) => {
  return (
    <AnimatePresence>
      {isOpen ? (
        <>
          <motion.div
            variants={modalBackdrop}
            initial='hidden'
            animate='vissible'
            exit='exit'
            className='fixed h-screen w-screen bg-[#000] bg-opacity-50 top-0 left-0 z-50'
            onClick={() => close(false)}
          />

          <motion.div
            variants={modalWrapper}
            initial='hidden'
            animate='vissible'
            exit='exit'
            className={
              className
                ? className
                : 'fixed w-fit  h-fit overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'
            }>
            <motion.div
              variants={modalContent}
              initial='hidden'
              animate='vissible'
              exit='exit'>
              {children}
            </motion.div>
          </motion.div>
        </>
      ) : null}
    </AnimatePresence>
  );
};

export default Modal;
