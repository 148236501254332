import { useMany } from '@refinedev/core';
import React from 'react';
import { useSecurityStore } from 'store/securityDetail.store';

const Security = () => {
  const itemID = useSecurityStore((state) => state.securityItemID);

  const { data } = useMany({
    resource: 'security/get-multiple-security',
    ids: itemID,
  });

  return (
    <div className='p-4 mb-8'>
      <div className=' uppercase bg-white p-4 rounded-xl font-bold '>
        Data Security
      </div>
      <table className='w-full text-sm text-left text-gray-500 '>
        <thead className='uppercase font-bold bg-none border-b-2 border-white'>
          <tr>
            <th scope='col' className='px-6 py-6'>
              Data Sensitive Item
            </th>
            <th scope='col' className='px-6 py-6'>
              Details
            </th>
            <th scope='col' className='px-6 py-6'>
              Compliance
            </th>

            {/* <th scope='col' className='px-6 py-6'>
                  Actions
                </th> */}
          </tr>
        </thead>

        <tbody>
          {data?.data.map((item, index: number) => {
            const { _id: id, compliance, dataSensitiveItem, details } = item;

            return (
              <tr
                key={index}
                className='bg-none border-b-2 border-white hover:bg-white '>
                <td className='px-6 py-4'>{dataSensitiveItem}</td>
                <td className='px-6 py-4'>{details}</td>
                <td className='px-6 py-4'>{compliance}</td>

                {/* <td className='text-right flex gap-8 px-6 py-4'>
                          <button
                            onClick={() => {
                              setSecurityItemID(item);
                              setOpenEditModal(true);
                            }}>
                            <RiEdit2Fill className='text-2xl text-green-400' />
                          </button>
                          <button
                            onClick={() => {
                              setDeleteItemID(id);
                              setDeleteModal(true);
                            }}>
                            <MdDeleteSweep className='text-2xl text-red-500' />
                          </button>
                        </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Security;
