import React from 'react';
import { InlineWidget } from 'react-calendly';
import { GrClose } from 'react-icons/gr';

const Assistance = ({
  setIsAssistance,
}: {
  setIsAssistance: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div>
      <div className='flex justify-end mb-4'>
        <GrClose
          className='cursor-pointer font-bold text-2xl'
          onClick={() => setIsAssistance(false)}
        />
      </div>
      <InlineWidget url='https://calendly.com/alexander-186/experiment1et' />
    </div>
  );
};

export default Assistance;
