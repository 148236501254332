/* eslint-disable no-mixed-operators */
import React, { useEffect } from 'react';
import { initFirebase } from 'config/firebase';
import { getAuth } from 'firebase/auth';
import { useResource, useOne, useInvalidate, useDelete } from '@refinedev/core';

import ViewDetails from 'components/projects/dataSources/viewDetails';
import EditDataSource from 'components/projects/dataSources/editDataSource';
import ViewUserStoriesDetails from 'components/projects/userStories/viewUserStoriesDetails';
import EditUserStories from 'components/projects/userStories/editUserStories';
import ViewKKPIDetails from 'components/projects/kpis/viewKKPIDetails';
import EditKPIDetails from 'components/projects/kpis/editKPIDetails';
import ViewSecurityDetails from 'components/projects/security/viewSecurityDetails';
import EditSecurityDetails from 'components/projects/security/editSecurityDetails';
import PDFDownloadButton from 'components/projects/PDFDownloadButton';
import UserStorySection from 'components/projects/userStories/UserStorySection';
import DataSourceSection from 'components/projects/dataSources/DataSourceSection';
import KPISection from 'components/projects/kpis/KPISection';
import SecurityDetailsSection from 'components/projects/security/SecurityDetailsSection';
import ReportSection from 'components/projects/Reports/ReportSection';
import BrandingSection from 'components/projects/branding/BrandingSection';

import { notifyError } from 'components/shared/Toastify';
import Loading from 'components/shared/loading';
import Modal from 'components/shared/Modal';

import { useDataSourceStore } from 'store/dataSource.store';
import { useStoryStore } from 'store/userStory.store';
import { useProjectStore } from 'store/projects.store';
import { useKPIStore } from 'store/kpi.store';
import { useSecurityStore } from 'store/securityDetail.store';
import { useSampleReportStore } from 'store/sampleReport.store';
import { useBrandingStore } from 'store/branding.store';

import { ISecurity } from 'types/securityTypes';
import { IProjectObjective } from 'types/projectObjective';
import ProjectObjectiveSection from 'components/projects/projectObjective/ProjectObjectiveSection';
import { useProjectObjectiveStore } from 'store/projectObjectives.store';
import ViewProjectObjectiveDetails from 'components/projects/projectObjective/viewProjectObjectiveDetails';
import EditProjectObejective from 'components/projects/projectObjective/editProjectObjective';
import ForbiddenError from 'components/errorComponent/forbidden';

export type UserStoriesItemTypes = {
  _id: string;
  story: string;
  intendedAudience: string;
  storyDetails: string;
  priority: string;
};

export type DatasourceItem = {
  _id: string;
  dataSourceName: string;
  dataSourceSubType: string;
  dataSourceType: string;
  estimateVolumeInGB: number;
  fileName: string;
  fileURL: string;
};
export type KPIItem = {
  _id: string;
  comparator: string[];
  dataSource: { _id: string; dataSourceName: string }[];
  dimensionality: string[];
  metric: string;
  metricCalculation: string;
  metricDefinition: string;
  reportedFrequency: string;
  table: string[];
  createdAt: string;
  updatedAt: string;
};

const SingleProject = () => {
  initFirebase();
  const auth = getAuth();
  const addProjectItemID = useProjectStore((state) => state.addProjectItemID);
  const clearDataSourceItem = useDataSourceStore(
    (state) => state.clearDataSourceItem
  );
  const clearStoryItem = useStoryStore((state) => state.clearStoryItem);
  const clearKPIItem = useKPIStore((state) => state.clearKPIItem);
  const clearSecurityDetailsItem = useSecurityStore(
    (state) => state.clearSecurityItem
  );
  const clearReport = useSampleReportStore((state) => state.clearSampleReport);
  const clearBranding = useBrandingStore((state) => state.clearBranding);
  const clearProjectObjective = useProjectObjectiveStore(
    (state) => state.clearProjectObjectiveItem
  );

  const [openDataSourceModal, setOpenDataSourceModal] =
    React.useState<boolean>(false);
  const [openUserStoriesViewModal, setOpenUserStoriesViewModal] =
    React.useState<boolean>(false);
  const [openKPIViewModal, setOpenKPIViewModal] =
    React.useState<boolean>(false);
  const [openSecurityViewModal, setOpenSecurityViewModal] =
    React.useState<boolean>(false);
  const [openProjectObjectiveViewModal, setOpenProjectObjectiveViewModal] =
    React.useState<boolean>(false);

  // Edit Modals boolean
  const [openDatasourceEditModal, setOpenDatasourceEditModal] =
    React.useState<boolean>(false);
  const [openUserStoriesEditModal, setOpenUserStoriesEditModal] =
    React.useState<boolean>(false);
  const [openKPIEditModal, setOpenKPIEditModal] =
    React.useState<boolean>(false);
  const [openSecurityEditModal, setOpenSecurityEditModal] =
    React.useState<boolean>(false);
  const [openProjectObjectiveEditModal, setOpenProjectObjectiveEditModal] =
    React.useState<boolean>(false);

  // Items
  const [dataSourceItem, setDataSourceItem] = React.useState<
    DatasourceItem | undefined
  >();
  const [userStoriesItem, setUserStoriesItem] = React.useState<
    UserStoriesItemTypes | undefined
  >();
  const [kpiItem, setKpiItem] = React.useState<KPIItem | undefined>();
  const [securityItem, setSecurityItem] = React.useState<
    ISecurity | undefined
  >();
  const [projectObjectiveItem, setProjectObjectiveItem] = React.useState<
    IProjectObjective | undefined
  >();

  // Edit Items
  const [editDataSourceItem, setEditDataSourceItem] = React.useState<
    DatasourceItem | undefined
  >();
  const [editUserStoriesItem, setEditUserStoriesItem] = React.useState<
    UserStoriesItemTypes | undefined
  >();
  const [editKPIItem, setEditKPIItem] = React.useState<KPIItem | undefined>();
  const [editSecurityItem, setEditSecurityItem] = React.useState<
    ISecurity | undefined
  >();
  const [editProjectObjectiveItem, setEditProjectObjectiveItem] =
    React.useState<IProjectObjective | undefined>();

  const [isAdmin, setIsAdmin] = React.useState<boolean>();

  const { id } = useResource();
  const invalidate = useInvalidate();
  const { mutate: deleteItem } = useDelete();

  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchUserClaims = async () => {
      try {
        if (currentUser) {
          const idTokenResult = await currentUser.getIdTokenResult();
          const isAdminClaim = idTokenResult.claims.Administrator;
          setIsAdmin(!!isAdminClaim);
        }
      } catch (error) {
        if (error instanceof Error) {
          notifyError(error.message);
        }
      }
    };

    fetchUserClaims();
  }, [currentUser]);

  const resource = isAdmin
    ? 'admin/project/single-project'
    : 'project/single-project';

  const {
    data: projects,
    isLoading: fetchLoading,
    error,
  } = useOne({
    resource,
    id: id,
  });

  const deleteProjectItem = (id: string, resource: string) => {
    deleteItem(
      {
        resource,
        id,
      },
      {
        onSuccess(data) {
          if (data.data) {
            invalidate({
              resource: 'project/get-project',
              invalidates: ['all'],
            });
          }
        },
      }
    );
  };

  if (fetchLoading) {
    return <Loading />;
  }

  return (
    <>
      <Modal
        isOpen={openProjectObjectiveViewModal}
        close={setOpenProjectObjectiveViewModal}>
        <ViewProjectObjectiveDetails
          projectObjectiveItem={projectObjectiveItem}
          setOpenProjectObjectiveViewModal={setOpenProjectObjectiveViewModal}
        />
      </Modal>
      <Modal
        isOpen={openUserStoriesViewModal}
        close={setOpenUserStoriesViewModal}>
        <ViewUserStoriesDetails
          userStoriesItem={userStoriesItem}
          setOpenUserStoriesViewModal={setOpenUserStoriesViewModal}
        />
      </Modal>

      <Modal isOpen={openDataSourceModal} close={setOpenDataSourceModal}>
        <ViewDetails
          dataSourceItem={dataSourceItem}
          setOpenDataSourceModal={setOpenDataSourceModal}
        />
      </Modal>

      <Modal
        isOpen={openKPIViewModal}
        close={setOpenKPIViewModal}
        className='fixed w-fit  h-[60vh] overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
        <ViewKKPIDetails
          kpiItem={kpiItem}
          setOpenKPIViewModal={setOpenKPIViewModal}
        />
      </Modal>

      <Modal isOpen={openSecurityViewModal} close={setOpenSecurityViewModal}>
        <ViewSecurityDetails
          securityItem={securityItem}
          setOpenSecurityViewModal={setOpenSecurityViewModal}
        />
      </Modal>

      {/* edit user modal */}

      <Modal
        className='fixed w-fit  h-fit overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'
        isOpen={openProjectObjectiveEditModal}
        close={setOpenProjectObjectiveEditModal}>
        <EditProjectObejective
          setOpenProjectObjectiveEditModal={setOpenProjectObjectiveEditModal}
          editProjectObjectiveItem={editProjectObjectiveItem}
        />
      </Modal>

      <Modal
        className='fixed w-fit  h-[60vh] overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'
        isOpen={openUserStoriesEditModal}
        close={setOpenUserStoriesEditModal}>
        <EditUserStories
          setOpenUserStoriesEditModal={setOpenUserStoriesEditModal}
          editUserStoriesItem={editUserStoriesItem}
        />
      </Modal>

      <Modal
        className='fixed w-fit  h-[60vh] overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'
        isOpen={openDatasourceEditModal}
        close={setOpenDatasourceEditModal}>
        <EditDataSource
          setOpenDatasourceEditModal={setOpenDatasourceEditModal}
          editDataSourceItem={editDataSourceItem}
        />
      </Modal>

      <Modal
        className='fixed w-fit  h-[60vh] overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'
        isOpen={openKPIEditModal}
        close={setOpenKPIEditModal}>
        <EditKPIDetails
          setOpenKPIEditModal={setOpenKPIEditModal}
          editKPIItem={editKPIItem}
        />
      </Modal>

      <Modal
        className='fixed w-fit  h-[60vh] overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'
        isOpen={openSecurityEditModal}
        close={setOpenSecurityEditModal}>
        <EditSecurityDetails
          setOpenSecurityEditModal={setOpenSecurityEditModal}
          editSecurityItem={editSecurityItem}
        />
      </Modal>

      <div className='my-4'>
        {(projects && projects.data === null) || error ? (
          <ForbiddenError />
        ) : (
          <>
            <div className='flex justify-end mb-4'>
              <PDFDownloadButton projects={projects} />
            </div>

            {/* <div className='w-full max-w-full grid grid-cols-1 gap-8 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 overflow-y-auto'> */}
            <div className='w-full h-[45rem] max-w-full flex gap-8 overflow-auto'>
              <ProjectObjectiveSection
                id={id}
                navigateResource='/project-objective'
                clearProjectStorage={clearProjectObjective}
                deleteProjectItem={deleteProjectItem}
                addProjectItemID={addProjectItemID}
                projects={projects}
                setEditProjectObjectiveItem={setEditProjectObjectiveItem}
                setOpenProjectObjectiveViewModal={
                  setOpenProjectObjectiveViewModal
                }
                setOpenProjectObjectiveEditModal={
                  setOpenProjectObjectiveEditModal
                }
                setProjectObjectiveItem={setProjectObjectiveItem}
              />

              <UserStorySection
                id={id}
                navigateResource='/user-story'
                clearProjectStorage={clearStoryItem}
                deleteProjectItem={deleteProjectItem}
                addProjectItemID={addProjectItemID}
                projects={projects}
                setEditUserStoriesItem={setEditUserStoriesItem}
                setOpenUserStoriesViewModal={setOpenUserStoriesViewModal}
                setOpenUserStoriesEditModal={setOpenUserStoriesEditModal}
                setUserStoriesItem={setUserStoriesItem}
              />

              {/* Data Sources */}
              <DataSourceSection
                id={id}
                navigateResource='/datasource'
                clearProjectStorage={clearDataSourceItem}
                deleteProjectItem={deleteProjectItem}
                addProjectItemID={addProjectItemID}
                projects={projects}
                setEditDataSourceItem={setEditDataSourceItem}
                setOpenDataSourceModal={setOpenDataSourceModal}
                setOpenDatasourceEditModal={setOpenDatasourceEditModal}
                setDataSourceItem={setDataSourceItem}
              />

              {/* KPIS */}
              <KPISection
                id={id}
                navigateResource='/kpi'
                clearProjectStorage={clearKPIItem}
                deleteProjectItem={deleteProjectItem}
                addProjectItemID={addProjectItemID}
                projects={projects}
                setEditKPIItem={setEditKPIItem}
                setOpenKPIViewModal={setOpenKPIViewModal}
                setOpenKPIEditModal={setOpenKPIEditModal}
                setKpiItem={setKpiItem}
              />

              {/* Security Details */}
              <SecurityDetailsSection
                id={id}
                navigateResource='security/delete-security'
                clearProjectStorage={clearSecurityDetailsItem}
                deleteProjectItem={deleteProjectItem}
                addProjectItemID={addProjectItemID}
                projects={projects}
                setEditSecurityItem={setEditSecurityItem}
                setOpenSecurityViewModal={setOpenSecurityViewModal}
                setOpenSecurityEditModal={setOpenSecurityEditModal}
                setSecurityItem={setSecurityItem}
              />

              {/* files */}
              <div className='p-4 bg-white board-bg rounded-lg h-fit w-full'>
                <ReportSection
                  id={id}
                  navigateResource='/upload-sample-report'
                  clearProjectStorage={clearReport}
                  addProjectItemID={addProjectItemID}
                  deleteProjectItem={deleteProjectItem}
                  projects={projects}
                />

                <BrandingSection
                  id={id}
                  navigateResource='/branding'
                  clearProjectStorage={clearBranding}
                  addProjectItemID={addProjectItemID}
                  deleteProjectItem={deleteProjectItem}
                  projects={projects}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SingleProject;
