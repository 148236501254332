import { BaseKey, BaseRecord, GetOneResponse } from '@refinedev/core';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IProjectObjective } from 'types/projectObjective';

interface IProjectObjectiveProps {
  projects: GetOneResponse<BaseRecord> | undefined;
  setProjectObjectiveItem: React.Dispatch<
    React.SetStateAction<IProjectObjective | undefined>
  >;
  setOpenProjectObjectiveViewModal: React.Dispatch<boolean>;
  setEditProjectObjectiveItem: React.Dispatch<
    React.SetStateAction<IProjectObjective | undefined>
  >;
  setOpenProjectObjectiveEditModal: React.Dispatch<boolean>;
  addProjectItemID: (id: string) => void;
  id: BaseKey | undefined;
  deleteProjectItem: (id: string, resource: string) => void;
  clearProjectStorage: () => void;
  navigateResource: string;
}

const ProjectObjectiveSection: FC<IProjectObjectiveProps> = ({
  addProjectItemID,
  clearProjectStorage,
  deleteProjectItem,
  id,
  navigateResource,
  projects,
  setEditProjectObjectiveItem,
  setOpenProjectObjectiveEditModal,
  setProjectObjectiveItem,
  setOpenProjectObjectiveViewModal,
}) => {
  const navigate = useNavigate();

  return (
    <div className='p-4 bg-white board-bg rounded-lg h-fit w-full'>
      <div className='w-64'>
        <h4 className='text-xl font-bold'>Project Objective</h4>
        {projects?.data.projectObjective.length > 0 ? (
          projects?.data.projectObjective.map(
            (item: IProjectObjective, index: number) => {
              const { projectObjectiveName, _id: id } = item;
              const resource = 'project-objective/delete-project-objective';
              return (
                <div key={index} className='mt-4 bg-white p-3 rounded-lg'>
                  <div>
                    <div>
                      <span className='font-semibold'>Project Objective:</span>
                      <p>{projectObjectiveName}</p>
                    </div>

                    <div className='flex justify-end gap-3 mt-8'>
                      <button
                        onClick={() => {
                          setProjectObjectiveItem(item);
                          setOpenProjectObjectiveViewModal(true);
                        }}
                        className='p-2 rounded-lg text-white bg-blue-400 w-fit text-xs'>
                        View Details
                      </button>
                      <button
                        onClick={() => {
                          setEditProjectObjectiveItem(item);
                          setOpenProjectObjectiveEditModal(true);
                        }}
                        className='p-2 rounded-lg text-white bg-blue-400 w-fit'>
                        Edit
                      </button>
                      <button
                        onClick={() => deleteProjectItem(id, resource)}
                        className='p-2 rounded-lg text-white bg-pink-500 w-fit'>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              );
            }
          )
        ) : (
          <div className='mt-4 bg-white p-3 rounded-lg '>
            <div className='w-fit h-fit m-auto'>
              <button
                onClick={() => {
                  addProjectItemID(id as string);
                  clearProjectStorage();
                  navigate(navigateResource);
                }}>
                Create New Data
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectObjectiveSection;
