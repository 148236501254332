import { useMany } from '@refinedev/core';
import { useKPIStore } from 'store/kpi.store';

import { BsChevronDown } from 'react-icons/bs';
import { useState } from 'react';

const KPIBusinessGlossary: React.FC = () => {
  const kpiTotalFiltered = useKPIStore((state) => state.kpiTotalFiltered);
  const projectNames = useKPIStore((state) => state.projectNames);
  const updateProjectNames = useKPIStore((state) => state.updateProjectNames);

  const kpis = kpiTotalFiltered.map((item) => item._id);

  const { data } = useMany({
    resource: 'kpi/get-multiple-kpi',
    ids: kpis,
  });

  const [isProjectNameModalOpen, setProjectNameModalOpen] = useState(false);

  const handleProjectModalOpen = () => {
    setProjectNameModalOpen(!isProjectNameModalOpen);
  };

  return (
    <div className='flex flex-col gap-2 py-2 px-1'>
      <h2 className='font-semibold text-lg my-2'>Business Glossary</h2>
      <table className='w-full text-sm text-left text-gray-500 min-h-[10rem]'>
        <thead className='uppercase font-bold bg-none border-b-2 border-white'>
          <tr>
            <th
              scope='col'
              className='relative px-6 py-6 flex flex-row justify-between items-center gap-1'
            >
              <span>Project name</span>
              <button
                type='button'
                className='p-1 bg-white hover:bg-gray-100 rounded-sm'
                onClick={handleProjectModalOpen}
              >
                <BsChevronDown />
              </button>
              {isProjectNameModalOpen ? (
                <ul className='absolute z-50 top-16 xl:top-14 left-4 flex flex-col min-w-[9rem] bg-white p-2 rounded'>
                  {projectNames?.map(({ name, id, isChecked }) => {
                    return (
                      <li
                        key={name}
                        className='w-full flex flex-row justify-start items-center gap-1 p-1'
                      >
                        <input
                          data-id={id}
                          value={name}
                          type='checkbox'
                          className='cursor-pointer'
                          checked={isChecked}
                          onChange={(e) => {
                            const name = e.currentTarget.dataset.id;
                            if (name) {
                              updateProjectNames(name);
                            }
                          }}
                        />
                        <label htmlFor={name}>{name}</label>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </th>
            <th scope='col' className='px-6 py-6'>
              Metric
            </th>
            <th scope='col' className='px-6 py-6'>
              Metric Definition
            </th>
            <th scope='col' className='px-6 py-6'>
              Metric Calculation
            </th>
            <th scope='col' className='px-6 py-6'>
              Reported Frequency
            </th>
            <th scope='col' className='px-6 py-6'>
              Comparator
            </th>
            <th scope='col' className='px-6 py-6'>
              Data Source
            </th>
            <th scope='col' className='px-6 py-6'>
              Table
            </th>
            <th scope='col' className='px-6 py-6'>
              Dimentionality
            </th>
          </tr>
        </thead>
        <tbody>
          {projectNames.map((projectItem) => {
            if (projectItem.isChecked === false) {
              return <></>;
            } else {
              const filtered = data?.data.filter(
                (dataItem) => dataItem.project[0] === projectItem.id
              );
              return filtered?.map((item, index: number) => {
                const {
                  reportedFrequency,
                  comparator,
                  dataSource,
                  dimensionality,
                  metric,
                  metricCalculation,
                  metricDefinition,
                  table,
                } = item;

                return (
                  <tr
                    key={index}
                    className='bg-none border-b-2 border-white hover:bg-white '
                  >
                    <td className='px-6 py-4'>{projectItem.name}</td>
                    <td className='px-6 py-4'>{metric}</td>
                    <td className='px-6 py-4'>{metricDefinition}</td>
                    <td className='px-6 py-4'>{metricCalculation}</td>
                    <td className='px-6 py-4'>{reportedFrequency}</td>
                    <td className='px-6 py-4'>
                      {comparator.map((item: string, index: number) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </td>

                    <td className='px-6 py-4'>
                      {dataSource.map(
                        (item: { dataSourceName: string }, index: number) => {
                          return <div key={index}>{item.dataSourceName}</div>;
                        }
                      )}
                    </td>
                    <td className='px-6 py-4'>
                      {table.map((item: string, index: number) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </td>
                    <td className='px-6 py-4'>
                      {dimensionality.map((item: string, index: number) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </td>
                  </tr>
                );
              });
            }
          })}
        </tbody>
      </table>
    </div>
  );
};

export default KPIBusinessGlossary;
