import { BaseRecord, useDelete } from '@refinedev/core';
import { AddUserStoryForms } from 'components/userStory/addUserStory';
import { EditUserStoryForms } from 'components/userStory/editUserStoryForms';
import NoData from 'components/errorComponent/noData';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { InlineWidget } from 'react-calendly';
import { GrClose } from 'react-icons/gr';
import { MdDeleteSweep } from 'react-icons/md';
import { RiEdit2Fill } from 'react-icons/ri';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { modalBackdrop, modalContent, modalWrapper } from 'utils/modal-framer';
import { useStoryStore } from 'store/userStory.store';

const UserStory = () => {
  const userStoryItems = useStoryStore((state) => state.storyItem);
  const removeStoryItem = useStoryStore((state) => state.removeStoryItem);
  const removeStoryItemID = useStoryStore((state) => state.removeStoryItemID);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [discoveryItemID, setDiscoveryItemID] = useState<string>('');
  const [userStoryItemID, setUserStoryItemID] = useState<BaseRecord>();
  const [isAssistance, setIsAssistance] = useState<boolean>(false);

  const { mutate, isLoading } = useDelete();
  const [projectIdParams] = useSearchParams();
  const navigate = useNavigate();
  const projectId = projectIdParams.get('id');

  return (
    <>
      <AnimatePresence>
        {/* add discovery modal */}
        {openModal ? (
          <>
            <motion.div
              variants={modalBackdrop}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed h-screen w-screen bg-[#000] bg-opacity-50 top-0 left-0 z-50'
              onClick={() => setOpenModal(false)}
            />

            <motion.div
              variants={modalWrapper}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed w-fit  h-[60vh] overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
              <motion.div
                variants={modalContent}
                initial='hidden'
                animate='vissible'
                exit='exit'>
                <AddUserStoryForms
                  projectId={projectId}
                  setOpenModal={setOpenModal}
                />
              </motion.div>
            </motion.div>
          </>
        ) : null}
        {/* end discovery modal */}

        {/* edit user modal */}
        {openEditModal ? (
          <>
            <motion.div
              variants={modalBackdrop}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed h-screen w-screen bg-[#000] bg-opacity-50 top-0 left-0 z-50'
              onClick={() => setOpenEditModal(false)}
            />

            <motion.div
              variants={modalWrapper}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed w-fit  h-fit overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
              <motion.div
                variants={modalContent}
                initial='hidden'
                animate='vissible'
                exit='exit'>
                <EditUserStoryForms
                  userStoryItemID={userStoryItemID}
                  setOpenEditModal={setOpenEditModal}
                />
              </motion.div>
            </motion.div>
          </>
        ) : null}
        {/* end edit user modal */}

        {/* delete modal */}
        {deleteModal ? (
          <>
            <motion.div
              variants={modalBackdrop}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed h-screen w-screen bg-[#000] bg-opacity-50 top-0 left-0 z-50'
              onClick={() => setDeleteModal(false)}
            />

            <motion.div
              variants={modalWrapper}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed w-fit  h-fit overflow-y-auto scrollbar-hide mx-auto top-32 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
              <motion.div
                variants={modalContent}
                initial='hidden'
                animate='vissible'
                exit='exit'>
                <div>
                  <p className='text-xl text-center font-semibold mb-12'>
                    Are you sure you want to
                    <br /> delete?
                  </p>
                  <div className='flex gap-4 justify-center'>
                    <button
                      onClick={() => setDeleteModal(false)}
                      className='w-32 bg-white border border-slate-200 p-3 rounded-xl'>
                      Cancel
                    </button>
                    <button
                      disabled={isLoading}
                      onClick={() => {
                        mutate(
                          { resource: 'user-story', id: discoveryItemID },
                          {
                            onSuccess: (data) => {
                              removeStoryItemID(data.data._id);
                              removeStoryItem(data.data._id);
                              if (data.data) {
                                setDeleteModal(false);
                              }
                            },
                          }
                        );
                      }}
                      className='w-32 bg-pink-500 text-white p-3 rounded-xl'>
                      {isLoading ? 'Deleting...' : 'Delete'}
                    </button>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          </>
        ) : null}
        {/* end delete modal */}

        {isAssistance ? (
          <>
            <motion.div
              variants={modalBackdrop}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed h-screen w-screen bg-[#000] bg-opacity-50 top-0 left-0 z-50'
              onClick={() => setIsAssistance(false)}
            />

            <motion.div
              variants={modalWrapper}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed w-fit md:w-[30vw]  h-fit overflow-y-auto scrollbar-hide mx-auto top-4 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
              <motion.div
                variants={modalContent}
                initial='hidden'
                animate='vissible'
                exit='exit'>
                <div>
                  <div className='flex justify-end mb-4'>
                    <GrClose
                      className='cursor-pointer font-bold text-2xl'
                      onClick={() => setIsAssistance(false)}
                    />
                  </div>
                  <InlineWidget url='https://calendly.com/alexander-186/experiment1et' />
                </div>
              </motion.div>
            </motion.div>
          </>
        ) : null}
      </AnimatePresence>
      <div className='relative tables p-4 mt-4 mb-8 w-full h-[90vh] border-2 border-white scroll-auto'>
        <div className='flex justify-between mb-4'>
          <p className='font-semibold text-xl'>User Stories</p>
          <div className='flex space-x-8 items-center'>
            <button
              onClick={() => setIsAssistance(true)}
              className='text-blue-400 underline'>
              Need assistance?
            </button>
            <button
              className='p-2 bg-blue-400 text-white rounded-lg capitalize'
              onClick={() => setOpenModal(true)}>
              Add Story
            </button>
          </div>
        </div>
        <div className='relative overflow-x-auto shadow-md rounded-lg'>
          <table className='w-full text-sm text-left text-gray-500 '>
            <thead className='text-xs uppercase bg-white'>
              <tr>
                <th scope='col' className='px-6 py-6'>
                  User Story
                </th>
                <th scope='col' className='px-6 py-6'>
                  Story Detail
                </th>
                <th scope='col' className='px-6 py-6'>
                  Intended Audience
                </th>
                <th scope='col' className='px-6 py-6'>
                  Priority
                </th>

                <th scope='col' className='px-6 py-6'>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {userStoryItems.map((item, index: number) => {
                const { _id, story, intendedAudience, storyDetails, priority } =
                  item;
                return (
                  <tr
                    className='bg-none border-b-2 border-white hover:bg-white '
                    key={index}>
                    <td className='px-6 py-4'>{story}</td>
                    <td className='px-6 py-4'>
                      {storyDetails.substring(0, 200)}
                      {storyDetails.length > 200 ? '...' : ''}
                    </td>
                    <td className='px-6 py-4'>{intendedAudience}</td>
                    <td className='px-6 py-4'>{priority}</td>
                    <td className='text-right flex gap-8 px-6 py-4'>
                      <button
                        onClick={() => {
                          setUserStoryItemID(item);
                          setOpenEditModal(true);
                        }}>
                        <RiEdit2Fill className='text-2xl text-green-400' />
                      </button>
                      <button
                        onClick={() => {
                          setDiscoveryItemID(_id);
                          setDeleteModal(true);
                        }}>
                        <MdDeleteSweep className='text-2xl text-red-500' />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {userStoryItems.length < 1 ? <NoData /> : null}
        </div>
        <div className='absolute mx-auto left-0 right-0 bottom-12 text-center'>
          <div className=''>
            <button
              onClick={() => navigate('/project-objective')}
              className='bg-blue-400 p-4 text-white rounded-xl w-28 mr-8'>
              Go back
            </button>
            <button
              onClick={() => navigate(`/datasource?id=${projectId}`)}
              className='bg-blue-400 p-4 text-white rounded-xl w-24'>
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserStory;
