import { useDelete } from '@refinedev/core';
import { BaseRecord } from '@refinedev/core/dist/interfaces';
import List from 'components/projectObjectives/List';
import AddProjectObjectiveFrom from 'components/projectObjectives/addForm';
import EditProjectObejective from 'components/projectObjectives/editForm';
import Assistance from 'components/shared/Assistance';
import Modal from 'components/shared/Modal';
import React, { useState } from 'react';
import { useProjectObjectiveStore } from 'store/projectObjectives.store';

const ProjectObjectivePage = () => {
  const { projectObjectiveItem } = useProjectObjectiveStore();

  const removeProjectObjectiveId = useProjectObjectiveStore(
    (state) => state.removeProjectObjectives
  );
  const removeProjectObjectiveItem = useProjectObjectiveStore(
    (state) => state.removeProjectObjectivesItem
  );

  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteItemID, setDeleteItemID] = useState<string>('');
  const [projectObjectiveItemID, setProjectObjectiveItemID] =
    useState<BaseRecord>();
  const [isAssistance, setIsAssistance] = useState<boolean>(false);

  const { mutate, isLoading } = useDelete();

  // User Story

  return (
    <>
      <Modal
        isOpen={openModal}
        close={setOpenModal}
        className='fixed w-fit  h-[60vh] overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
        <AddProjectObjectiveFrom setOpenModal={setOpenModal} />
      </Modal>

      <Modal
        isOpen={openEditModal}
        close={setOpenEditModal}
        className='fixed w-fit  h-[60vh] overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
        <EditProjectObejective
          projectObjectiveItemID={projectObjectiveItemID}
          setOpenEditModal={setOpenEditModal}
        />
      </Modal>

      <Modal
        isOpen={deleteModal}
        close={setDeleteModal}
        className='fixed w-fit  h-fit overflow-y-auto scrollbar-hide mx-auto top-32 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
        <div>
          <p className='text-xl text-center font-semibold mb-12'>
            Are you sure you want to
            <br /> delete?
          </p>
          <div className='flex gap-4 justify-center'>
            <button
              onClick={() => setDeleteModal(false)}
              className='w-32 bg-white border border-slate-200 p-3 rounded-xl'>
              Cancel
            </button>
            <button
              disabled={isLoading}
              onClick={() => {
                mutate(
                  {
                    resource: 'project-objective/delete-project-objective',
                    id: deleteItemID,
                    invalidates: ['all'],
                  },
                  {
                    onSuccess: (data) => {
                      if (data.data) {
                        removeProjectObjectiveItem(data.data._id);
                        removeProjectObjectiveId(data.data._id);
                        setDeleteModal(false);
                      }
                    },
                  }
                );
              }}
              className='w-32 bg-pink-500 text-white p-3 rounded-xl'>
              {isLoading ? 'Deleting...' : 'Delete'}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isAssistance}
        close={setIsAssistance}
        className='fixed w-[30vw]  h-fit overflow-y-auto scrollbar-hide mx-auto top-4 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'>
        <Assistance setIsAssistance={setIsAssistance} />
      </Modal>

      <List
        projectObjectiveItem={projectObjectiveItem}
        setDeleteItemID={setDeleteItemID}
        setDeleteModal={setDeleteModal}
        setIsAssistance={setIsAssistance}
        setOpenEditModal={setOpenEditModal}
        setOpenModal={setOpenModal}
        setProjectObjectiveItemID={setProjectObjectiveItemID}
      />
    </>
  );
};

export default ProjectObjectivePage;
