import React from 'react';
import { useUpdate, useInvalidate } from '@refinedev/core';
import FormRow from 'HOC/FormRow';
import { GrClose } from 'react-icons/gr';
import { ISecurity } from 'types/securityTypes';
import { useSecurityStore } from 'store/securityDetail.store';

const EditSecurityDetails = ({
  setOpenSecurityEditModal,
  editSecurityItem,
}: {
  setOpenSecurityEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  editSecurityItem: ISecurity | undefined;
}) => {
  const updateSecurityItem = useSecurityStore(
    (state) => state.updateSecurityItem
  );

  const initialValues = {
    id: editSecurityItem?._id,
    dataSensitiveItem: editSecurityItem?.dataSensitiveItem,
    details: editSecurityItem?.details,
    compliance: editSecurityItem?.compliance,
  };

  const [value, setValue] = React.useState(initialValues);
  const invalidate = useInvalidate();

  const { mutate, isLoading } = useUpdate<ISecurity>();

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setValue((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const payload = {
      dataSensitiveItem: value?.dataSensitiveItem,
      details: value?.details,
      compliance: value?.compliance,
    };
    const id = value.id as string;

    mutate(
      {
        resource: 'security/update-security',
        values: payload,
        id: id,
      },
      {
        onSuccess: (data) => {
          if (data.data) {
            updateSecurityItem(data.data);

            invalidate({
              resource: 'project-objective',
              invalidates: ['all'],
            });
            setOpenSecurityEditModal(false);
          }
        },
      }
    );
  };

  return (
    <div>
      <div className='flex justify-end mb-8'>
        <button
          className='font-semibold'
          onClick={() => setOpenSecurityEditModal(false)}>
          <GrClose className='text-2xl' />
        </button>
      </div>
      <form
        onSubmit={handleSubmit}
        className='w-[75vw] max-w-[100vw] md:w-[40rem]'>
        <FormRow
          labelText='Data Sensitive Item'
          name='dataSensitiveItem'
          type='text'
          value={value.dataSensitiveItem}
          handleChange={handleChange}
        />
        <FormRow
          name='details'
          type='text'
          value={value.details}
          handleChange={handleChange}
        />
        <FormRow
          name='compliance'
          type='text'
          value={value.compliance}
          handleChange={handleChange}
        />

        <div className='grid place-items-center'>
          <button
            disabled={isLoading}
            type='submit'
            className={
              isLoading
                ? 'cursor-not-allowed w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
                : 'w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
            }>
            {isLoading ? 'Updating...' : 'Update'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditSecurityDetails;
