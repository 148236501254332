import { useCreate } from '@refinedev/core';
import { notify, notifyError } from 'components/shared/Toastify';
import FormRow from 'HOC/FormRow';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useKPIStore } from 'store/kpi.store';
import { useProjectStore } from 'store/projects.store';

const CreateProjectForms = ({
  setIsCreateProject,
}: {
  setIsCreateProject: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const addProjectItemId = useProjectStore((state) => state.addProjectItemID);
  const addProjectNames = useKPIStore((state) => state.addProjectNames);

  const navigate = useNavigate();
  const { mutate, isLoading } = useCreate();

  const initialValues = {
    name: '',
    description: '',
    priority: '',
    isExistingData: '',
    preferenceTools: '',
  };

  const [value, setValue] = React.useState(initialValues);
  const [isExistingData, setIsExistingData] = React.useState('');

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const payload = {
      name: value.name,
      description: value.description,
      priority: value.priority,
      existingDataWarehouse: isExistingData,
      preferenceTools: value.preferenceTools,
    };

    mutate(
      {
        resource: 'project/create-project',
        values: payload,
        invalidates: ['all'],
      },
      {
        onSuccess: (data) => {
          if (data.data) {
            notify('Project created successfully');
            addProjectItemId(data.data._id);
            console.log(data);
            addProjectNames({
              name: data?.data?.name,
              id: data.data._id,
              isChecked: true,
            });
            navigate(`/project-objective`);
            setIsCreateProject(false);
          }
        },
        onError: (error) => {
          notifyError(error.response.data.errorMsg);
        },
      }
    );
  };

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setValue((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <form
      onSubmit={handleSubmit}
      className='w-[75vw] max-w-[100vw] md:w-[40rem] flex flex-col'
    >
      <FormRow
        name='name'
        type='text'
        labelText='Project Name'
        required={true}
        placeholder='Project Name'
        value={value.name}
        handleChange={handleChange}
      />
      <FormRow
        name='description'
        type='text'
        labelText='Project Description'
        required={true}
        placeholder='Project Description'
        value={value.description}
        handleChange={handleChange}
      />

      <label htmlFor='priority'>Project Priority</label>
      <select
        onChange={handleChange}
        value={value.priority}
        name='priority'
        id='priority'
        className='outline-none border-[1px] border-slate-400  p-2 mb-8 rounded-xl mt-4'
      >
        <option value=''>Select Priority</option>
        <option value='High'>High</option>
        <option value='Medium'>Medium</option>
        <option value='Low'>Low</option>
      </select>

      <label htmlFor='priority'>
        Do you have any existing data warehouse solution to feed this dashboard?
      </label>
      <div className='flex my-8 gap-5'>
        <input
          type='radio'
          id='exitYes'
          name='existingDataWarehouse'
          value={isExistingData}
          checked={isExistingData === 'Yes'}
          onChange={() => setIsExistingData('Yes')}
          className='hidden peer'
          required
        />
        <label
          htmlFor='exitYes'
          className={`inline-flex items-center justify-center w-full p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer  hover:text-gray-600 ${
            isExistingData === 'Yes'
              ? 'peer-checked:border-blue-600 peer-checked:text-blue-600'
              : ''
          } `}
        >
          <div className='block'>
            <div className='w-full text-lg font-semibold'>Yes</div>
          </div>
        </label>

        <input
          type='radio'
          id='existNo'
          name='existingDataWarehouse'
          value={isExistingData}
          checked={isExistingData === 'No'}
          onChange={() => setIsExistingData('No')}
          className='hidden peer'
          required
        />
        <label
          htmlFor='existNo'
          className={`inline-flex items-center justify-center w-full p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer hover:text-gray-600 ${
            isExistingData === 'No'
              ? ' peer-checked:border-red-600 peer-checked:text-red-600 '
              : ''
          }`}
        >
          <div className='block'>
            <div className='w-full text-lg font-semibold'>No</div>
          </div>
        </label>
      </div>

      <FormRow
        name='preferenceTools'
        type='text'
        labelText='For visualisation do you have any preference in tools?'
        required={true}
        placeholder='e.g Power BI, Tableau, Looker etc.'
        value={value.preferenceTools}
        handleChange={handleChange}
      />
      <div>
        <button type='button' className='p-3 rounded-lg bg-blue-400 text-white'>
          More info
        </button>
        <p className='mb-2 font-semibold'>
          From your knowledge do you require a data hub / warehouse
        </p>
      </div>

      <div className='grid place-items-center'>
        <button
          disabled={isLoading}
          type='submit'
          className={
            isLoading
              ? 'cursor-not-allowed w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
              : 'w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
          }
        >
          {isLoading ? 'Saving...' : 'Save'}
        </button>
      </div>
    </form>
  );
};

export default CreateProjectForms;
