import { useCreate, useGetIdentity, useMany } from '@refinedev/core';
import React from 'react';
import Security from './security';
import Story from './story';

import KPI from './kpi';
import { AnimatePresence, motion } from 'framer-motion';
import { modalBackdrop, modalContent, modalWrapper } from 'utils/modal-framer';

import DataSourceConfirmation from './dataSource';
import { useNavigate } from 'react-router-dom';
import { useStoryStore } from 'store/userStory.store';
import { useDataSourceStore } from 'store/dataSource.store';
import { useKPIStore } from 'store/kpi.store';
import { useSecurityStore } from 'store/securityDetail.store';
import { useProjectObjectiveStore } from 'store/projectObjectives.store';
import ProjectObjective from './projectObjective';
import { useSampleReportStore } from 'store/sampleReport.store';
import { useBrandingStore } from 'store/branding.store';
import { useProjectStore } from 'store/projects.store';
import { Identity } from 'types/user.types';
import { notify, notifyError } from 'components/shared/Toastify';

const ConfirmationPage = () => {
  const navigate = useNavigate();
  const { data } = useGetIdentity<Identity>();

  const projectId = useProjectStore((state) => state.projectItemID);

  const { securityItemID, clearSecurityItemID, clearSecurityItem } =
    useSecurityStore();

  const { kpiItemID, clearKPIItemID, clearKPIItem } = useKPIStore();

  const { storyItemID, clearStoryItemID, clearStoryItem } = useStoryStore();

  const { dataSourceItemID, clearDataSourceItemID, clearDataSourceItem } =
    useDataSourceStore();

  const {
    projectObjectives: projectItemId,
    clearProjectObjectiveItemId,
    clearProjectObjectiveItem,
  } = useProjectObjectiveStore();

  const clearSampleReport = useSampleReportStore(
    (state) => state.clearSampleReport
  );

  const { clearBranding } = useBrandingStore();

  const [isConfirmation, setIsConfirmation] = React.useState(false);

  const { data: kpi } = useMany({
    resource: 'kpi/get-multiple-kpi',
    ids: kpiItemID,
  });

  const { data: userStory } = useMany({
    resource: 'user-story/get-multiple-story',
    ids: storyItemID,
  });

  const { data: dataSource } = useMany({
    resource: 'datasource/get-multiple-data-source',
    ids: dataSourceItemID,
  });

  const { data: security } = useMany({
    resource: 'security/get-multiple-security',
    ids: securityItemID,
  });

  const { data: projectObjective } = useMany({
    resource: 'project-objective/get-multiple-project-objective',
    ids: projectItemId,
  });

  const { mutate } = useCreate();

  return (
    <>
      <AnimatePresence>
        {isConfirmation ? (
          <>
            <motion.div
              variants={modalBackdrop}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed h-screen w-screen bg-[#000] bg-opacity-50 top-0 left-0 z-50'
              onClick={() => setIsConfirmation(false)}
            />

            <motion.div
              variants={modalWrapper}
              initial='hidden'
              animate='vissible'
              exit='exit'
              className='fixed w-fit  h-[60vh] overflow-y-auto scrollbar-hide mx-auto top-16 bottom-0 left-0 right-0 p-8 rounded-xl bg-white z-[51]'
            >
              <motion.div
                variants={modalContent}
                initial='hidden'
                animate='vissible'
                exit='exit'
              >
                <h4>Confirmation</h4>
              </motion.div>
            </motion.div>
          </>
        ) : null}
      </AnimatePresence>
      <div className='relative tables p-4 mt-4 mb-8 w-full h-[90vh] border-2 border-white scroll-auto'>
        <p className='font-semibold text-xl mb-4'>Summary Report</p>
        <div className='h-[80%] w-screen md:w-full overflow-auto convert-pdf'>
          <div className='h-full w-full '>
            {projectObjective && projectObjective.data.length > 0 ? (
              <ProjectObjective />
            ) : null}
            {userStory && userStory.data.length > 0 ? <Story /> : null}

            {security && security.data.length > 0 ? <Security /> : null}

            {dataSource && dataSource.data.length > 0 ? (
              <DataSourceConfirmation />
            ) : null}

            {kpi && kpi.data.length > 0 ? <KPI /> : null}
          </div>
        </div>

        <div className='absolute mx-auto left-0 right-0 bottom-12 text-center'>
          <div>
            <button
              onClick={() => {
                mutate(
                  {
                    resource: 'sendmail',
                    values: {
                      link: `${
                        process.env.NODE_ENV === 'development'
                          ? process.env.REACT_APP_DEV_URL
                          : process.env.REACT_APP_PROD_URL
                      }/single-project/${projectId}`,
                      email: `${data?.email}`,
                    },
                  },
                  {
                    onSuccess() {
                      notify('Thank you for sending quotation!');
                      clearSecurityItemID();
                      clearDataSourceItemID();
                      clearKPIItemID();
                      clearStoryItemID();
                      clearProjectObjectiveItemId();
                      clearSecurityItem();
                      clearDataSourceItem();
                      clearKPIItem();
                      clearStoryItem();
                      clearProjectObjectiveItem();
                      clearSampleReport();
                      clearBranding();
                      navigate('/');
                    },
                    onError() {
                      notifyError('Something went wrong');
                    },
                  }
                );
              }}
              className='bg-blue-400 p-4 text-white rounded-xl w-fit mr-8'
            >
              Confirm and download requirements
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationPage;
