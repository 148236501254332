import { useCreate } from '@refinedev/core';
import { notify, notifyError } from 'components/shared/Toastify';
import FormRow from 'HOC/FormRow';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IDataSourceItem, useDataSourceStore } from 'store/dataSource.store';
import { useProjectStore } from 'store/projects.store';
import FileHandler from 'HOC/FileHandler';

const AddDataSourceForm = ({
  setOpenModal,
}: {
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const projectItemId = useProjectStore((state) => state.projectItemID);
  const addDataSourceItemID = useDataSourceStore(
    (state) => state.addDataSourceItemID
  );
  const addDataSourceItem = useDataSourceStore(
    (state) => state.addDataSourceItem
  );
  const navigate = useNavigate();
  const initialValues = {
    dataSourceType: '',
    dataSourceSubType: '',
    other: '',
    dataSourceName: '',
    estimateVolumeInGB: 0,
  };

  const [value, setValue] = React.useState(initialValues);

  const [otherDataSourceType, setOtherDataSourceType] =
    React.useState<string>('');
  const [otherDataSourceSubType, setOtherDataSourceSubType] =
    React.useState<string>('');

  const [schemaFile, setSchemaFile] = React.useState<FileList | null>(null);

  const { mutate, isLoading } = useCreate<IDataSourceItem>();

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setValue((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleImage = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    setSchemaFile(files);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const form = new FormData();

    if (schemaFile !== null) {
      for (const files of schemaFile) {
        form.append('dataSourceFiles', files);
      }
    }

    const payload = {
      dataSourceType:
        value?.dataSourceType === 'other'
          ? otherDataSourceType
          : value?.dataSourceType,
      dataSourceSubType:
        value?.dataSourceSubType === 'other'
          ? otherDataSourceSubType
          : value?.dataSourceSubType,

      dataSourceName: value?.dataSourceName,
      estimateVolumeInGB: value?.estimateVolumeInGB,
      projectId: projectItemId,
    };

    form.append('dataSourceType', payload.dataSourceType);
    form.append('dataSourceSubType', payload.dataSourceSubType);
    form.append('dataSourceName', payload.dataSourceName);
    form.append('estimateVolumeInGB', payload.estimateVolumeInGB.toString());
    form.append('projectId', payload.projectId);

    mutate(
      { resource: 'datasource', values: form },
      {
        onSuccess: (data) => {
          if (data.data) {
            addDataSourceItemID(data.data._id);
            addDataSourceItem(data.data);
            notify('Data Source Created Successfully');
            setOpenModal(false);
          }
        },
        onError(error) {
          notifyError(error.response.data.errorMsg);
        },
      }
    );
  };
  return (
    <>
      <div className=''>
        <form
          onSubmit={handleSubmit}
          encType='multipart/form-data'
          className='w-[75vw] max-w-[100vw] md:w-[40rem] flex flex-col'>
          <FormRow
            labelText='Data Source Name'
            name='dataSourceName'
            type='text'
            value={value.dataSourceName}
            handleChange={handleChange}
          />
          <label htmlFor='dataSourceType' className='capitalize'>
            Data Source Type
          </label>
          {value.dataSourceType === 'other' ? (
            <input
              type='text'
              name='dataSourceType'
              value={otherDataSourceType}
              onChange={(e) => {
                setOtherDataSourceType(e.target.value);
              }}
              placeholder='Other Please Specify'
              className='outline-none border-[1px] border-slate-400  p-2 mb-8 rounded-xl mt-4'
            />
          ) : (
            <select
              id='dataSourceType'
              name='dataSourceType'
              value={value.dataSourceType}
              onChange={(e) => {
                setValue((prevValue) => ({
                  ...prevValue,
                  dataSourceType: e.target.value,
                }));
              }}
              className='w-full outline-none border-[1px] border-slate-400 rounded-xl p-2 mb-8 md:rounded-xl mt-4'>
              <option value=''>Select Data Source Type</option>
              <option value='Database'>DataBase</option>
              <option value='Flat-file'>Flat file</option>
              <option value='Web-Services'>Web Services</option>
              <option value='other'>Other</option>
            </select>
          )}

          <label htmlFor='dataSourceSubType' className='capitalize'>
            Data Source Sub Type
          </label>

          {value.dataSourceSubType === 'other' ? (
            <input
              type='text'
              name='dataSourceSubType'
              value={otherDataSourceSubType}
              onChange={(e) => {
                setOtherDataSourceSubType(e.target.value);
              }}
              placeholder='Other Please Specify'
              className='outline-none border-[1px] border-slate-400  p-2 mb-8 rounded-xl mt-4'
            />
          ) : (
            <select
              id='dataSourceSubType'
              name='dataSourceSubType'
              value={value.dataSourceSubType}
              onChange={(e) => {
                setValue((prevValue) => ({
                  ...prevValue,
                  dataSourceSubType: e.target.value,
                }));
              }}
              className='w-full outline-none border-[1px] border-slate-400 rounded-xl p-2 mb-8 md:rounded-xl mt-4'>
              <option value=''>Select Data Source Sub Type</option>
              {value.dataSourceType === 'Database' && (
                <>
                  <option value='MS-SQL-On-Prem'>MS SQL - On Prem</option>
                  <option value='MS-SQL-Cloud'>MS SQL - Cloud</option>
                  <option value='PostgreSQL-On-Prem'>Postgres - On Prem</option>
                  <option value='Big-Query'>Big Query</option>
                  <option value='Synapse'>Synapse</option>
                  <option value='RedShift'>RedShift</option>
                  <option value='Snowflake'>Snowflake</option>
                </>
              )}

              {value.dataSourceType === 'Flat-file' && (
                <>
                  <option value='CSV'>CSV</option>
                  <option value='Excel'>Excel</option>
                  <option value='Text-file'>Text file</option>
                  <option value='Data-Lake'>Data Lake/Parquet</option>
                </>
              )}

              {value.dataSourceType === 'Web-Services' && (
                <>
                  <option value='API-Endpoint'>API Endpoint</option>
                  <option value='Share-point-list'>Share point list</option>
                </>
              )}

              <option value='other'>Other</option>
            </select>
          )}

          {/* {checked ? (
            <FormRow
              labelText='Other - Please Specify'
              name='other'
              type='text'
              value={value.other}
              handleChange={handleChange}
            />
          ) : null} */}

          <FormRow
            labelText='Estimate Volume In GB'
            name='estimateVolumeInGB'
            type='number'
            value={value.estimateVolumeInGB}
            handleChange={handleChange}
          />
          <FileHandler
            labelText='Schema File (optional)'
            name='schemaFile'
            type='file'
            // value={value.estimateVolumeInGB}
            handleChange={handleImage}
          />

          <div className='grid place-items-center'>
            <button
              disabled={isLoading}
              type='submit'
              className={
                isLoading
                  ? 'cursor-not-allowed w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
                  : 'w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
              }>
              {isLoading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddDataSourceForm;
