import React from 'react';

const NotFoundIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width={406}
      height={249}
      viewBox='0 0 406 249'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M316.902 247C338.807 220.926 352 187.29 352 150.571C352 67.7287 284.843 0.571411 202 0.571411C119.157 0.571411 52 67.7287 52 150.571C52 187.29 65.1931 220.926 87.0976 247H316.902Z'
        fill='#F0F3FD'
      />
      <g opacity={0.15}>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M261.578 108.909C261.415 109.104 261.308 109.34 261.271 109.592L256.45 142.407C256.36 143.022 256.695 143.62 257.266 143.865L261.579 145.713C261.645 145.742 261.712 145.765 261.78 145.782C261.963 145.829 262.118 145.979 262.118 146.168V156.291C262.118 156.669 262.425 156.976 262.803 156.976C263.181 156.976 263.488 156.669 263.488 156.291V144.464C263.488 144.461 263.488 144.457 263.488 144.454L263.488 107.578C263.488 107.258 263.089 107.113 262.883 107.357L261.578 108.909Z'
          fill='#A4A4A4'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M261.578 108.224C261.415 108.419 261.308 108.655 261.271 108.907L256.45 141.722C256.36 142.337 256.695 142.935 257.266 143.18L261.579 145.028C261.645 145.057 261.712 145.08 261.78 145.097C261.963 145.145 262.118 145.294 262.118 145.483V155.606C262.118 155.984 262.425 156.291 262.803 156.291C263.181 156.291 263.488 155.984 263.488 155.606V143.779C263.488 143.776 263.488 143.773 263.488 143.769L263.488 106.893C263.488 106.573 263.089 106.428 262.883 106.672L261.578 108.224Z'
          fill='#C4C4C4'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M222.747 180.608C222.997 180.652 223.255 180.626 223.491 180.532L254.321 168.3C254.898 168.071 255.249 167.482 255.176 166.865L254.62 162.206C254.611 162.134 254.598 162.064 254.579 161.997C254.529 161.815 254.58 161.606 254.744 161.511L263.511 156.45C263.838 156.261 263.951 155.842 263.762 155.514C263.572 155.187 263.154 155.074 262.826 155.263L252.594 161.171C252.588 161.174 252.581 161.178 252.575 161.182L220.639 179.62C220.362 179.78 220.436 180.198 220.75 180.254L222.747 180.608Z'
          fill='#A4A4A4'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M222.405 180.266C222.655 180.31 222.913 180.284 223.15 180.19L253.979 167.958C254.557 167.729 254.908 167.139 254.834 166.522L254.278 161.863C254.27 161.791 254.256 161.722 254.237 161.655C254.187 161.473 254.238 161.263 254.402 161.169L263.169 156.107C263.497 155.918 263.609 155.499 263.42 155.172C263.231 154.844 262.812 154.732 262.484 154.921L252.252 160.828C252.246 160.832 252.24 160.836 252.233 160.839L220.297 179.277C220.021 179.437 220.094 179.855 220.409 179.911L222.405 180.266Z'
          fill='#C4C4C4'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M304.702 178.956C304.615 178.717 304.464 178.507 304.264 178.349L278.256 157.766C277.769 157.381 277.083 157.372 276.586 157.744L272.829 160.555C272.771 160.598 272.717 160.645 272.668 160.694C272.536 160.829 272.329 160.889 272.165 160.795L263.398 155.733C263.071 155.544 262.652 155.656 262.463 155.984C262.274 156.311 262.386 156.73 262.713 156.919L279.32 166.507C279.324 166.51 279.328 166.512 279.332 166.514L304.9 181.276C305.177 181.436 305.502 181.163 305.393 180.863L304.702 178.956Z'
          fill='#A4A4A4'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M304.702 178.272C304.615 178.033 304.464 177.822 304.264 177.665L278.256 157.082C277.769 156.696 277.083 156.687 276.586 157.059L272.829 159.87C272.771 159.913 272.717 159.96 272.668 160.01C272.536 160.144 272.329 160.204 272.165 160.11L263.398 155.048C263.071 154.859 262.652 154.971 262.463 155.299C262.274 155.627 262.386 156.045 262.713 156.235L279.32 165.823C279.324 165.825 279.328 165.827 279.332 165.829L304.9 180.591C305.177 180.751 305.502 180.478 305.393 180.178L304.702 178.272Z'
          fill='#C4C4C4'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M264.564 158.38C265.542 157.962 266.228 156.991 266.228 155.859C266.228 154.347 265.002 153.12 263.489 153.12C261.976 153.12 260.749 154.347 260.749 155.859C260.749 156.991 261.435 157.962 262.413 158.38L256.982 249H269.995L264.564 158.38Z'
          fill='#A4A4A4'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M263.88 158.38C264.858 157.962 265.543 156.991 265.543 155.859C265.543 154.347 264.317 153.12 262.804 153.12C261.291 153.12 260.065 154.347 260.065 155.859C260.065 156.991 260.75 157.962 261.728 158.38L256.298 249H269.31L263.88 158.38Z'
          fill='#C4C4C4'
        />
      </g>
      <g opacity={0.6}>
        <rect
          x={56}
          y={123}
          width={26.7138}
          height={62.9968}
          rx={13.3569}
          fill='#E9EBF2'
        />
        <rect
          x={68.7588}
          y={148.518}
          width={1.59486}
          height={98.4823}
          rx={0.797428}
          fill='#E1E2E5'
        />
        <rect
          x={75.3389}
          y={152.019}
          width={1.59486}
          height={13.443}
          rx={0.797428}
          transform='rotate(30 75.3389 152.019)'
          fill='#E1E2E5'
        />
        <rect
          width={1.59486}
          height={18.9128}
          rx={0.797428}
          transform='matrix(0.866025 -0.5 -0.5 -0.866025 69.2012 173.49)'
          fill='#E1E2E5'
        />
      </g>
      <rect
        x={91}
        y={71}
        width={38.1994}
        height={90.0821}
        rx={19.0997}
        fill='#E9EBF2'
      />
      <rect
        x={109.244}
        y={107.489}
        width={2.28056}
        height={140.825}
        rx={1.14028}
        fill='#E1E2E5'
      />
      <rect
        x={118.653}
        y={112.496}
        width={2.28056}
        height={19.2228}
        rx={1.14028}
        transform='rotate(30 118.653 112.496)'
        fill='#E1E2E5'
      />
      <rect
        width={2.28056}
        height={27.0444}
        rx={1.14028}
        transform='matrix(0.866025 -0.5 -0.5 -0.866025 109.877 143.198)'
        fill='#E1E2E5'
      />
      <path
        opacity={0.8}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M142 49.9322C142 50.001 141.999 50.0697 141.997 50.1381C146.285 50.2446 149.729 53.7541 149.729 58.0678C149.729 62.3806 146.287 65.8895 142 65.9974V66H141.804C141.802 66 141.799 66 141.797 66C141.794 66 141.791 66 141.789 66H126.136V65.9974C126.068 65.9991 126 66 125.932 66C121.551 66 118 62.4486 118 58.0678C118 53.687 121.551 50.1356 125.932 50.1356C126.001 50.1356 126.07 50.1365 126.138 50.1382C126.136 50.0698 126.136 50.0011 126.136 49.9322C126.136 45.5514 129.687 42 134.068 42C138.449 42 142 45.5514 142 49.9322Z'
        fill='white'
      />
      <path
        opacity={0.6}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M288.103 73.8974C288.103 73.9321 288.102 73.9668 288.101 74.0012C290.264 74.055 292 75.8247 292 78C292 80.1748 290.264 81.9442 288.103 81.9987V82H288.01C288.007 82 288.003 82 288 82C287.996 82 287.993 82 287.989 82H280.103V81.9987C280.069 81.9996 280.034 82 280 82C277.791 82 276 80.2091 276 78C276 75.7908 277.791 74 280 74C280.035 74 280.069 74.0004 280.104 74.0013C280.103 73.9668 280.103 73.9322 280.103 73.8974C280.103 71.6883 281.894 69.8974 284.103 69.8974C286.312 69.8974 288.103 71.6883 288.103 73.8974Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M221.2 27.8281C221.2 20.7434 226.943 15 234.028 15C239.286 15 243.804 18.1627 245.786 22.6893C247.06 21.7875 248.617 21.2576 250.298 21.2576C254.251 21.2576 257.52 24.1909 258.046 28H260.98C264.57 28 267.48 30.9101 267.48 34.5C267.48 38.0898 264.57 41 260.98 41H222.5C218.91 41 216 38.0898 216 34.5C216 31.3541 218.235 28.7302 221.203 28.1293C221.201 28.0292 221.2 27.9288 221.2 27.8281Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M317.382 28.71C317.125 29.016 316.957 29.3876 316.899 29.7832L309.318 81.3844C309.176 82.3511 309.702 83.2923 310.6 83.6772L317.382 86.5837C317.487 86.6286 317.593 86.6645 317.698 86.6918C317.986 86.7664 318.23 87.001 318.23 87.2983V103.217C318.23 103.811 318.713 104.293 319.307 104.293C319.902 104.293 320.384 103.811 320.384 103.217V84.646C320.385 84.632 320.385 84.618 320.385 84.604L320.385 26.6165C320.385 26.114 319.758 25.8854 319.434 26.2699L317.382 28.71Z'
        fill='#DCDFE6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M317.382 27.6331C317.125 27.9391 316.957 28.3107 316.899 28.7063L309.318 80.3075C309.176 81.2742 309.702 82.2154 310.6 82.6002L317.382 85.5068C317.487 85.5516 317.593 85.5875 317.698 85.6149C317.986 85.6895 318.23 85.9241 318.23 86.2213V102.14C318.23 102.734 318.713 103.217 319.307 103.217C319.902 103.217 320.384 102.734 320.384 102.14V83.5691C320.385 83.5551 320.385 83.5411 320.385 83.5271L320.385 25.5395C320.385 25.0371 319.758 24.8085 319.434 25.193L317.382 27.6331Z'
        fill='#E9EBF2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M256.32 141.455C256.714 141.525 257.119 141.484 257.491 141.336L305.969 122.101C306.877 121.741 307.429 120.815 307.314 119.844L306.44 112.518C306.426 112.405 306.405 112.295 306.375 112.19C306.296 111.903 306.377 111.574 306.634 111.426L320.42 103.467C320.935 103.169 321.112 102.511 320.814 101.995C320.517 101.48 319.858 101.304 319.343 101.601L303.243 110.897C303.237 110.9 303.23 110.904 303.224 110.907L253.005 139.901C252.57 140.152 252.686 140.81 253.181 140.898L256.32 141.455Z'
        fill='#DCDFE6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M255.782 140.917C256.175 140.986 256.581 140.945 256.952 140.798L305.431 121.563C306.339 121.203 306.891 120.276 306.775 119.306L305.902 111.979C305.888 111.866 305.866 111.757 305.837 111.651C305.758 111.365 305.839 111.036 306.096 110.887L319.882 102.928C320.397 102.631 320.574 101.972 320.276 101.457C319.979 100.942 319.32 100.765 318.805 101.063L302.704 110.359C302.698 110.362 302.692 110.366 302.686 110.369L252.467 139.363C252.032 139.614 252.148 140.272 252.642 140.359L255.782 140.917Z'
        fill='#E9EBF2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M385.19 138.858C385.054 138.482 384.815 138.152 384.502 137.903L343.605 105.537C342.838 104.931 341.76 104.916 340.978 105.502L335.069 109.922C334.978 109.99 334.894 110.063 334.818 110.141C334.609 110.353 334.284 110.448 334.026 110.299L320.24 102.34C319.725 102.043 319.067 102.219 318.769 102.734C318.472 103.249 318.648 103.908 319.164 104.205L345.278 119.282C345.283 119.285 345.288 119.288 345.293 119.291L385.502 142.505C385.937 142.756 386.448 142.328 386.277 141.855L385.19 138.858Z'
        fill='#DCDFE6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M385.19 137.781C385.054 137.405 384.815 137.075 384.502 136.827L343.605 104.46C342.838 103.854 341.76 103.839 340.978 104.425L335.069 108.845C334.978 108.913 334.894 108.986 334.818 109.064C334.609 109.276 334.284 109.371 334.026 109.222L320.24 101.263C319.725 100.966 319.067 101.142 318.769 101.657C318.472 102.172 318.648 102.831 319.164 103.128L345.278 118.205C345.283 118.208 345.288 118.211 345.293 118.214L385.502 141.428C385.937 141.68 386.448 141.251 386.277 140.778L385.19 137.781Z'
        fill='#E9EBF2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M322.077 106.501C323.615 105.844 324.693 104.317 324.693 102.538C324.693 100.159 322.764 98.2308 320.385 98.2308C318.006 98.2308 316.077 100.159 316.077 102.538C316.077 104.317 317.155 105.844 318.694 106.501L310.154 249H330.616L322.077 106.501Z'
        fill='#DCDFE6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M320.999 106.501C322.538 105.844 323.616 104.317 323.616 102.538C323.616 100.159 321.687 98.2308 319.308 98.2308C316.929 98.2308 315 100.159 315 102.538C315 104.317 316.078 105.844 317.616 106.501L309.077 249H329.539L320.999 106.501Z'
        fill='#E9EBF2'
      />
      <path
        d='M331.906 246H320.967C308.293 246 313.761 242.234 316.968 241.503C309.801 238.947 315.082 234.131 318.704 236.14C316.441 227.375 324.174 229.201 325.683 234.131C328.7 229.019 333.981 231.575 330.775 237.053C336.244 234.679 336.244 239.427 333.981 240.887C339.45 241.8 336.394 243.512 333.376 243.512C336.96 245.52 334.319 246 331.906 246Z'
        fill='#D7D8DE'
      />
      <path
        d='M283.906 246H272.967C260.293 246 265.761 242.234 268.968 241.503C261.801 238.947 267.082 234.131 270.704 236.14C268.441 227.375 276.174 229.201 277.683 234.131C280.7 229.019 285.981 231.575 282.775 237.053C288.244 234.679 288.244 239.427 285.981 240.887C291.45 241.8 288.394 243.512 285.376 243.512C288.96 245.52 286.319 246 283.906 246Z'
        fill='#D7D8DE'
      />
      <path
        d='M316.538 247H295.116C270.296 247 281.004 239.938 287.283 238.569C273.248 233.776 283.589 224.746 290.683 228.512C286.251 212.079 301.395 215.503 304.349 224.746C310.259 215.16 320.601 219.953 314.322 230.224C325.033 225.774 325.033 234.675 320.601 237.414C331.312 239.126 325.325 242.335 319.416 242.335C326.433 246.101 321.263 247 316.538 247Z'
        fill='#E1E1E6'
      />
      <path
        d='M273.403 226.831C273.26 225.833 272.842 224.83 271.992 224.143C273.981 224.205 274.738 224.746 275.561 225.553C277.552 227.504 276.486 229.886 275.318 229.504C274.151 229.123 273.551 227.865 273.403 226.831Z'
        fill='#E5E6ED'
      />
      <path
        d='M273.248 224.672C275.329 225.69 276.117 228.384 276.398 229.717L276.133 229.784C275.778 226.991 274.373 225.235 273.248 224.672C273.212 224.654 273.175 224.637 273.139 224.621C273.175 224.636 273.211 224.653 273.248 224.672Z'
        fill='#727785'
      />
      <path
        d='M275.141 225.4C275.485 225.829 275.381 226.487 275.306 226.802L275.242 226.789C275.449 226.154 275.325 225.635 275.141 225.4C275.135 225.392 275.129 225.385 275.123 225.378C275.129 225.385 275.135 225.392 275.141 225.4Z'
        fill='#727785'
      />
      <path
        d='M274.137 225.774C274.308 226.023 274.65 226.076 274.817 226.089L274.819 226.054C274.474 226.058 274.231 225.908 274.137 225.774C274.134 225.77 274.131 225.765 274.128 225.761C274.131 225.765 274.134 225.77 274.137 225.774Z'
        fill='#727785'
      />
      <path
        d='M275.96 226.39C276.181 226.894 275.911 227.503 275.758 227.787L275.7 227.759C276.063 227.198 276.078 226.665 275.96 226.39C275.956 226.382 275.952 226.373 275.948 226.365C275.952 226.373 275.956 226.382 275.96 226.39Z'
        fill='#727785'
      />
      <path
        d='M274.24 226.721C274.546 227.177 275.205 227.276 275.527 227.3L275.535 227.235C274.867 227.241 274.409 226.967 274.24 226.721C274.235 226.713 274.23 226.705 274.225 226.697C274.229 226.705 274.235 226.713 274.24 226.721Z'
        fill='#727785'
      />
      <path
        d='M274.228 227.768C274.655 228.433 275.542 228.599 275.975 228.644L275.982 228.552C275.087 228.536 274.463 228.127 274.228 227.768C274.22 227.757 274.213 227.745 274.206 227.734C274.213 227.745 274.22 227.757 274.228 227.768Z'
        fill='#727785'
      />
      <path
        d='M332.011 229.284C332.591 228.46 333.42 227.757 334.489 227.531C332.69 226.682 331.77 226.821 330.669 227.165C328.009 227.997 327.876 230.604 329.089 230.795C330.303 230.986 331.409 230.137 332.011 229.284Z'
        fill='#E5E6ED'
      />
      <path
        d='M333.131 227.432C330.814 227.393 328.887 229.434 328.031 230.494L328.237 230.674C329.823 228.348 331.873 227.422 333.131 227.432C333.171 227.432 333.211 227.434 333.251 227.436C333.212 227.433 333.172 227.432 333.131 227.432Z'
        fill='#727785'
      />
      <path
        d='M331.113 227.22C330.612 227.446 330.406 228.079 330.33 228.393L330.392 228.411C330.497 227.751 330.843 227.345 331.113 227.22C331.122 227.216 331.131 227.212 331.14 227.208C331.131 227.212 331.122 227.215 331.113 227.22Z'
        fill='#727785'
      />
      <path
        d='M331.838 228.01C331.572 228.154 331.243 228.046 331.089 227.981L331.103 227.948C331.408 228.109 331.693 228.087 331.838 228.01C331.842 228.007 331.847 228.004 331.852 228.002C331.847 228.004 331.843 228.007 331.838 228.01Z'
        fill='#727785'
      />
      <path
        d='M329.934 227.73C329.508 228.078 329.471 228.743 329.479 229.066L329.543 229.067C329.475 228.402 329.704 227.921 329.934 227.73C329.941 227.724 329.948 227.718 329.956 227.712C329.949 227.718 329.941 227.724 329.934 227.73Z'
        fill='#727785'
      />
      <path
        d='M331.315 228.805C330.835 229.073 330.203 228.862 329.905 228.736L329.928 228.675C330.521 228.984 331.053 228.948 331.315 228.805C331.323 228.801 331.332 228.796 331.34 228.791C331.332 228.796 331.324 228.801 331.315 228.805Z'
        fill='#727785'
      />
      <path
        d='M330.85 229.744C330.167 230.142 329.302 229.887 328.895 229.73L328.931 229.645C329.735 230.037 330.477 229.957 330.85 229.744C330.861 229.738 330.873 229.73 330.885 229.723C330.873 229.73 330.862 229.737 330.85 229.744Z'
        fill='#727785'
      />
      <path
        d='M309.101 212.924C309.171 211.918 309.515 210.888 310.313 210.14C308.334 210.346 307.619 210.942 306.856 211.806C305.012 213.896 306.249 216.195 307.385 215.729C308.522 215.264 309.028 213.965 309.101 212.924Z'
        fill='#E5E6ED'
      />
      <path
        d='M309.099 210.759C307.097 211.926 306.507 214.67 306.324 216.02L306.593 216.067C306.744 213.256 308.018 211.402 309.099 210.759C309.134 210.739 309.169 210.719 309.204 210.7C309.169 210.718 309.134 210.738 309.099 210.759Z'
        fill='#727785'
      />
      <path
        d='M307.264 211.623C306.952 212.076 307.104 212.725 307.201 213.033L307.264 213.016C307.012 212.397 307.097 211.87 307.264 211.623C307.269 211.615 307.275 211.607 307.28 211.599C307.275 211.607 307.269 211.615 307.264 211.623Z'
        fill='#727785'
      />
      <path
        d='M308.293 211.923C308.141 212.184 307.803 212.262 307.638 212.286L307.633 212.251C307.977 212.23 308.209 212.064 308.293 211.923C308.296 211.919 308.298 211.914 308.301 211.909C308.298 211.914 308.296 211.918 308.293 211.923Z'
        fill='#727785'
      />
      <path
        d='M306.519 212.67C306.335 213.188 306.649 213.776 306.822 214.049L306.878 214.016C306.475 213.484 306.422 212.953 306.519 212.67C306.522 212.661 306.526 212.652 306.529 212.644C306.526 212.652 306.522 212.661 306.519 212.67Z'
        fill='#727785'
      />
      <path
        d='M308.259 212.875C307.987 213.352 307.337 213.499 307.017 213.546L307.005 213.482C307.672 213.439 308.109 213.133 308.259 212.875C308.263 212.866 308.268 212.858 308.272 212.85C308.268 212.858 308.264 212.866 308.259 212.875Z'
        fill='#727785'
      />
      <path
        d='M308.347 213.919C307.969 214.612 307.097 214.842 306.668 214.919L306.654 214.828C307.546 214.746 308.139 214.293 308.347 213.919C308.354 213.907 308.36 213.895 308.366 213.882C308.36 213.894 308.354 213.906 308.347 213.919Z'
        fill='#727785'
      />
      <path
        d='M287.5 213.729C287.466 213.137 287.271 212.528 286.807 212.082C287.97 212.218 288.387 212.573 288.829 213.088C289.899 214.331 289.155 215.674 288.489 215.392C287.824 215.11 287.535 214.342 287.5 213.729Z'
        fill='#E5E6ED'
      />
      <path
        d='M287.517 212.455C288.687 213.156 289.014 214.775 289.112 215.571L288.953 215.597C288.885 213.941 288.149 212.842 287.517 212.455C287.497 212.443 287.477 212.431 287.456 212.42C287.476 212.431 287.497 212.443 287.517 212.455Z'
        fill='#727785'
      />
      <path
        d='M288.591 212.977C288.77 213.246 288.677 213.626 288.617 213.807L288.58 213.796C288.733 213.434 288.687 213.124 288.591 212.977C288.588 212.972 288.584 212.967 288.581 212.963C288.584 212.967 288.587 212.972 288.591 212.977Z'
        fill='#727785'
      />
      <path
        d='M287.983 213.146C288.071 213.3 288.269 213.349 288.366 213.364L288.369 213.344C288.167 213.329 288.031 213.229 287.983 213.146C287.981 213.143 287.98 213.14 287.979 213.138C287.98 213.14 287.981 213.143 287.983 213.146Z'
        fill='#727785'
      />
      <path
        d='M289.021 213.598C289.126 213.905 288.937 214.248 288.833 214.407L288.8 214.388C289.041 214.077 289.076 213.765 289.021 213.598C289.019 213.593 289.018 213.588 289.016 213.583C289.018 213.588 289.02 213.593 289.021 213.598Z'
        fill='#727785'
      />
      <path
        d='M287.996 213.706C288.153 213.989 288.534 214.08 288.722 214.11L288.729 214.072C288.337 214.042 288.083 213.859 287.996 213.706C287.993 213.701 287.991 213.696 287.988 213.691C287.991 213.696 287.993 213.701 287.996 213.706Z'
        fill='#727785'
      />
      <path
        d='M287.937 214.32C288.154 214.73 288.666 214.872 288.917 214.92L288.926 214.867C288.402 214.812 288.057 214.542 287.937 214.32C287.933 214.312 287.929 214.305 287.926 214.298C287.929 214.305 287.933 214.312 287.937 214.32Z'
        fill='#727785'
      />
      <path
        d='M75.5896 247H64.9758C52.6784 247 57.984 243.501 61.0949 242.823C54.141 240.448 59.2649 235.974 62.7798 237.84C60.5839 229.698 68.0867 231.394 69.5507 235.974C72.4786 231.225 77.6025 233.599 74.4916 238.688C79.7985 236.483 79.7985 240.893 77.6025 242.25C82.9094 243.099 79.9435 244.689 77.0156 244.689C80.4925 246.555 77.9306 247 75.5896 247Z'
        fill='#EDEEF2'
      />
      <path
        d='M81.4768 246.505H76.057C69.7774 246.505 72.4867 244.639 74.0752 244.277C70.5243 243.01 73.1408 240.624 74.9356 241.619C73.8143 237.277 77.6455 238.181 78.3931 240.624C79.8882 238.091 82.5047 239.358 80.9161 242.072C83.626 240.896 83.626 243.248 82.5047 243.971C85.2146 244.424 83.7 245.272 82.2049 245.272C83.9804 246.267 82.6722 246.505 81.4768 246.505Z'
        fill='#DFE0E6'
      />
      <path
        d='M57.2465 234.448C56.9587 234.04 56.5483 233.692 56.0183 233.58C56.9099 233.159 57.3659 233.228 57.9112 233.398C59.2291 233.811 59.295 235.102 58.6938 235.197C58.0925 235.291 57.5447 234.871 57.2465 234.448Z'
        fill='#E5E6ED'
      />
      <path
        d='M56.6914 233.531C57.8394 233.511 58.7939 234.523 59.2181 235.048L59.1162 235.137C58.3303 233.984 57.3147 233.526 56.6914 233.531C56.6716 233.531 56.6518 233.531 56.6318 233.532C56.6513 233.531 56.6712 233.531 56.6914 233.531Z'
        fill='#727785'
      />
      <path
        d='M57.6907 233.425C57.939 233.538 58.0412 233.851 58.0791 234.007L58.0482 234.016C57.9964 233.689 57.8249 233.488 57.6907 233.425C57.6864 233.423 57.6821 233.422 57.6777 233.42C57.682 233.421 57.6864 233.423 57.6907 233.425Z'
        fill='#727785'
      />
      <path
        d='M57.332 233.817C57.4635 233.888 57.6266 233.835 57.703 233.802L57.6959 233.787C57.5449 233.866 57.4037 233.855 57.332 233.817C57.3297 233.816 57.3274 233.814 57.3252 233.813C57.3274 233.814 57.3296 233.815 57.332 233.817Z'
        fill='#727785'
      />
      <path
        d='M58.2757 233.678C58.4867 233.85 58.5048 234.18 58.5012 234.34L58.4691 234.341C58.5032 234.011 58.3894 233.773 58.2757 233.678C58.2721 233.675 58.2684 233.672 58.2646 233.669C58.2683 233.672 58.272 233.675 58.2757 233.678Z'
        fill='#727785'
      />
      <path
        d='M57.5914 234.211C57.8293 234.344 58.1423 234.239 58.2898 234.177L58.2785 234.147C57.985 234.3 57.7212 234.282 57.5914 234.211C57.5872 234.209 57.5832 234.206 57.5791 234.204C57.583 234.206 57.5871 234.209 57.5914 234.211Z'
        fill='#727785'
      />
      <path
        d='M57.8211 234.676C58.1594 234.873 58.5881 234.747 58.7894 234.669L58.772 234.627C58.3731 234.821 58.0056 234.782 57.8211 234.676C57.8153 234.673 57.8095 234.669 57.8037 234.666C57.8093 234.669 57.8151 234.673 57.8211 234.676Z'
        fill='#727785'
      />
      <path
        d='M81.9791 237.916C82.2669 237.508 82.6773 237.16 83.2073 237.048C82.3157 236.627 81.8597 236.696 81.3144 236.867C79.9965 237.279 79.9306 238.571 80.5318 238.665C81.133 238.759 81.6809 238.339 81.9791 237.916Z'
        fill='#E5E6ED'
      />
      <path
        d='M82.5341 236.999C81.3862 236.979 80.4317 237.991 80.0075 238.516L80.1094 238.605C80.8953 237.452 81.9109 236.994 82.5341 236.999C82.554 236.999 82.5738 237 82.5938 237.001C82.5743 237 82.5544 236.999 82.5341 236.999Z'
        fill='#727785'
      />
      <path
        d='M81.5349 236.894C81.2866 237.006 81.1843 237.32 81.1465 237.475L81.1774 237.484C81.2292 237.157 81.4007 236.956 81.5349 236.894C81.5392 236.892 81.5435 236.89 81.5479 236.888C81.5436 236.89 81.5392 236.892 81.5349 236.894Z'
        fill='#727785'
      />
      <path
        d='M81.8936 237.285C81.7621 237.356 81.599 237.303 81.5226 237.271L81.5297 237.255C81.6806 237.334 81.8219 237.323 81.8936 237.285C81.8959 237.284 81.8981 237.282 81.9004 237.281C81.8982 237.282 81.8959 237.284 81.8936 237.285Z'
        fill='#727785'
      />
      <path
        d='M80.9498 237.146C80.7388 237.319 80.7208 237.648 80.7244 237.808L80.7565 237.809C80.7224 237.48 80.8362 237.241 80.9498 237.146C80.9535 237.143 80.9572 237.14 80.9609 237.138C80.9572 237.14 80.9535 237.143 80.9498 237.146Z'
        fill='#727785'
      />
      <path
        d='M81.6342 237.679C81.3963 237.812 81.0833 237.707 80.9358 237.645L80.9471 237.615C81.2406 237.768 81.5044 237.75 81.6342 237.679C81.6383 237.677 81.6424 237.675 81.6465 237.672C81.6425 237.675 81.6385 237.677 81.6342 237.679Z'
        fill='#727785'
      />
      <path
        d='M81.4044 238.145C81.0662 238.341 80.6375 238.215 80.4362 238.138L80.4536 238.095C80.8525 238.29 81.22 238.25 81.4044 238.145C81.4103 238.141 81.4161 238.138 81.4219 238.134C81.4163 238.138 81.4104 238.141 81.4044 238.145Z'
        fill='#727785'
      />
      <path
        d='M70.9142 232.098C70.949 231.6 71.1195 231.09 71.5148 230.719C70.5343 230.821 70.1798 231.116 69.8018 231.545C68.8882 232.58 69.5011 233.719 70.0642 233.488C70.6274 233.258 70.8782 232.614 70.9142 232.098Z'
        fill='#E5E6ED'
      />
      <path
        d='M70.9138 231.026C69.9219 231.604 69.6296 232.964 69.5387 233.632L69.6721 233.656C69.7472 232.263 70.3782 231.345 70.9138 231.026C70.931 231.016 70.9483 231.006 70.9658 230.997C70.9486 231.006 70.9313 231.015 70.9138 231.026Z'
        fill='#727785'
      />
      <path
        d='M70.0045 231.454C69.8503 231.678 69.9254 232 69.9737 232.152L70.0047 232.144C69.8796 231.837 69.9219 231.576 70.0045 231.454C70.0072 231.45 70.0099 231.446 70.0127 231.442C70.0099 231.446 70.0072 231.45 70.0045 231.454Z'
        fill='#727785'
      />
      <path
        d='M70.5138 231.603C70.4383 231.732 70.271 231.77 70.189 231.783L70.1868 231.765C70.357 231.755 70.4722 231.672 70.5138 231.603C70.5151 231.6 70.5163 231.598 70.5176 231.596C70.5164 231.598 70.5151 231.6 70.5138 231.603Z'
        fill='#727785'
      />
      <path
        d='M69.6347 231.973C69.5435 232.23 69.6988 232.521 69.7848 232.656L69.8126 232.64C69.6128 232.376 69.5865 232.113 69.6347 231.973C69.6363 231.968 69.6379 231.964 69.6396 231.96C69.6379 231.964 69.6363 231.968 69.6347 231.973Z'
        fill='#727785'
      />
      <path
        d='M70.4971 232.074C70.3623 232.311 70.0403 232.383 69.8819 232.406L69.876 232.375C70.2063 232.354 70.4227 232.202 70.4971 232.074C70.4994 232.07 70.5017 232.066 70.5039 232.062C70.5018 232.066 70.4995 232.07 70.4971 232.074Z'
        fill='#727785'
      />
      <path
        d='M70.5403 232.591C70.353 232.935 69.9209 233.049 69.7085 233.087L69.7016 233.042C70.1434 233.001 70.4371 232.777 70.5403 232.591C70.5436 232.585 70.5467 232.579 70.5498 232.573C70.5468 232.579 70.5437 232.585 70.5403 232.591Z'
        fill='#727785'
      />
      <path
        d='M60.4654 232.002C60.4486 231.708 60.352 231.407 60.1221 231.186C60.6982 231.253 60.9046 231.429 61.1239 231.684C61.6539 232.3 61.285 232.966 60.9554 232.826C60.6257 232.686 60.4828 232.306 60.4654 232.002Z'
        fill='#E5E6ED'
      />
      <path
        d='M60.4737 231.371C61.0531 231.718 61.2152 232.52 61.2638 232.914L61.1851 232.927C61.1511 232.107 60.7865 231.562 60.4737 231.371C60.4637 231.365 60.4536 231.359 60.4434 231.353C60.4534 231.359 60.4635 231.364 60.4737 231.371Z'
        fill='#727785'
      />
      <path
        d='M61.0057 231.629C61.0948 231.762 61.0483 231.951 61.0188 232.04L61.0006 232.035C61.0764 231.856 61.0534 231.702 61.0057 231.629C61.0042 231.627 61.0026 231.625 61.001 231.622C61.0026 231.624 61.0042 231.627 61.0057 231.629Z'
        fill='#727785'
      />
      <path
        d='M60.7043 231.713C60.7478 231.79 60.8459 231.814 60.8941 231.821L60.8955 231.811C60.7955 231.804 60.7283 231.754 60.7043 231.713C60.7036 231.712 60.7029 231.71 60.7021 231.709C60.7028 231.71 60.7036 231.712 60.7043 231.713Z'
        fill='#727785'
      />
      <path
        d='M61.2186 231.937C61.2704 232.089 61.1769 232.259 61.1253 232.338L61.1091 232.328C61.2286 232.174 61.246 232.02 61.2186 231.937C61.2177 231.935 61.2168 231.932 61.2158 231.929C61.2168 231.932 61.2177 231.935 61.2186 231.937Z'
        fill='#727785'
      />
      <path
        d='M60.7109 231.99C60.7886 232.131 60.9774 232.176 61.0705 232.191L61.0742 232.172C60.88 232.157 60.7538 232.066 60.7109 231.99C60.7096 231.988 60.7083 231.986 60.707 231.983C60.7082 231.986 60.7096 231.988 60.7109 231.99Z'
        fill='#727785'
      />
      <path
        d='M60.6812 232.295C60.7889 232.498 61.0423 232.568 61.167 232.592L61.1714 232.566C60.9118 232.539 60.7406 232.404 60.6812 232.295C60.6794 232.291 60.6776 232.287 60.6758 232.284C60.6775 232.287 60.6793 232.291 60.6812 232.295Z'
        fill='#727785'
      />
      <path
        d='M78.7592 235.47C78.776 235.177 78.8727 234.875 79.1025 234.654C78.5264 234.721 78.32 234.898 78.1007 235.152C77.5707 235.768 77.9396 236.434 78.2693 236.294C78.5989 236.154 78.7418 235.774 78.7592 235.47Z'
        fill='#E5E6ED'
      />
      <path
        d='M78.7509 234.839C78.1715 235.186 78.0094 235.988 77.9608 236.383L78.0395 236.395C78.0735 235.575 78.4381 235.03 78.7509 234.839C78.7609 234.833 78.771 234.827 78.7812 234.821C78.7712 234.827 78.7611 234.833 78.7509 234.839Z'
        fill='#727785'
      />
      <path
        d='M78.2189 235.097C78.1298 235.231 78.1763 235.419 78.2059 235.509L78.224 235.503C78.1482 235.324 78.1712 235.17 78.2189 235.097C78.2204 235.095 78.222 235.093 78.2236 235.091C78.222 235.093 78.2205 235.095 78.2189 235.097Z'
        fill='#727785'
      />
      <path
        d='M78.5203 235.181C78.4768 235.258 78.3787 235.282 78.3305 235.289L78.3291 235.279C78.4292 235.272 78.4963 235.223 78.5203 235.181C78.521 235.18 78.5217 235.179 78.5225 235.177C78.5218 235.179 78.521 235.18 78.5203 235.181Z'
        fill='#727785'
      />
      <path
        d='M78.006 235.405C77.9542 235.557 78.0477 235.727 78.0993 235.806L78.1155 235.797C77.996 235.643 77.9786 235.488 78.006 235.405C78.0069 235.403 78.0078 235.4 78.0088 235.398C78.0078 235.4 78.0069 235.403 78.006 235.405Z'
        fill='#727785'
      />
      <path
        d='M78.5137 235.459C78.4361 235.599 78.2472 235.644 78.1541 235.659L78.1504 235.64C78.3446 235.625 78.4708 235.534 78.5137 235.459C78.515 235.456 78.5163 235.454 78.5176 235.451C78.5164 235.454 78.5151 235.456 78.5137 235.459Z'
        fill='#727785'
      />
      <path
        d='M78.5434 235.763C78.4357 235.966 78.1823 236.037 78.0576 236.06L78.0532 236.034C78.3128 236.007 78.484 235.873 78.5434 235.763C78.5452 235.759 78.5471 235.756 78.5488 235.752C78.5471 235.756 78.5453 235.759 78.5434 235.763Z'
        fill='#727785'
      />
      <path
        d='M116.59 247H105.976C93.6784 247 98.984 243.501 102.095 242.823C95.141 240.448 100.265 235.974 103.78 237.84C101.584 229.698 109.087 231.394 110.551 235.974C113.479 231.225 118.603 233.599 115.492 238.688C120.799 236.483 120.799 240.893 118.603 242.25C123.909 243.099 120.943 244.689 118.016 244.689C121.493 246.555 118.931 247 116.59 247Z'
        fill='#EDEEF2'
      />
      <path
        d='M122.477 246.505H117.057C110.777 246.505 113.487 244.639 115.075 244.277C111.524 243.01 114.141 240.624 115.936 241.619C114.814 237.277 118.646 238.181 119.393 240.624C120.888 238.091 123.505 239.358 121.916 242.072C124.626 240.896 124.626 243.248 123.505 243.971C126.215 244.424 124.7 245.272 123.205 245.272C124.98 246.267 123.672 246.505 122.477 246.505Z'
        fill='#DFE0E6'
      />
      <path
        d='M98.2465 234.448C97.9587 234.04 97.5483 233.692 97.0183 233.58C97.9099 233.159 98.3659 233.228 98.9112 233.398C100.229 233.811 100.295 235.102 99.6938 235.197C99.0925 235.291 98.5447 234.871 98.2465 234.448Z'
        fill='#E5E6ED'
      />
      <path
        d='M97.6914 233.531C98.8394 233.511 99.7939 234.523 100.218 235.048L100.116 235.137C99.3303 233.984 98.3147 233.526 97.6914 233.531C97.6716 233.531 97.6518 233.531 97.6318 233.532C97.6513 233.531 97.6712 233.531 97.6914 233.531Z'
        fill='#727785'
      />
      <path
        d='M98.6907 233.425C98.939 233.538 99.0412 233.851 99.0791 234.007L99.0482 234.016C98.9964 233.689 98.8249 233.488 98.6907 233.425C98.6864 233.423 98.6821 233.422 98.6777 233.42C98.682 233.421 98.6864 233.423 98.6907 233.425Z'
        fill='#727785'
      />
      <path
        d='M98.332 233.817C98.4635 233.888 98.6266 233.835 98.703 233.802L98.6959 233.787C98.5449 233.866 98.4037 233.855 98.332 233.817C98.3297 233.816 98.3274 233.814 98.3252 233.813C98.3274 233.814 98.3296 233.815 98.332 233.817Z'
        fill='#727785'
      />
      <path
        d='M99.2757 233.678C99.4867 233.85 99.5048 234.18 99.5012 234.34L99.4691 234.341C99.5032 234.011 99.3894 233.773 99.2757 233.678C99.2721 233.675 99.2684 233.672 99.2646 233.669C99.2683 233.672 99.272 233.675 99.2757 233.678Z'
        fill='#727785'
      />
      <path
        d='M98.5914 234.211C98.8293 234.344 99.1423 234.239 99.2898 234.177L99.2785 234.147C98.985 234.3 98.7212 234.282 98.5914 234.211C98.5872 234.209 98.5832 234.206 98.5791 234.204C98.583 234.206 98.5871 234.209 98.5914 234.211Z'
        fill='#727785'
      />
      <path
        d='M98.8211 234.676C99.1594 234.873 99.5881 234.747 99.7894 234.669L99.772 234.627C99.3731 234.821 99.0056 234.782 98.8211 234.676C98.8153 234.673 98.8095 234.669 98.8037 234.666C98.8093 234.669 98.8151 234.673 98.8211 234.676Z'
        fill='#727785'
      />
      <path
        d='M122.979 237.916C123.267 237.508 123.677 237.16 124.207 237.048C123.316 236.627 122.86 236.696 122.314 236.867C120.997 237.279 120.931 238.571 121.532 238.665C122.133 238.759 122.681 238.339 122.979 237.916Z'
        fill='#E5E6ED'
      />
      <path
        d='M123.534 236.999C122.386 236.979 121.432 237.991 121.007 238.516L121.109 238.605C121.895 237.452 122.911 236.994 123.534 236.999C123.554 236.999 123.574 237 123.594 237.001C123.574 237 123.554 236.999 123.534 236.999Z'
        fill='#727785'
      />
      <path
        d='M122.535 236.894C122.287 237.006 122.184 237.32 122.147 237.475L122.177 237.484C122.229 237.157 122.401 236.956 122.535 236.894C122.539 236.892 122.543 236.89 122.548 236.888C122.544 236.89 122.539 236.892 122.535 236.894Z'
        fill='#727785'
      />
      <path
        d='M122.894 237.285C122.762 237.356 122.599 237.303 122.523 237.271L122.53 237.255C122.681 237.334 122.822 237.323 122.894 237.285C122.896 237.284 122.898 237.282 122.9 237.281C122.898 237.282 122.896 237.284 122.894 237.285Z'
        fill='#727785'
      />
      <path
        d='M121.95 237.146C121.739 237.319 121.721 237.648 121.724 237.808L121.757 237.809C121.722 237.48 121.836 237.241 121.95 237.146C121.953 237.143 121.957 237.14 121.961 237.138C121.957 237.14 121.954 237.143 121.95 237.146Z'
        fill='#727785'
      />
      <path
        d='M122.634 237.679C122.396 237.812 122.083 237.707 121.936 237.645L121.947 237.615C122.241 237.768 122.504 237.75 122.634 237.679C122.638 237.677 122.642 237.675 122.646 237.672C122.643 237.675 122.638 237.677 122.634 237.679Z'
        fill='#727785'
      />
      <path
        d='M122.404 238.145C122.066 238.341 121.638 238.215 121.436 238.138L121.454 238.095C121.852 238.29 122.22 238.25 122.404 238.145C122.41 238.141 122.416 238.138 122.422 238.134C122.416 238.138 122.41 238.141 122.404 238.145Z'
        fill='#727785'
      />
      <path
        d='M111.914 232.098C111.949 231.6 112.12 231.09 112.515 230.719C111.534 230.821 111.18 231.116 110.802 231.545C109.888 232.58 110.501 233.719 111.064 233.488C111.627 233.258 111.878 232.614 111.914 232.098Z'
        fill='#E5E6ED'
      />
      <path
        d='M111.914 231.026C110.922 231.604 110.63 232.964 110.539 233.632L110.672 233.656C110.747 232.263 111.378 231.345 111.914 231.026C111.931 231.016 111.948 231.006 111.966 230.997C111.949 231.006 111.931 231.015 111.914 231.026Z'
        fill='#727785'
      />
      <path
        d='M111.005 231.454C110.85 231.678 110.925 232 110.974 232.152L111.005 232.144C110.88 231.837 110.922 231.576 111.005 231.454C111.007 231.45 111.01 231.446 111.013 231.442C111.01 231.446 111.007 231.45 111.005 231.454Z'
        fill='#727785'
      />
      <path
        d='M111.514 231.603C111.438 231.732 111.271 231.77 111.189 231.783L111.187 231.765C111.357 231.755 111.472 231.672 111.514 231.603C111.515 231.6 111.516 231.598 111.518 231.596C111.516 231.598 111.515 231.6 111.514 231.603Z'
        fill='#727785'
      />
      <path
        d='M110.635 231.973C110.544 232.23 110.699 232.521 110.785 232.656L110.813 232.64C110.613 232.376 110.586 232.113 110.635 231.973C110.636 231.968 110.638 231.964 110.64 231.96C110.638 231.964 110.636 231.968 110.635 231.973Z'
        fill='#727785'
      />
      <path
        d='M111.497 232.074C111.362 232.311 111.04 232.383 110.882 232.406L110.876 232.375C111.206 232.354 111.423 232.202 111.497 232.074C111.499 232.07 111.502 232.066 111.504 232.062C111.502 232.066 111.5 232.07 111.497 232.074Z'
        fill='#727785'
      />
      <path
        d='M111.54 232.591C111.353 232.935 110.921 233.049 110.709 233.087L110.702 233.042C111.143 233.001 111.437 232.777 111.54 232.591C111.544 232.585 111.547 232.579 111.55 232.573C111.547 232.579 111.544 232.585 111.54 232.591Z'
        fill='#727785'
      />
      <path
        d='M101.465 232.002C101.449 231.708 101.352 231.407 101.122 231.186C101.698 231.253 101.905 231.429 102.124 231.684C102.654 232.3 102.285 232.966 101.955 232.826C101.626 232.686 101.483 232.306 101.465 232.002Z'
        fill='#E5E6ED'
      />
      <path
        d='M101.474 231.371C102.053 231.718 102.215 232.52 102.264 232.914L102.185 232.927C102.151 232.107 101.787 231.562 101.474 231.371C101.464 231.365 101.454 231.359 101.443 231.353C101.453 231.359 101.464 231.364 101.474 231.371Z'
        fill='#727785'
      />
      <path
        d='M102.006 231.629C102.095 231.762 102.048 231.951 102.019 232.04L102.001 232.035C102.076 231.856 102.053 231.702 102.006 231.629C102.004 231.627 102.003 231.625 102.001 231.622C102.003 231.624 102.004 231.627 102.006 231.629Z'
        fill='#727785'
      />
      <path
        d='M101.704 231.713C101.748 231.79 101.846 231.814 101.894 231.821L101.896 231.811C101.795 231.804 101.728 231.754 101.704 231.713C101.704 231.712 101.703 231.71 101.702 231.709C101.703 231.71 101.704 231.712 101.704 231.713Z'
        fill='#727785'
      />
      <path
        d='M102.219 231.937C102.27 232.089 102.177 232.259 102.125 232.338L102.109 232.328C102.229 232.174 102.246 232.02 102.219 231.937C102.218 231.935 102.217 231.932 102.216 231.929C102.217 231.932 102.218 231.935 102.219 231.937Z'
        fill='#727785'
      />
      <path
        d='M101.711 231.99C101.789 232.131 101.977 232.176 102.07 232.191L102.074 232.172C101.88 232.157 101.754 232.066 101.711 231.99C101.71 231.988 101.708 231.986 101.707 231.983C101.708 231.986 101.71 231.988 101.711 231.99Z'
        fill='#727785'
      />
      <path
        d='M101.681 232.295C101.789 232.498 102.042 232.568 102.167 232.592L102.171 232.566C101.912 232.539 101.741 232.404 101.681 232.295C101.679 232.291 101.678 232.287 101.676 232.284C101.677 232.287 101.679 232.291 101.681 232.295Z'
        fill='#727785'
      />
      <path
        d='M119.759 235.47C119.776 235.177 119.873 234.875 120.103 234.654C119.526 234.721 119.32 234.898 119.101 235.152C118.571 235.768 118.94 236.434 119.269 236.294C119.599 236.154 119.742 235.774 119.759 235.47Z'
        fill='#E5E6ED'
      />
      <path
        d='M119.751 234.839C119.172 235.186 119.009 235.988 118.961 236.383L119.039 236.395C119.073 235.575 119.438 235.03 119.751 234.839C119.761 234.833 119.771 234.827 119.781 234.821C119.771 234.827 119.761 234.833 119.751 234.839Z'
        fill='#727785'
      />
      <path
        d='M119.219 235.097C119.13 235.231 119.176 235.419 119.206 235.509L119.224 235.503C119.148 235.324 119.171 235.17 119.219 235.097C119.22 235.095 119.222 235.093 119.224 235.091C119.222 235.093 119.22 235.095 119.219 235.097Z'
        fill='#727785'
      />
      <path
        d='M119.52 235.181C119.477 235.258 119.379 235.282 119.33 235.289L119.329 235.279C119.429 235.272 119.496 235.223 119.52 235.181C119.521 235.18 119.522 235.179 119.522 235.177C119.522 235.179 119.521 235.18 119.52 235.181Z'
        fill='#727785'
      />
      <path
        d='M119.006 235.405C118.954 235.557 119.048 235.727 119.099 235.806L119.116 235.797C118.996 235.643 118.979 235.488 119.006 235.405C119.007 235.403 119.008 235.4 119.009 235.398C119.008 235.4 119.007 235.403 119.006 235.405Z'
        fill='#727785'
      />
      <path
        d='M119.514 235.459C119.436 235.599 119.247 235.644 119.154 235.659L119.15 235.64C119.345 235.625 119.471 235.534 119.514 235.459C119.515 235.456 119.516 235.454 119.518 235.451C119.516 235.454 119.515 235.456 119.514 235.459Z'
        fill='#727785'
      />
      <path
        d='M119.543 235.763C119.436 235.966 119.182 236.037 119.058 236.06L119.053 236.034C119.313 236.007 119.484 235.873 119.543 235.763C119.545 235.759 119.547 235.756 119.549 235.752C119.547 235.756 119.545 235.759 119.543 235.763Z'
        fill='#727785'
      />
      <rect x={197} y={116} width={8} height={130} fill='#20242E' />
      <rect x={197} y={116} width={6} height={130} fill='#334680' />
      <path
        d='M226.717 45.5634C230.026 45.5634 233.083 47.3286 234.738 50.1942L259.954 93.8699C261.608 96.7354 261.608 100.266 259.954 103.132L234.738 146.807C233.083 149.673 230.026 151.438 226.717 151.438L176.285 151.438C172.976 151.438 169.918 149.673 168.264 146.807L143.048 103.132C141.393 100.266 141.393 96.7354 143.048 93.8699L168.264 50.1942C169.918 47.3286 172.976 45.5634 176.285 45.5634L226.717 45.5634Z'
        fill='#4E9CF4'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M155.589 124.854C194.159 120.665 224.179 87.9921 224.179 48.308C224.179 47.3893 224.163 46.4743 224.131 45.5634L176.284 45.5634C172.976 45.5634 169.918 47.3286 168.264 50.1942L143.047 93.8699C141.393 96.7355 141.393 100.266 143.047 103.132L155.589 124.854Z'
        fill='white'
        fillOpacity={0.15}
      />
      <path
        d='M229.315 55.5428L251.873 94.6152C253.086 96.7147 253.086 99.3013 251.873 101.401L229.315 140.473C228.103 142.573 225.863 143.866 223.439 143.866L178.322 143.866C175.897 143.866 173.657 142.573 172.445 140.473L149.887 101.401C148.675 99.3013 148.675 96.7147 149.887 94.6152L172.445 55.5428C173.657 53.4433 175.897 52.15 178.322 52.15L223.439 52.15C225.863 52.15 228.103 53.4433 229.315 55.5428Z'
        stroke='white'
        strokeWidth={3}
      />
      <path
        d='M189.026 106.908V102.308H186.786V95.8279H181.546V102.308H176.226L184.106 83.7079H178.866L170.986 102.308V106.908H181.546V111.308H186.786V106.908H189.026ZM194.445 109.388C195.805 110.988 197.885 111.748 200.685 111.748C203.485 111.748 205.525 110.948 206.885 109.348C208.245 107.748 208.925 105.388 208.925 102.228V92.4679C208.925 89.4279 208.245 87.1479 206.885 85.5879C205.525 84.0679 203.485 83.2679 200.685 83.2679C197.965 83.2679 195.925 84.0679 194.525 85.6279C193.125 87.1879 192.445 89.4679 192.445 92.4679V102.228C192.445 105.428 193.085 107.788 194.445 109.388ZM203.045 105.988C202.645 106.788 201.845 107.188 200.685 107.188C199.525 107.188 198.725 106.788 198.325 105.988C197.925 105.188 197.725 103.788 197.725 101.788V93.2279C197.725 91.2679 197.925 89.8679 198.325 89.0679C198.725 88.2679 199.485 87.8679 200.685 87.8679C201.845 87.8679 202.645 88.2679 203.045 89.0679C203.445 89.8679 203.685 91.2679 203.685 93.2279V101.788C203.685 103.788 203.445 105.188 203.045 105.988ZM230.354 106.908V102.308H228.114V95.8279H222.874V102.308H217.554L225.434 83.7079H220.194L212.314 102.308V106.908H222.874V111.308H228.114V106.908H230.354Z'
        fill='white'
      />
      <path
        d='M145.357 170H155.231L177.674 240.02H122.914L145.357 170Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M145.357 170H155.231L164.294 198.277H136.293L145.357 170ZM133.704 206.357L130.395 216.68H170.193L166.884 206.357H133.704ZM127.805 224.76H172.782L177.674 240.02H122.914L127.805 224.76Z'
        fill='#4E9CF4'
      />
      <rect
        x={109}
        y={240.02}
        width={82.5882}
        height={6.28389}
        fill='#4E9CF4'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M175.516 233.288L177.674 240.021H122.914L125.072 233.288H175.516Z'
        fill='black'
        fillOpacity={0.2}
      />
      <mask
        id='mask0_201_7'
        style={{
          maskType: 'alpha',
        }}
        maskUnits='userSpaceOnUse'
        x={109}
        y={170}
        width={83}
        height={77}>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M145.357 170H155.231L164.294 198.277H136.293L145.357 170ZM133.704 206.357L130.395 216.68H170.193L166.884 206.357H133.704ZM127.805 224.76H172.782L177.674 240.02H122.914L127.805 224.76Z'
          fill='#2F63FF'
        />
        <rect
          x={109}
          y={240.02}
          width={82.5882}
          height={6.28389}
          fill='#2F63FF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M175.516 233.288L177.674 240.021H122.914L125.072 233.288H175.516Z'
          fill='black'
          fillOpacity={0.2}
        />
      </mask>
      <g mask='url(#mask0_201_7)'>
        <circle
          cx={98.2278}
          cy={152.944}
          r={83.9348}
          fill='white'
          fillOpacity={0.2}
        />
      </g>
      <path
        d='M2 247H402'
        stroke='#20242E'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default NotFoundIcon;
