import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface IProjectObjectiveItem {
  user_id: string;
  projectObjectiveName: string;
  projectObjectiveDescription: string;
  projectObjectivePriority: string;
  project: string[];
  _id: string;
  createdAt: Date;
  updatedAt: Date;
}

interface IProjectObjective {
  projectObjectives: string[];
  projectObjectiveItem: IProjectObjectiveItem[];
  addProjectObjectives: (id: string) => void;
  addProjectObjectiveItem: (item: IProjectObjectiveItem) => void;
  updateProjectObjectiveItem: (updateItem: IProjectObjectiveItem) => void;
  removeProjectObjectives: (id: string) => void;
  removeProjectObjectivesItem: (id: string) => void;
  clearProjectObjectiveItem: () => void;
  clearProjectObjectiveItemId: () => void;
}

export const useProjectObjectiveStore = create<IProjectObjective>()(
  persist(
    (set, get) => ({
      projectObjectives: [],
      projectObjectiveItem: [],

      addProjectObjectives: (id: string) => {
        set((state) => ({
          projectObjectives: [...state.projectObjectives, id],
        }));
      },

      addProjectObjectiveItem: (item: IProjectObjectiveItem) => {
        set((state) => ({
          projectObjectiveItem: [...state.projectObjectiveItem, item],
        }));
      },
      updateProjectObjectiveItem: (updateItem: IProjectObjectiveItem) => {
        set((state) => ({
          projectObjectiveItem: state.projectObjectiveItem.map((item) =>
            item._id === updateItem._id ? updateItem : item
          ),
        }));
      },

      removeProjectObjectives: (id: string) => {
        set((state) => ({
          projectObjectives: state.projectObjectives.filter(
            (item) => item !== id
          ),
        }));
      },
      removeProjectObjectivesItem: (id: string) => {
        set((state) => ({
          projectObjectiveItem: state.projectObjectiveItem.filter(
            (item) => item._id !== id
          ),
        }));
      },

      clearProjectObjectiveItem: () => {
        set((state) => ({ projectObjectiveItem: [] }));
      },
      clearProjectObjectiveItemId: () => {
        set((state) => ({ projectObjectives: [] }));
      },
    }),
    {
      name: 'projectObjectives-storage',
    }
  )
);
