import { useCreate } from '@refinedev/core';
import { notify, notifyError } from 'components/shared/Toastify';
import FormRow from 'HOC/FormRow';
import React from 'react';
import { useSecurityStore } from 'store/securityDetail.store';

import { useProjectStore } from 'store/projects.store';
import { ISecurity } from 'types/securityTypes';

const AddForm = ({
  setOpenModal,
}: {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const addItemSecurityID = useSecurityStore(
    (state) => state.addSecurityItemID
  );
  const addSecurityItem = useSecurityStore((state) => state.addSecurityItem);

  const projectItemId = useProjectStore((state) => state.projectItemID);
  const initialValues = {
    dataSensitiveItem: '',
    details: '',
    compliance: '',
  };

  const [value, setValue] = React.useState(initialValues);

  const { mutate, isLoading } = useCreate<ISecurity>();

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setValue((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const payload = {
      dataSensitiveItem: value?.dataSensitiveItem,
      details: value?.details,
      compliance: value?.compliance,
      projectId: projectItemId,
    };

    mutate(
      {
        resource: 'security/add-security',
        values: payload,
        invalidates: ['all'],
      },
      {
        onSuccess: (data) => {
          if (data.data) {
            addItemSecurityID(data.data._id);
            addSecurityItem(data.data);
            notify('Security details added');
            setOpenModal(false);
          }
        },
        onError(error) {
          notifyError(error.response.data.errorMsg);
        },
      }
    );
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className='w-[75vw] max-w-[100vw] md:w-[40rem]'>
        <FormRow
          labelText='Data Sensitive Item'
          name='dataSensitiveItem'
          type='text'
          value={value.dataSensitiveItem}
          handleChange={handleChange}
        />
        <FormRow
          name='details'
          type='text'
          value={value.details}
          handleChange={handleChange}
        />
        <FormRow
          name='compliance'
          type='text'
          value={value.compliance}
          handleChange={handleChange}
        />

        <div className='grid place-items-center'>
          <button
            disabled={isLoading}
            type='submit'
            className={
              isLoading
                ? 'cursor-not-allowed w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
                : 'w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
            }>
            {isLoading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    </>
  );
};

export default AddForm;
