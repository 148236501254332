import moment from 'moment';
import { KPIItem } from 'pages/projectList/showSingleProject';
import React from 'react';
import { GrClose } from 'react-icons/gr';

const ViewKKPIDetails = ({
  kpiItem,
  setOpenKPIViewModal,
}: {
  kpiItem: KPIItem | undefined;
  setOpenKPIViewModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className='w-[75vw] max-w-[100vw] md:w-[30vw] '>
      <div className='flex justify-end mb-8'>
        <button
          className='font-semibold'
          onClick={() => setOpenKPIViewModal(false)}
        >
          <GrClose className='text-2xl' />
        </button>
      </div>
      <h4 className='font-semibold text-center text-xl mb-4'>KPI Details</h4>
      <div className='my-6'>
        <span className='font-semibold'>Metric ID:</span>
        <p>{kpiItem?._id}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Metric:</span>
        <p>{kpiItem?.metric}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Metric Definition:</span>
        <p>{kpiItem?.metricDefinition}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Metric Calculation:</span>
        <p>{kpiItem?.metricCalculation}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Reported Frequency:</span>
        <p>{kpiItem?.reportedFrequency}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Metric Calculation:</span>
        <p>{kpiItem?.metricCalculation}</p>
      </div>
      <div className='my-6'>
        <span className='font-semibold'>Comparator:</span>
        <p>{kpiItem?.comparator}</p>
      </div>

      <div className='my-6'>
        <span className='font-semibold'>Dimensionality:</span>
        <p>{kpiItem?.dimensionality}</p>
      </div>

      <div className='my-6'>
        <span className='font-semibold'>Data Source:</span>
        {kpiItem?.dataSource.map((item, index) => {
          return <p key={index}>{item.dataSourceName}</p>;
        })}
      </div>

      <div className='my-6'>
        <span className='font-semibold'>Table:</span>
        <p>{kpiItem?.table}</p>
      </div>

      <div className='my-6'>
        <span className='font-semibold'>Date created:</span>
        <p>{moment(kpiItem?.createdAt).format('DD/MM/YYYY')}</p>
      </div>

      <div className='my-6'>
        <span className='font-semibold'>Last updated:</span>
        <p>{moment(kpiItem?.updatedAt).format('DD/MM/YYYY')}</p>
      </div>
    </div>
  );
};

export default ViewKKPIDetails;
