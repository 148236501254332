import { useMany } from '@refinedev/core';
import { useProjectObjectiveStore } from 'store/projectObjectives.store';
import { useProjectStore } from 'store/projects.store';
import { filterConfirmationData } from 'utils/filterConfirmationData';

const ProjectObjective = () => {
  const projectItemId = useProjectObjectiveStore(
    (state) => state.projectObjectives
  );

  const { data: projectObjectivesItem } = useMany({
    resource: 'project-objective/get-multiple-project-objective',
    ids: projectItemId,
  });

  const projectId = useProjectStore((state) => state.projectItemID);

  const filteredProjectObjectives = filterConfirmationData(
    projectObjectivesItem,
    projectId
  );

  return (
    <div>
      <div className=' uppercase bg-white p-4 rounded-xl font-bold'>
        Project Objective
      </div>
      <table className='w-full text-sm text-left text-gray-500 '>
        <thead className='uppercase font-bold bg-none border-b-2 border-white'>
          <tr>
            <th scope='col' className='px-6 py-6'>
              Objective Name
            </th>
            <th scope='col' className='px-6 py-6'>
              Objective Description
            </th>
            <th scope='col' className='px-6 py-6'>
              Objective Priority
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredProjectObjectives?.map((item, index: number) => {
            const {
              projectObjectiveName,
              projectObjectiveDescription,
              projectObjectivePriority,
            } = item;

            return (
              <tr
                key={index}
                className='bg-none border-b-2 border-white hover:bg-white '
              >
                <td className='px-6 py-4'>{projectObjectiveName}</td>
                <td className='px-6 py-4'>{projectObjectiveDescription}</td>
                <td className='px-6 py-4'>{projectObjectivePriority}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectObjective;
