import { useGetIdentity, useOne, useUpdate } from '@refinedev/core';
import ImageIcon from 'assets/SVG/imageIcon';
import FormRow from 'HOC/FormRow';

import React, { ChangeEvent, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
// import 'react-phone-input-2/lib/bootstrap.css';

import { useUserStore } from 'store/user.store';
import { Identity } from 'types/user.types';
import { notify, notifyError } from 'components/shared/Toastify';
// import { useForm } from '@refinedev/react-hook-form';
// import { axiosInstance } from 'rest-data-provider/utils';

export type UserValue = {
  data: {
    data: {
      fullName: string;
      email: string;
      phoneNumber: string;
      bio: string;
      profileImage: string;
    };
  };
};

const AccountSettings = () => {
  const [userData, setUserData] = useState();
  const { data: userIdentity } = useGetIdentity<Identity>();
  const addUser = useUserStore((state) => state.addUser);
  const userValue = useUserStore((state) => state.user);
  // const apiURL = process.env.REACT_APP_SERVER_API_URL;
  const { data: user } = useOne({
    resource: 'user/get-current-user',
    id: userIdentity?.id,
  });
  const { mutate: updateUser } = useUpdate();

  const initialValues = {
    fullName: userValue.fullName,
    email: userValue.email,
    phoneNumber: userValue.phoneNumber,
    bio: '',
  };

  const [value, setValue] = React.useState(initialValues);
  const [imageFile, setImageFile] = React.useState<FileList | null>(null);
  const [profileImage, setProfileImage] = useState(user?.data?.profileImage);
  const [imageData, setImageData] = useState<FileList | null>(null);

  useEffect(() => {
    if (user?.data) {
      const payload = {
        fullName: user?.data?.fullName,
        email: user?.data?.email,
        phoneNumber: user?.data?.phoneNumber,
        bio: user?.data?.bio,
        profileImage: user?.data?.profileImage,
      };
      setProfileImage(user?.data?.profileImage);
      addUser(payload);
    }
  }, [user]);

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setValue((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    setImageFile(files);
    const dataFile = files![0];

    const reader = new FileReader();
    setImageData(files);

    reader.readAsDataURL(dataFile);
    reader.onloadend = () => {
      setProfileImage(reader.result as string);
    };
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const form = new FormData();
    if (imageFile !== null) {
      for (const files of imageFile) {
        form.append('profilePhoto', files);
      }
    }
    form.append('fullName', value?.fullName);
    // form.append('email', value?.email);
    form.append('phoneNumber', value?.phoneNumber);
    form.append('bio', value?.bio);

    updateUser(
      {
        resource: 'user/update-user',
        values: form,
        id: user?.data._id,
        invalidates: ['all'],
      },
      {
        onSuccess(data) {
          if (data) {
            notify('Account updated successfully');
          }
        },
        onError(error) {
          notifyError(error.response.data.errorMsg);
        },
      }
    );
  };

  return (
    <>
      <div className='flex justify-center md:justify-start'>
        {profileImage ? (
          <div className='flex flex-col items-center'>
            <img
              className='w-24 h-24 rounded-full'
              src={profileImage}
              alt={user?.data.fullName}
            />
            <label
              htmlFor='change-profile'
              className='flex flex-col items-center justify-center w-fit h-fit p-4 mt-4 '>
              <p className='text-gray-500 cursor-pointer'>Change Profile</p>
              <input
                type='file'
                id='change-profile'
                accept='image/png, image/jpeg'
                className='hidden'
                onChange={handleImageChange}
              />
            </label>
          </div>
        ) : (
          <label
            htmlFor='upload-profile'
            className='flex flex-col items-center justify-center w-fit h-fit p-4 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50'>
            <ImageIcon />
            <p className='my-2 text-sm text-gray-500'>
              <span className='font-semibold'>Upload your photo</span>
            </p>
            <p className='text-xs text-gray-500 '>PNG or JPG</p>
            <input
              type='file'
              id='upload-profile'
              accept='image/png, image/jpeg'
              className='hidden'
              onChange={handleImageChange}
            />
          </label>
        )}
      </div>
      <hr className='my-8 border-2' />
      <form onSubmit={handleSubmit}>
        <div className='md:grid md:grid-cols-2 gap-4'>
          <FormRow
            labelText='Full Name'
            name='fullName'
            type='text'
            placeholder='Please enter your full name'
            value={value.fullName}
            handleChange={handleChange}
          />
          <FormRow
            labelText='Email'
            name='email'
            type='email'
            placeholder='Please enter your email'
            value={value.email}
            disabled
            handleChange={handleChange}
          />

          {/* <FormRow
            labelText='Phone Number'
            name='phoneNumber'
            type='number'
            placeholder='Enter your phone number'
            value={value.phoneNumber}
            handleChange={handleChange}
          /> */}
          <PhoneInput
            country='us'
            enableSearch={true}
            value={value.phoneNumber}
            onChange={(value) =>
              setValue((prevValue) => ({
                ...prevValue,
                phoneNumber: value,
              }))
            }
          />
        </div>
        <div className='mt-8'>
          <FormRow
            labelText='Bio'
            name='bio'
            textArea='textArea'
            handleChange={handleChange}
            value={value?.bio}
          />
        </div>
        <button type='submit' className='p-3 text-white bg-blue-400 rounded-lg'>
          Update Profile
        </button>
      </form>
    </>
  );
};

export default AccountSettings;
