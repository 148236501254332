import { BaseKey, BaseRecord, GetOneResponse } from '@refinedev/core';
import { UserStoriesItemTypes } from 'pages/projectList/showSingleProject';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface IUserStoryProps {
  projects: GetOneResponse<BaseRecord> | undefined;
  setUserStoriesItem: React.Dispatch<
    React.SetStateAction<UserStoriesItemTypes | undefined>
  >;
  setOpenUserStoriesViewModal: React.Dispatch<boolean>;
  setEditUserStoriesItem: React.Dispatch<
    React.SetStateAction<UserStoriesItemTypes | undefined>
  >;
  setOpenUserStoriesEditModal: React.Dispatch<boolean>;
  addProjectItemID: (id: string) => void;
  id: BaseKey | undefined;
  deleteProjectItem: (id: string, resource: string) => void;
  clearProjectStorage: () => void;
  navigateResource: string;
}

const UserStorySection: FC<IUserStoryProps> = ({
  projects,
  setUserStoriesItem,
  setOpenUserStoriesViewModal,
  setEditUserStoriesItem,
  setOpenUserStoriesEditModal,
  id,
  addProjectItemID,
  deleteProjectItem,
  clearProjectStorage,
  navigateResource,
}) => {
  const navigate = useNavigate();
  return (
    <div className='p-4 bg-white board-bg rounded-lg h-fit w-full'>
      <div className='w-64'>
        <h4 className='text-xl font-bold'>User Story</h4>
        {projects?.data.userStories.length > 0 ? (
          projects?.data.userStories.map(
            (
              item: {
                _id: string;
                story: string;
                intendedAudience: string;
                storyDetails: string;
                priority: string;
              },
              index: number
            ) => {
              const { story, _id: id, priority } = item;

              const resource = 'user-story';
              return (
                <div key={index} className='mt-4 bg-white p-3 rounded-lg'>
                  <div>
                    <div className='flex justify-between gap-8'>
                      <div>
                        <span className='font-semibold'>User Story:</span>
                        <p>{story}</p>
                      </div>

                      <div className='flex flex-col'>
                        <p className='font-semibold'>Priority</p>
                        <span
                          className={
                            priority === 'High'
                              ? 'font-bold text-red-300'
                              : priority === 'Medium'
                              ? 'font-bold text-yellow-300'
                              : priority === 'Low'
                              ? 'font-bold text-green-300'
                              : ''
                          }>
                          {priority}
                        </span>
                      </div>
                    </div>

                    <div className='flex justify-end gap-3 mt-8'>
                      <button
                        onClick={() => {
                          setUserStoriesItem(item);
                          setOpenUserStoriesViewModal(true);
                        }}
                        className='p-2 rounded-lg text-white bg-blue-400 w-fit text-xs'>
                        View Details
                      </button>
                      <button
                        onClick={() => {
                          setEditUserStoriesItem(item);
                          setOpenUserStoriesEditModal(true);
                        }}
                        className='p-2 rounded-lg text-white bg-blue-400 w-fit'>
                        Edit
                      </button>
                      <button
                        onClick={() => deleteProjectItem(id, resource)}
                        className='p-2 rounded-lg text-white bg-pink-500 w-fit'>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              );
            }
          )
        ) : (
          <div className='mt-4 bg-white p-3 rounded-lg '>
            <div className='w-fit h-fit m-auto'>
              <button
                onClick={() => {
                  addProjectItemID(id as string);
                  clearProjectStorage();
                  navigate(navigateResource);
                }}>
                Create New Data
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserStorySection;
