import NotFoundIcon from 'assets/SVG/NotFoundIcon';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='flex flex-col justify-center items-center mt-24'>
      <div className='mb-4'>
        <NotFoundIcon />
      </div>
      <div className='text-center'>
        <h4 className='font-bold text-4xl'>We are Sorry...</h4>
        <p className='mt-4'>The page you visited does not exist.</p>
        <div className='mt-8'>
          <Link to='/' className='p-4 bg-blue-400 rounded-lg text-white'>
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
