import FormRow from 'HOC/FormRow';
import { DatasourceItem } from 'pages/projectList/showSingleProject';
import { useUpdate, useInvalidate } from '@refinedev/core';
import React from 'react';
import { GrClose } from 'react-icons/gr';

const EditDataSource = ({
  setOpenDatasourceEditModal,
  editDataSourceItem,
}: {
  setOpenDatasourceEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  editDataSourceItem: DatasourceItem | undefined;
}) => {
  const initialValues = {
    id: editDataSourceItem?._id,
    dataSourceType: editDataSourceItem?.dataSourceType,
    dataSourceSubType: editDataSourceItem?.dataSourceSubType,
    dataSourceName: editDataSourceItem?.dataSourceName,
    estimateVolumeInGB: editDataSourceItem?.estimateVolumeInGB,
  };

  const [value, setValue] = React.useState(initialValues);
  const [checked, setchecked] = React.useState(false);
  const [otherDataSourceType, setOtherDataSourceType] =
    React.useState<string>('');
  const [otherDataSourceSubType, setOtherDataSourceSubType] =
    React.useState<string>('');

  const { mutate, isLoading, data } = useUpdate();
  const ivalidate = useInvalidate();

  const handleChange = (e: { target: { name: string; value: string } }) => {
    setValue((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const payload = {
      dataSourceType:
        value?.dataSourceType === 'other'
          ? otherDataSourceType
          : value?.dataSourceType,
      dataSourceSubType:
        value?.dataSourceSubType === 'other'
          ? otherDataSourceSubType
          : value?.dataSourceSubType,

      dataSourceName: value?.dataSourceName,
      estimateVolumeInGB: value?.estimateVolumeInGB,
    };
    const id = value.id as string;

    mutate(
      {
        resource: 'datasource',
        values: payload,
        id: id,
        invalidates: ['resourceAll'],
      },
      {
        onSuccess: (data) => {
          if (data.data) {
            ivalidate({
              resource: 'project-objective',
              invalidates: ['all'],
            });
            setOpenDatasourceEditModal(false);
          }
        },
      }
    );
  };
  return (
    <div>
      <div className='flex justify-end mb-8'>
        <button
          className='font-semibold'
          onClick={() => setOpenDatasourceEditModal(false)}>
          <GrClose className='text-2xl' />
        </button>
      </div>
      <form
        onSubmit={handleSubmit}
        className='w-[75vw] max-w-[100vw] md:w-[40rem]'>
        <FormRow
          labelText='Data Source Name'
          name='dataSourceName'
          type='text'
          value={value.dataSourceName}
          handleChange={handleChange}
        />

        <label htmlFor='dataSourceType' className='capitalize'>
          Data Source Type
        </label>
        {value.dataSourceType === 'other' ? (
          <input
            type='text'
            name='dataSourceType'
            value={otherDataSourceType}
            onChange={(e) => {
              setOtherDataSourceType(e.target.value);
            }}
            placeholder='Other Please Specify'
            className='outline-none border-[1px] border-slate-400  p-2 mb-8 rounded-xl mt-4'
          />
        ) : (
          <select
            id='dataSourceType'
            name='dataSourceType'
            value={value.dataSourceType}
            onChange={(e) => {
              setValue((prevValue) => ({
                ...prevValue,
                dataSourceType: e.target.value,
              }));
            }}
            className='w-full outline-none border-[1px] border-slate-400 rounded-xl p-2 mb-8 md:rounded-xl mt-4'>
            <option value=''>Select Data Source Type</option>
            <option value='Database'>DataBase</option>
            <option value='Flat-file'>Flat file</option>
            <option value='Web-Services'>Web Services</option>
            <option value='other'>Other</option>
          </select>
        )}

        <label htmlFor='dataSourceSubType' className='capitalize'>
          Data Source Sub Type
        </label>

        {value.dataSourceSubType === 'other' ? (
          <input
            type='text'
            name='dataSourceType'
            value={otherDataSourceSubType}
            onChange={(e) => {
              setOtherDataSourceSubType(e.target.value);
            }}
            placeholder='Other Please Specify'
            className='outline-none border-[1px] border-slate-400  p-2 mb-8 rounded-xl mt-4'
          />
        ) : (
          <select
            id='dataSourceSubType'
            name='dataSourceSubType'
            value={value.dataSourceSubType}
            onChange={(e) => {
              setValue((prevValue) => ({
                ...prevValue,
                dataSourceSubType: e.target.value,
              }));
            }}
            className='w-full outline-none border-[1px] border-slate-400 rounded-xl p-2 mb-8 md:rounded-xl mt-4'>
            {value.dataSourceType === 'Database' && (
              <>
                <option value='MS-SQL-On-Prem'>MS SQL - On Prem</option>
                <option value='MS-SQL-Cloud'>MS SQL - Cloud</option>
                <option value='PostgreSQL-On-Prem'>Postgres - On Prem</option>
                <option value='Big-Query'>Big Query</option>
                <option value='Synapse'>Synapse</option>
                <option value='RedShift'>RedShift</option>
                <option value='Snowflake'>Snowflake</option>
              </>
            )}

            {value.dataSourceType === 'Flat-file' && (
              <>
                <option value='CSV'>CSV</option>
                <option value='Excel'>Excel</option>
                <option value='Text-file'>Text file</option>
                <option value='Data-Lake'>Data Lake/Parquet</option>
              </>
            )}

            {value.dataSourceType === 'Web-Services' && (
              <>
                <option value='API-Endpoint'>API Endpoint</option>
                <option value='Share-point-list'>Share point list</option>
              </>
            )}

            <option value='other'>Other</option>
          </select>
        )}

        {/* {checked ? (
            <FormRow
              labelText='Other - Please Specify'
              name='other'
              type='text'
              value={value.other}
              handleChange={handleChange}
            />
          ) : null} */}

        <FormRow
          labelText='Estimate Volume In GB'
          name='estimateVolumeInGB'
          type='number'
          value={value.estimateVolumeInGB}
          handleChange={handleChange}
        />

        <div className='grid place-items-center'>
          <button
            type='submit'
            className={
              isLoading
                ? 'cursor-not-allowed w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
                : 'w-28 h-fit bg-blue-400 text-white p-3 rounded-md'
            }>
            {isLoading ? 'Updating...' : 'Update'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditDataSource;
