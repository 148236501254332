import { HttpError } from '@refinedev/core';

// "axios" package should be installed to customize the http client
import axios from 'axios';
import { initFirebase } from '../../config/firebase';
import { getAuth } from 'firebase/auth';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    initFirebase();
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();
    if (token) {
      config.headers!['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

export { axiosInstance };
