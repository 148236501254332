import { useMany } from '@refinedev/core';
import { useKPIStore } from 'store/kpi.store';
import { useProjectStore } from 'store/projects.store';
import { filterConfirmationData } from 'utils/filterConfirmationData';

const KPI = () => {
  const kpiItemID = useKPIStore((state) => state.kpiItemID);

  const projectItemId = useProjectStore((state) => state.projectItemID);

  const { data } = useMany({
    resource: 'kpi/get-multiple-kpi',
    ids: kpiItemID,
  });

  const projectKpi = filterConfirmationData(data, projectItemId);

  return (
    <div>
      <div className=' uppercase bg-white p-4 rounded-xl font-bold'>
        Key Performance Indicator
      </div>

      <table className='w-full text-sm text-left text-gray-500 '>
        <thead className='uppercase font-bold bg-none border-b-2 border-white'>
          <tr>
            <th scope='col' className='px-6 py-6'>
              Metric
            </th>
            <th scope='col' className='px-6 py-6'>
              Metric Definition
            </th>
            <th scope='col' className='px-6 py-6'>
              Metric Calculation
            </th>
            <th scope='col' className='px-6 py-6'>
              Reported Frequency
            </th>
            <th scope='col' className='px-6 py-6'>
              Comparator
            </th>
            <th scope='col' className='px-6 py-6'>
              Data Source
            </th>
            <th scope='col' className='px-6 py-6'>
              Table
            </th>
            <th scope='col' className='px-6 py-6'>
              Dimentionality
            </th>
          </tr>
        </thead>
        <tbody>
          {projectKpi?.map((item, index: number) => {
            const {
              _id,
              reportedFrequency,
              comparator,
              dataSource,
              dimensionality,
              metric,
              metricCalculation,
              metricDefinition,
              table,
            } = item;

            return (
              <tr
                key={index}
                className='bg-none border-b-2 border-white hover:bg-white '
              >
                <td className='px-6 py-4'>{metric}</td>
                <td className='px-6 py-4'>{metricDefinition}</td>
                <td className='px-6 py-4'>{metricCalculation}</td>
                <td className='px-6 py-4'>{reportedFrequency}</td>
                <td className='px-6 py-4'>
                  {comparator.map((item: string, index: number) => {
                    return <div key={index}>{item}</div>;
                  })}
                </td>

                <td className='px-6 py-4'>
                  {dataSource.map(
                    (item: { dataSourceName: string }, index: number) => {
                      return <div key={index}>{item.dataSourceName}</div>;
                    }
                  )}
                </td>
                <td className='px-6 py-4'>
                  {table.map((item: string, index: number) => {
                    return <div key={index}>{item}</div>;
                  })}
                </td>
                <td className='px-6 py-4'>
                  {dimensionality.map((item: string, index: number) => {
                    return <div key={index}>{item}</div>;
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default KPI;
