import Forbidden from 'assets/SVG/Forbidden';
import React from 'react';
import { Link } from 'react-router-dom';

const ForbiddenError = () => {
  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='mb-4'>
        <Forbidden />
      </div>
      <div className='text-center'>
        <h4 className='font-bold text-4xl'>We are Sorry...</h4>
        <p className='mt-4'>
          {' '}
          The page you're trying to access has restricted access <br />
          or has been removed <br />
          Please refer to your system administrator
        </p>
        <div className='mt-8'>
          <Link to='/' className='p-4 bg-blue-400 rounded-lg text-white'>
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForbiddenError;
