import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface IStoryItem {
  _id: string;
  story: string;
  storyDetails: string;
  intendedAudience: string;
  priority: string;
}

interface IStory {
  storyItemID: string[];
  storyItem: IStoryItem[];
  addStoryItemID: (id: string) => void;
  addStoryItem: (item: IStoryItem) => void;

  updateStoryItem: (updateItem: IStoryItem) => void;
  removeStoryItemID: (id: string) => void;
  removeStoryItem: (id: string) => void;
  clearStoryItemID: () => void;
  clearStoryItem: () => void;
}

export const useStoryStore = create<IStory>()(
  persist(
    (set, get) => ({
      storyItemID: [],
      storyItem: [],

      addStoryItemID: (id: string) => {
        set((state) => ({ storyItemID: [...state.storyItemID, id] }));
      },
      addStoryItem: (item: IStoryItem) => {
        set((state) => ({ storyItem: [...state.storyItem, item] }));
      },

      updateStoryItem: (updateItem: IStoryItem) => {
        set((state) => ({
          storyItem: state.storyItem.map((item) =>
            item._id === updateItem._id ? updateItem : item
          ),
        }));
      },

      removeStoryItemID: (id: string) => {
        set((state) => ({
          storyItemID: state.storyItemID.filter((item) => item !== id),
        }));
      },
      removeStoryItem: (id: string) => {
        set((state) => ({
          storyItem: state.storyItem.filter((item) => item._id !== id),
        }));
      },

      clearStoryItemID: () => {
        set((state) => ({ storyItemID: [] }));
      },

      clearStoryItem: () => {
        set((state) => ({ storyItem: [] }));
      },
    }),

    {
      name: 'story-storage',
    }
  )
);
